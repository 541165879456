import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

export const NotFoundPage = () => {
  const [countdown, setCountdown] = useState(5); // Initial countdown set to 5 seconds
  const navigate = useNavigate();


  const userId = localStorage.getItem("userId"); 
  const userUrl = localStorage.getItem("userUrl"); 


  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);


    if (countdown === 0) {
      clearInterval(timer); 

      if (userId && userUrl) {

        navigate("/chatbots");
      } else {
 
        navigate("/");
      }
    }

    return () => clearInterval(timer); 
  }, [countdown, navigate, userId, userUrl]);

  return (
    <div className="flex w-full h-screen bg-gradient-to-r from-gray-200 to-gray-400 items-center justify-center">
      <div className="text-center bg-white bg-opacity-90 text-gray-900 rounded-lg shadow-2xl p-12 max-w-lg w-full">
        <div className="p-6 bg-gray-100 rounded-lg shadow-md mb-8">
          <h1 className="text-6xl font-extrabold text-red-600 mb-6">404</h1>
          <h2 className="text-3xl font-semibold text-gray-800 mb-4">Page Not Found</h2>
          <p className="text-lg text-gray-600 mb-8">
            The page you are looking for does not exist. It might have been moved or deleted.
          </p>
        </div>

        <div className="flex justify-center space-x-6">
          <Link
            to="/"
            className="py-3 px-8 bg-blue-500 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-blue-400 transition duration-300"
          >
            Go Home
          </Link>
          <Link
            to="/login"
            className="py-3 px-8 bg-green-500 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-green-400 transition duration-300"
          >
            Login
          </Link>
        </div>

        <div className="mt-6">
          <p className="text-lg text-gray-600">
            Redirecting in <span className="font-semibold">{countdown}</span> seconds.
          </p>
        </div>
      </div>
    </div>
  );
};
