import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { FetchDataFromLocalStorage } from '../hooks/FetchDataFromLocalStorage';
import { useLocation } from 'react-router-dom';
import { AccessDenied } from '../Pages/AccessDenied';

// Create the AuthContext
export const AuthContext = createContext();  // Named export

// AuthProvider Component
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null); // User state
  const [loading, setLoading] = useState(true); // Loading state to wait for token verification
  const [showAccessDenied, setShowAccessDenied] = useState(false); // Show Access Denied state
  const location = useLocation(); // Get the current route
  const [userName, setUserName] = useState("Welcome");
  const apiUrl = process.env.REACT_APP_API_URL;

  // Define routes that bypass authentication
  const publicRoutes = ['/login', '/register', '/'];


  const isPublicRoute =
    publicRoutes.some(route => {
      // Handle "/" as an exact match
      if (route === '/') {
        return location.pathname === '/';
      }
      // For other routes, check prefix matching
      return location.pathname.startsWith(route);
    }) || location.pathname.startsWith('/agentlink');

  // Function to log in the user and store their data in context and localStorage
  const login = (userData) => {
    setUser(userData); // Store user data in the context
    localStorage.setItem('user', JSON.stringify(userData)); // Optionally store in localStorage
  };

  // Function to log out the user
  const logout = () => {
    setUser(null);
    localStorage.removeItem('user');
  };

  // Function to check the token and validate user session
  const checkToken = () => {
    const token = localStorage.getItem('token');

    // Show Access Denied if no token is found
    if (!token || token === 'undefined' || token === 'null') {
      setUser(null);
      setShowAccessDenied(true);
      setLoading(false);
      return;
    }

    // Verify the token
    axios
      .get(`${apiUrl}/protected`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(response => {
        // Handle successful response
        setUser(response.data.data); // Set user data if token is valid
        setShowAccessDenied(false);
      })
      .catch(error => {
        // Handle errors
        console.error('Error verifying token:', error.response || error);
        localStorage.removeItem('token');

        setUser(null);
        setShowAccessDenied(true);
      })
      .finally(() => {
        setLoading(false); // End loading state
      });
  };

  // useEffect hook to check the token status and manage route protection
  useEffect(() => {
    // Skip authentication logic for public routes
    if (isPublicRoute) {
      setLoading(false);
      return;
    }

    // Initial token check
    checkToken();

    // Listen for token changes dynamically
    const handleStorageChange = () => {
      checkToken();
    };

    window.addEventListener('storage', handleStorageChange);

    // Cleanup listener on unmount
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [location, isPublicRoute]);

  // AuthContext Value to provide to other components
  const value = {
    user,
    loading,
    subscriptionStatus: FetchDataFromLocalStorage('subscriptionStatus'),
    checkToken,
    login,
    logout,
  };

  // Render AccessDenied if token is invalid or missing
  if (showAccessDenied && !isPublicRoute) {
    return <AccessDenied />;
  }

  // Render children only after loading state ends
  if (loading) {
    return (
      <div className="flex flex-col items-center min-h-screen bg-gray-900 text-white">
        <h1 className="text-4xl font-bold mt-6">Loading...</h1>
      </div>
    );
  }

  return <AuthContext.Provider value={{userName,setUserName,value}}>{children}</AuthContext.Provider>;
  
 
};

  
 

// Custom hook to use AuthContext
export const useAuth = () => {
  return React.useContext(AuthContext);
};
