import React, { useState } from 'react'
import { Link, NavLink, useParams } from 'react-router-dom'
import {BarsIcon, FillFileIcon,OutlineFileTextIcon,OutlineGlobalIcon, XMarkIcon,}from "../../Svg icons/Svg"
import { FetchDataFromLocalStorage } from '../../hooks/FetchDataFromLocalStorage'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify' // Add toast notifications
import aisaXLogo from '../../img/aisaXLogo.png'
import LoadingGif from "../../img/LoadingGif.gif";


export const AgentWebsiteUploadPage = () => {
  const [websiteUrl, setWebsiteUrl] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [loading, setLoading] = useState(false) // State to manage loading
  const [internalLinks, setInternalLinks] = useState([])
  const [selectedLinks, setSelectedLinks] = useState([])

  const { uuid } = useParams() // Access chatbotId from URL params
  const chatbotId = uuid
  const uuuid = FetchDataFromLocalStorage('current_uuid')
  const userId = FetchDataFromLocalStorage('userId')
  const apiUrl = process.env.REACT_APP_API_URL
  const clientUrl = process.env.REACT_APP_URL
  const token = FetchDataFromLocalStorage('token')
const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const modelUrl = process.env.REACT_APP_AI_AGENT_URL
  const [includedLinks, setIncludedLinks] = useState([]); // Initialize includedLinks
  const [showDeleteOptions, setShowDeleteOptions] = useState(false);
  // Validate URL
  const isValidUrl = url => {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // Protocol
        '((([a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,})|' + // Domain name
        'localhost|' + // localhost
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR IP (v4) address
        '(\\:\\d+)?(\\/[-a-zA-Z0-9%_.~+]*)*' + // Port and path
        '(\\?[;&a-zA-Z0-9%_.~+=-]*)?' + // Query string
        '(\\#[-a-zA-Z0-9_]*)?$', // Fragment locator
      'i',
    )
    return pattern.test(url)
  }

  // Handle Training
  const handleDocumentUpload = async () => {
    if (!websiteUrl || !isValidUrl(websiteUrl)) {
      return false
    }

    setLoading(true)
    // setMessage(""); // Clear any existing messages

    try {
      // Prepare the data for the API call
      const payload = {
        uuid: uuuid,
        DocumentType: 'Url',
        websiteUrl: websiteUrl,
        userId: userId,
      }

      // API call to upload text
      const response = await fetch(`${apiUrl}/uploadDocuments`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })

      const result = await response.json()
      // setFinal(result);
      console.log(result, 'result')

      if (response.ok) {
        // setMessage("Text uploaded successfully!");
        // setText(''); // Clear the text area
      } else {
        // setMessage(result.message || "Failed to upload text.");
      }
    } catch (error) {
      console.error('Error uploading text:', error)
      // setMessage("An error occurred while uploading the text.");
    } finally {
      setLoading(false)
    }
  }

  const handleBackLinks = async () => {
    try {
      setIsLoading(true);
  
      // Make POST request to FastAPI endpoint
      const response = await axios.post(
        `${modelUrl}/crawl-page`, // Base URL and endpoint
        {
          url: websiteUrl, // Pass the single website URL as expected by the FastAPI function
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token if required
            "Content-Type": "application/json", // Ensure proper content type
          },
        }
      );
  
      console.log(response, "response");
  
      // Check and handle API response
      if (response.data.internal_links) {
        const internalLinks = response.data.internal_links; // Extract internal links
        setInternalLinks(internalLinks);
        toast.success("Crawling successful!", { position: "bottom-right" });
      } else {
        toast.warn("Unexpected response format.", { position: "bottom-right" });
      }
    } catch (error) {
      // Handle error response
      toast.error(
        error.response?.data?.detail || "Error during crawling.",
        { position: "bottom-right" }
      );
    } finally {
      setIsLoading(false); // Hide loader
    }
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedLinks(internalLinks);
    } else {
      setSelectedLinks([]);
    }
  };

  const handleLinkSelect = (link) => {
    setSelectedLinks((prevSelected) => {
      if (prevSelected.includes(link)) {
        return prevSelected.filter((l) => l !== link);
      } else {
        return [...prevSelected, link];
      }
    });
  };

  const handleCheckboxChange = (link) => {
    setSelectedLinks((prev) =>
      prev.includes(link)
        ? prev.filter((item) => item !== link)
        : [...prev, link]
    );
  };

  const handleTraining = async () => {
    if (selectedLinks.length === 0) {
      toast.warn("Please select at least one link for training.", {
        position: "bottom-right",
      });
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post(
        `${modelUrl}/retrain`,
        {
          user_id: userId,
          agent_id: uuuid,
          company_name: 'company',
          // crawl_url: websiteUrl,
          links: selectedLinks,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.details && response.data.details.collection_id) {
        toast.success("Training successful!", { position: "bottom-right" });
      } else {
        toast.warn("Unexpected response format.", { position: "bottom-right" });
      }
    } catch (error) {
      toast.error(
        error.response?.data?.detail || "Error initiating model retraining.",
        { position: "bottom-right" }
      );
    } finally {
      setIsLoading(false);
    }
  };

  // Function to handle link deletion
  const handleDeleteLink = (index) => {
    const updatedLinks = includedLinks.filter((_, i) => i !== index);
    setIncludedLinks(updatedLinks);
  };

  // Function to toggle the visibility of delete options
  const toggleDeleteOptions = () => {
    setShowDeleteOptions(!showDeleteOptions);
  };
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <>
          <div className=' min-h-screen bg-gradient-to-r mt-10 from-blue-500 to-purple-500'> 
           {/* <TopNav/> */}
           <div className={`min-h-screen  transition-colors duration-300`}>
             {/* Top Navbar */}
                  <div className="fixed top-0 left-0 right-0 z-50 bg-blue-500 shadow-md py-2 flex items-center">
                    {/* Logo */}
                    <div className="ml-4">
                      <a href={`${clientUrl}/agents`}>
                        <img
                          src={aisaXLogo}
                          alt="AiSA-X Logo"
                          className="w-36 h-auto cursor-pointer"
                        />
                      </a>
                    </div>
            
                    {/* Hamburger Icon for Sidebar */}
                    <button
                      onClick={toggleSidebar}
                      className="ml-auto mr-4 text-white text-2xl focus:outline-none lg:hidden"
                    >
                      {isSidebarOpen ? <XMarkIcon/> : <BarsIcon />}
                    </button>
            
                    {/* Navigation Tabs for Larger Screens */}
                    <ul className="hidden lg:flex justify-around w-full px-[20%]">
                      <li className="text-white font-bold cursor-pointer">
                        <NavLink
                          to={`/agent/${chatbotId}`}
                          className={({ isActive }) =>
                            isActive ? 'text-yellow-300' : 'text-white'
                          }
                          end
                        >
                          Playground
                        </NavLink>
                      </li>
                      {['Activity', 'Analysis', 'Sources', 'Connect', 'Settings'].map(
                        section => (
                          <li key={section} className="text-white font-bold cursor-pointer">
                            <NavLink
                              to={`/agent/${chatbotId}/${section.toLowerCase()}`}
                              className={({ isActive }) =>
                                isActive ? 'text-yellow-300' : 'text-white'
                              }
                              end
                            >
                              {section}
                            </NavLink>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                 
  {/* Sidebar for Smaller Screens */}
             {isSidebarOpen && (
               <div className="fixed top-0 right-0 w-64 h-full bg-white shadow-lg z-50 p-4 lg:hidden">
                 <ul>
                   <li className="text-gray-800 font-bold cursor-pointer mb-4">
                     <NavLink
                       to={`/agent/${chatbotId}`}
                       onClick={toggleSidebar}
                       className={({ isActive }) =>
                         isActive ? 'text-blue-500' : 'text-gray-800'
                       }
                       end
                     >
                       Playground
                     </NavLink>
                   </li>
                   {['Activity', 'Analysis', 'Sources', 'Connect', 'Settings'].map(
                     section => (
                       <li
                         key={section}
                         className="text-gray-800 font-bold cursor-pointer mb-4"
                       >
                         <NavLink
                           to={`/agent/${chatbotId}/${section.toLowerCase()}`}
                           onClick={toggleSidebar}
                           className={({ isActive }) =>
                             isActive ? 'text-blue-500' : 'text-gray-800'
                           }
                           end
                         >
                           {section}
                         </NavLink>
                       </li>
                     )
                   )}
                 </ul>
               </div>
             )}



<div className="pt-10 flex w-full flex-col h-full bg-gradient-to-r from-blue-500 to-purple-500">
      {/* Hamburger Button for Mobile */}
      {/* <button
        className="fixed top-16 left-4 z-50 bg-gray-500 text-white  p-2 rounded-full lg:hidden"
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        {isSidebarOpen ? <c className="w-6 h-6" /> : <BarsIcon className="w-6 h-6" />}
      </button> */}

      {/* Sidebar */}
      <div
        className={`w-64 bg-white h-screen p-6 shadow-lg fixed z-40 transition-transform duration-300 ${
          isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
        } lg:translate-x-0`}
      >
        <h1 className="text-2xl font-bold mb-6">Data Sources</h1>
        <ul className="space-y-4">
          <li>
            <NavLink
              to={`/agent/${uuid}/files`}
              className={({ isActive }) =>
                `flex items-center py-2 px-4 rounded-lg transition duration-300 ${
                  isActive ? 'bg-[#8acfeb] text-white' : 'hover:bg-[#e6f7fd] text-[#333]'
                }`
              }
            >
              <FillFileIcon/>
              <span className="font-medium">Files</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/agent/${uuid}/text`}
              className={({ isActive }) =>
                `flex items-center py-2 px-4 rounded-lg transition duration-300 ${
                  isActive ? 'bg-[#8acfeb] text-white' : 'hover:bg-[#e6f7fd] text-[#333]'
                }`
              }
            >
              <OutlineFileTextIcon/>
              <span className="font-medium">Text</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/agent/${uuid}/website`}
              className={({ isActive }) =>
                `flex items-center py-2 px-4 rounded-lg transition duration-300 ${
                  isActive ? 'bg-[#8acfeb] text-black font-bold' : 'hover:bg-[#e6f7fd] text-[#333]'
                }`
              }
            >
              <OutlineGlobalIcon/>
              <span className="font-medium">Website</span>
            </NavLink>
          </li>
        </ul>
      </div>
      
      {/* Main Content */}
      <div className="flex-1 lg:ml-64 p-6 overflow-y-auto min-h-screen bg-gray-100">
        <h1 className="text-3xl font-bold mb-4">Website Data Source</h1>
        <p className="text-lg mb-4">
          Add a website URL to use as a data source for training your AI Agent.
        </p>

        {/* Crawl Website */}
        <div className="bg-white p-6 rounded-lg shadow-lg mb-6">
          <div className="border p-4 rounded-lg mb-4">
            <h2 className="text-2xl font-semibold mb-4">Crawl Website</h2>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-lg mb-2"
              placeholder="Enter website URL (e.g., https://www.example.com)"
              value={websiteUrl}
              onChange={(e) => setWebsiteUrl(e.target.value)}
            />
          </div>

          <button
            className="bg-blue-500 text-white font-bold py-2 px-6 rounded-lg hover:bg-blue-600 transition"
            onClick={handleBackLinks}
            disabled={isLoading}
          >
            {isLoading ? "Crawling Links..." : "Crawl Links"}
          </button>
        </div>

        {internalLinks.length > 0 && (
          <div className="bg-white p-6 rounded-lg shadow-lg mb-6">
            <h2 className="text-2xl font-semibold mb-4">Select Links for Training</h2>
            <ul className="space-y-2">
              {internalLinks.map((link, index) => (
                <li key={index} className="flex items-center">
                  <input
                    type="checkbox"
                    className="mr-2"
                    value={link}
                    onChange={() => handleCheckboxChange(link)}
                  />
                  <span>{link}</span>
                </li>
              ))}
            </ul>
            <button
              onClick={handleTraining}
              className="bg-green-500 text-white font-bold py-2 px-6 rounded-lg hover:bg-green-600 transition mt-4"
              disabled={isLoading}
            >
              {isLoading ? "Training AI Agent..." : "Train AI Agent"}
            </button>
          </div>
        )}

{isLoading && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-8 rounded-lg shadow-lg text-center">
              <img
                src={LoadingGif}
                alt="Loading..."
                className="w-16 h-16 mx-auto mb-4"
              />
              <p className="text-lg font-bold mb-2">Please wait...</p>
              <p>Your AI Agent is processing.</p>
            </div>
          </div>
        )}
      </div>

        {/* Included Links */}
        {/* <div className="border bg-white p-4 rounded-lg">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-semibold">Included Links</h2>
          </div>
          {includedLinks.length > 0 ? (
            <ul className="list-disc ml-6 space-y-2">
              {includedLinks.map((link, index) => (
                <li key={index} className="flex items-center justify-between">
                  <span className="break-words w-4/5">{link}</span>
                  {showDeleteOptions && (
                    <button
                      onClick={() => handleDeleteLink(index)}
                      className="text-red-500 hover:text-red-700 transition"
                    >
                      <AiOutlineDelete />
                    </button>
                  )}
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-500">No links added yet.</p>
          )}
        </div> */}
      {/* </div> */}

      <ToastContainer position="bottom-right" autoClose={3000} />
    </div>
        {/* Close Sidebar on Outside Click */}
   {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-25 z-30 lg:hidden"
          onClick={() => setIsSidebarOpen(false)}
        ></div>
      )}
    </div>
      </div>
      
    </>
  )
}
