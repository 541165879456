import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import aisaXLogo from '../../src/img/aisaXLogo2.png'

import { EyecloseIcon, EyeopenIcon, Google } from '../../src/Svg icons/Svg'

const OTPInput = ({ length, onComplete }) => {
  const [otp, setOtp] = useState(Array(length).fill(''))

  const apiUrl = process.env.REACT_APP_API_URL

  const handleChange = (e, index) => {
    const value = e.target.value
    if (/^[0-9]*$/.test(value)) {
      const newOtp = [...otp]
      newOtp[index] = value.slice(0, 1)
      setOtp(newOtp)

      if (value && index < length - 1) {
        document.getElementById(`otp-${index + 1}`)?.focus()
      }

      if (newOtp.every(digit => digit)) {
        onComplete(newOtp.join(''))
      }
    }
  }

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      document.getElementById(`otp-${index - 1}`)?.focus()
    }
  }

  return (
    <div className="flex justify-center space-x-2">
      {otp.map((digit, index) => (
        <input
          key={index}
          id={`otp-${index}`}
          type="text"
          value={digit}
          onChange={e => handleChange(e, index)}
          onKeyDown={e => handleKeyDown(e, index)}
          maxLength={1}
          className="w-12 h-12 text-center text-lg font-bold border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
        />
      ))}
    </div>
  )
}

export const Signup = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [sessionCreate, setSessionCreate] = useState('')

  const [showPassword, setShowPassword] = useState(false)
  const [otpSent, setOtpSent] = useState(false)
  const [loading, setLoading] = useState(false)
  const [passwordValid, setPasswordValid] = useState({
    length: false,
    number: false,
    specialChar: false,
  })
  const [emailValid, setEmailValid] = useState(null)

  const navigate = useNavigate()
  const apiUrl = process.env.REACT_APP_API_URL

  const handlePasswordChange = e => {
    const newPassword = e.target.value
    setPassword(newPassword)
    setPasswordValid({
      length: /.{8,}/.test(newPassword),
      number: /\d/.test(newPassword),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(newPassword),
    })
  }

  const handleEmailChange = e => {
    setEmail(e.target.value)
    setEmailValid(null)
  }

  const handleEmailBlur = () => {
    setEmailValid(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))
  }

  const handleGoogleLogin = () => {
    window.location.href = `${apiUrl}/auth/google`
  }

  const handleOtpRequest = async e => {
    e.preventDefault()

    if (
      !emailValid ||
      !passwordValid.length ||
      !passwordValid.number ||
      !passwordValid.specialChar
    ) {
      toast.error('Please fill in valid email and password fields.')
      return
    }

    try {
      setLoading(true)
      const response = await axios.post(`${apiUrl}/initialregister`, {
        email,
        password,
        agreedToTerms: termsAccepted,
      })
      // console.log(response.data.sessionId);

      if (response.status === 200) {
        setSessionCreate(response.data.sessionId)
        setOtpSent(true)
        toast.success('OTP sent to your email.')
      }
    } catch (error) {
      toast.error('Failed to send OTP. Please try again.')
    } finally {
      setLoading(false)
    }
  }

  // const handleOtpComplete = async (otp) => {
  //   try {
  //     setLoading(true);
  //     const response = await axios.post(`${apiUrl}/completeregister`, {
  //       otp,
  //       sessionId :sessionCreate
  //     });

  //     if (response.status === 200) {
  //       toast.success("Registration successful! Redirecting to login...");
  //       setTimeout(() => {
  //         navigate("/login");
  //       }, 2000);
  //     }
  //   } catch (error) {
  //     const errorMessage =
  //       error.response?.data?.message || "OTP verification failed. Please try again.";
  //     toast.error(errorMessage);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleOtpComplete = async otp => {
    try {
      setLoading(true)

      // Make the API request
      const response = await axios.post(`${apiUrl}/completeregister`, {
        otp,
        sessionId: sessionCreate,
      })

      // Log response for debugging
      console.log('API Response:', response.data.success)

      // Check if the response status is 200 and has a success flag
      if (response.data.success) {
        const { email, subscriptionId, isAdmin } = response.data.data

        // Display a success toast message
        toast.success(
          `Registration successful for ${email}! Redirecting to login...`,
        )

        // Redirect to login after a short delay
        setTimeout(() => {
          navigate('/login')
        }, 2000)
      } else {
        // Handle unexpected response format
        const errorMessage =
          response.data?.message || 'Unexpected response format.'
        throw new Error(errorMessage)
      }
    } catch (error) {
      // Handle error cases and display appropriate messages
      const errorMessage =
        error.response?.data?.message ||
        'OTP verification failed. Please try again.'
      console.error('Error:', errorMessage)
      toast.error(errorMessage)
    } finally {
      // Stop the loading spinner
      setLoading(false)
    }
  }

  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-r from-purple-500 to-blue-400 p-4">
      <div className="bg-white shadow-[20px_20px_30px_rgba(122,125,132,0.7)] rounded-[30px] w-[430px] p-10">
        <div className="flex flex-col items-center mb-2">
          <a href="https://aisa-x.ai">
            <img src={aisaXLogo} alt="AiSA-X Logo" className="w-28 md:w-32" />
          </a>
          <h2 className="text-lg font-bold text-gray-800">
            {otpSent ? 'Enter OTP' : 'Create Your Account'}
          </h2>
        </div>

        {!otpSent ? (
          <form onSubmit={handleOtpRequest}>
            <div className="mb-4">
              <label className="block font-medium text-gray-700">Email</label>
              <input
                type="email"
                value={email}
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
                className="w-full px-4 py-2 mt-1 border rounded-md"
                required
              />
            </div>

            <div className="mb-4 relative">
              <label className="block font-medium text-gray-700">
                Password
              </label>
              <input
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={handlePasswordChange}
                className="w-full px-4 py-2 mt-1 border rounded-md"
                required
              />
              <span
                className="absolute right-3 top-10 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <EyecloseIcon /> : <EyeopenIcon />}
              </span>
            </div>

            <div className="mb-2 flex items-center">
              <input
                type="checkbox"
                checked={termsAccepted}
                onChange={() => setTermsAccepted(!termsAccepted)}
                className="mr-2"
              />
              <label>
                I agree to the{' '}
                <Link to="/terms" className="text-purple-500">
                  Terms
                </Link>
                .
              </label>
            </div>

            <button
              type="submit"
              className={`w-full py-2 text-white rounded-md transition duration-200 ${
                emailValid && termsAccepted ? 'bg-purple-600' : 'bg-gray-400'
              }`}
              disabled={!emailValid || !termsAccepted}
            >
              {loading ? 'Sending OTP...' : 'Register'}
            </button>
          </form>
        ) : (
          <div>
            <OTPInput length={6} onComplete={handleOtpComplete} />
          </div>
        )}

        <hr className="my-6 border-t border-gray-300" />

        <div className="flex flex-col items-center">
  {/* Sign in with Google */}
          <button
            onClick={handleGoogleLogin}
            // className="flex items-center px-6 py-3 rounded-lg border-2 border-blue-300 bg-gradient-to-r from-purple-400 to-purple-600 hover:from-purple-500 hover:to-purple-700 hover:border-blue-600 text-white font-semibold transition-all duration-300 shadow-lg hover:shadow-2xl transform hover:scale-105"
            className="flex items-center px-6 py-3 rounded-lg border-2 border-blue-300 bg-black from-purple-400 to-purple-600 hover:from-purple-500 hover:to-purple-700 hover:border-blue-600 text-white font-semibold transition-all duration-300 shadow-lg hover:shadow-2xl transform hover:scale-105"

          >
            <Google className="mr-3" />
            Sign in with Google
          </button>
        </div>

        <p className="mt-2 text-center">
          Have an account?{' '}
          <Link to="/login">
            <span className="text-purple-500 underline font-bold">Login</span>
          </Link>
        </p>
      </div>
      <ToastContainer position="bottom-right" />
    </div>
  )
}
