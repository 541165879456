import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CustomerUserIcon, UserIcon }from "../../Svg icons/Svg"
import { FetchDataFromLocalStorage } from '../../hooks/FetchDataFromLocalStorage';
import { ToastContainer, toast } from "react-toastify"; // Importing ToastContainer and toast
import "react-toastify/dist/ReactToastify.css"; // Import toast CSS
import Sidebar from "./SideBar";


export const CustomerForm = () => {
  const Navigate = useNavigate();
  const { customerId } = useParams(); // Get the customer ID from the route params for editing
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // Sidebar state
  const token = FetchDataFromLocalStorage("token");
  const userId = FetchDataFromLocalStorage("userId");

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    address: "",
    email: "",
   
  });

  const [statusMessage, setStatusMessage] = useState("");
  const [originalData, setOriginalData] = useState({}); // To store original data
  const [isFormModified, setIsFormModified] = useState(false); // To track changes

 // Fetch customer data if in edit mode and set original data
useEffect(() => {
  if (customerId) {
    axios
      .get(`${apiUrl}/api/customers/${customerId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (response.data.success) {
          setFormData(response.data.customer);
          setOriginalData(response.data.customer); // Store original data for comparison
        } else {
          alert("Failed to fetch customer data");
        }
      })
      .catch((error) => {
        console.error("Error fetching customer data:", error);
      });
  }
}, [customerId, apiUrl, token]);

// Check for changes whenever formData updates
useEffect(() => {
  setIsFormModified(JSON.stringify(formData) !== JSON.stringify(originalData));
}, [formData, originalData]);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const requestData = {
      ...formData,
      userId, // Include userId in request
    };
  
    const url = `${apiUrl}/api/customers`; // Always use POST method
  
    try {
      const result = await axios({
        method: "post",
        url,
        data: requestData,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (result.data.success) {
        toast.success("Profile saved successfully!", {
          position: "bottom-right",
          autoClose: 3000,
        });
  
        setTimeout(() => {
          // Redirect only to UserProfile
          const newCustomerId = result.data.customer._id || customerId;
          Navigate(`/UserProfile/${newCustomerId}`); // Redirect to UserProfile page
        }, 1500);
      } else {
        console.error("API Error:", result.data);
        toast.error(result.data.message || "Failed to submit. Please try again.", {
          position: "bottom-right",
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error.response?.data || error.message);
      toast.error(
        error.response?.data?.message || "Failed to submit. Please check your inputs.",
        {
          position: "bottom-right",
          autoClose: 3000,
        }
      );
    }
  };
  
  
  
  return (
    <>
    <Sidebar onToggle={(isOpen) => setIsSidebarOpen(isOpen)} />
    <div
      className="min-h-screen w-full max-w-4xl bg-white overflow-y-auto"
      style={{ marginLeft: isSidebarOpen ? "280px" : "0", transition: "margin 0.3s ease-in-out" }}
    >
<div className="max-w-lg mx-auto mt-2 p-6 bg-white rounded-xl shadow-lg border border-gray-300 overflow-hidden">
  {/* Header Section */}
  <div className="text-center mb-2">
    <div className="inline-flex items-center justify-center w-20 h-20  rounded-full shadow-md">
      <CustomerUserIcon size={48} className="text-indigo-700" />
    </div>
    <h2 className="mt-4 text-2xl font-bold text-gray-800">
      {customerId ? "Update Profile" : "Add Profile"}
    </h2>
    <p className="mt-2 text-sm text-gray-500">
      {customerId
        ? "Make changes to the customer details below."
        : "Fill out the form to add a new profile."}
    </p>
  </div>

  {/* Status Message */}
  {statusMessage && (
    <div className="mb-5 px-4 py-3 bg-green-50 border-l-4 border-green-500 text-green-700 rounded-md shadow-sm">
      {statusMessage}
    </div>
  )}

  {/* Form Section */}
  <form onSubmit={handleSubmit} className="space-y-6">
    {[
      { label: "First Name", name: "firstName", type: "text" },
      { label: "Last Name", name: "lastName", type: "text" },
      { label: "Address", name: "address", type: "text" },
      { label: "Email", name: "email", type: "email" },
    ].map((field, index) => (
      <div key={index}>
        <label
          htmlFor={field.name}
          className="block text-sm font-medium text-gray-700 mb-2"
        >
          {field.label}
        </label>
        <input
          id={field.name}
          type={field.type}
          name={field.name}
          value={formData[field.name] || ""}
          onChange={handleChange}
          required
          className="w-full px-4 py-2 text-sm text-gray-700 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-400 focus:border-indigo-500 shadow-sm transition duration-300"
        />
      </div>
    ))}

    {/* Submit Button */}
    <button
      type="submit"
      disabled={!isFormModified}
      className={`w-full py-2 text-lg font-semibold rounded-lg shadow-md ${
        isFormModified
          ? "bg-indigo-600 text-white hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-400"
          : "bg-gray-300 text-gray-600 cursor-not-allowed"
      } transition-all duration-300`}
    >
      {customerId ? "Save Changes" : "Add Profile"}
    </button>
  </form>
</div>



      <ToastContainer />
    </div></>
  );
};

