import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import CryptoJS from "crypto-js"; 


import { FetchDataFromLocalStorage } from '../../hooks/FetchDataFromLocalStorage';
import axios from 'axios';

export const ChatBoxIframe = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const location = useLocation();

  // const { chatbotId } = useParams(); // Retrieve chatbotId from URL parameters
  const agentParameter = location.pathname.replace("/agentlink/", "");
  

  const [searchParams] = useSearchParams(); // Retrieve query parameters
  const modelUrl = process.env.REACT_APP_AI_AGENT_URL;
  const apiUrl = process.env.REACT_APP_API_URL;
  const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY || "default_key"; 
  const chatBoxRef = useRef(null); // Reference for scrolling to bottom
  // const decryptedChatbotId = CryptoJS.AES.decrypt(chatbotId, encryptionKey).toString(CryptoJS.enc.Utf8);
  // const decryptedUserId = CryptoJS.AES.decrypt(searchParams.get("user"), encryptionKey).toString(CryptoJS.enc.Utf8);
  // const decryptedUuid = CryptoJS.AES.decrypt(searchParams.get("agent"), encryptionKey).toString(CryptoJS.enc.Utf8);
  const decryptedChatbotId = CryptoJS.AES.decrypt(agentParameter, encryptionKey).toString(CryptoJS.enc.Utf8);
  const parsedData = JSON.parse(decryptedChatbotId);

  const { chatbotId, user, agent } = parsedData;

  // console.log(parsedData,"chatbotId")

  // const userId = decryptedUserId;
  // const uuid = decryptedUuid;

  const userId = searchParams.get("user") || user;
  const uuid = searchParams.get("agent") || agent;

  //const userId = FetchDataFromLocalStorage('userId');
  //const uuid = FetchDataFromLocalStorage('current_uuid');
  const stored_session_id = FetchDataFromLocalStorage('stored_session_id');

  



  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    scrollToBottom();
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [messages])

  // Function to fetch the initial message from the backend
  const fetchInitialMessage = async () => {
    if (!modelUrl) {
      console.error("Model URL is not defined.");
      return;
    }
  

    const params = {
      userId: userId,          // Add user ID as a query parameter
      agentId: uuid,      // Add agent ID as a query parameter
      collection_id: chatbotId, // Add collection_id as a query parameter
    };
  
    try {
      // Send GET request with query parameters
      const response = await axios.get(`${apiUrl}/initial_message/${uuid}`, { params });
      // console.log(response.data,"rdd")
  
      // Check response structure
      if (response.data.success && response.data.agent_Details) {
        const welcomeMessage = response.data.agent_Details.welcomeMessage;
        const name =response.data.agent_Details.name;

        if(stored_session_id){
          setSessionId(stored_session_id)

        }else{
          setSessionId(response.data.sessionId);

          localStorage.setItem('stored_session_id', response.data.sessionId)
        }


        if (welcomeMessage) {
          // console.log(welcomeMessage, "data");
          typeMessage(welcomeMessage); 
        } else {
          typeMessage(`hi i am ${name} , How can i assisst you `); 

        }
      } else {
        throw new Error("Invalid response format");
      }
    } catch (error) {
      console.error("Error fetching initial message:", error);
      setMessages([
        { text: "Error: Could not load the initial message.", sender: "Bot" },
      ]);
    }
  };

  const handleSendMessage = async () => {
    if (input.trim()) {
      const newMessage = { text: input, sender: "User" };
      setMessages([...messages, newMessage]);
      setInput("");
      setLoading(true);
  
      await sendMessageToBackend(input);
    }
  };

  // Function to send a message
  const sendMessageToBackend = async (userMessage) => {
    try {
      const response = await fetch(`${modelUrl}/query`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",  // Ensure the server knows the client sends JSON
          // Accept: "text/event-stream",         // Inform the server to send events as an SSE stream
          // "Cache-Control": "no-cache",         // Prevent response caching, crucial for live updates
          Connection: "keep-alive",            // Ensure the connection remains open for streaming
        },
        body: JSON.stringify({
          question: userMessage,
          collection_id: userId+ "_" +chatbotId ,
          company_name: "company",
          session_id: sessionId,
          user_id: userId,
          agent_id: chatbotId,
        }),
      });
  
      // Ensure response is OK before proceeding
      if (!response.ok) {
        throw new Error(`Failed to fetch response: ${response.status} ${response.statusText}`);
      }
  
      // Read the response stream
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
  
      // Start a new bot message to display streaming text
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: "", sender: "Bot", isStreaming: true },
      ]);
  
      let liveMessage = ""; // Accumulate chunks here
  
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
  
        const chunk = decoder.decode(value, { stream: true });
        const lines = chunk.split("\n").filter((line) => line.trim() !== ""); // Remove empty lines
  
        for (const line of lines) {
          if (line.startsWith("data:")) {
            const messagePart = line.replace("data:", "").trim(); // Remove "data:" prefix
            liveMessage += messagePart + " "; // Append to live message
  
            // Update the displayed message
            setMessages((prevMessages) => {
              const updatedMessages = [...prevMessages];
              const lastMessage = updatedMessages[updatedMessages.length - 1];
              if (lastMessage.sender === "Bot" && lastMessage.isStreaming) {
                lastMessage.text = liveMessage.trim(); // Update with live text
              }
              return updatedMessages;
            });
          }
        }
      }
  
      // Finalize the bot message
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        const lastMessage = updatedMessages[updatedMessages.length - 1];
        if (lastMessage.isStreaming) delete lastMessage.isStreaming; // Remove streaming flag
        return updatedMessages;
      });
    } catch (error) {
      console.error("Error handling streaming response:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: "Error: Could not connect to the agent.", sender: "Bot" },
      ]);
    } finally {
      setLoading(false);
    }
  };

  // Function to type the message (simulate typing)
  const typeMessage = (message) => {
    const newMessage = { text: message, sender: 'bot' };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  };

  useEffect(() => {
    fetchInitialMessage(); // Fetch initial message when component mounts
  }, []);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight
    }
  }, [messages]) // Scroll to bottom when messages update

  return (
    <div
      className="fixed inset-0 flex flex-col p-4"
      style={{
        background: 'linear-gradient(to bottom right, #f0f4f8, #d9e2ec)', // Light gray gradient background
        height: '100vh',
        zIndex: 111999999,
        position: 'fixxed',
        fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif", // Professional font
      }}
    >
      <div
        ref={chatBoxRef}
        className="flex-grow h-0 overflow-y-auto p-4 bg-white bg-opacity-90 rounded-lg shadow-lg mb-4"
      >
      {messages.map((msg, index) => (
        <div
          key={index}
          className={`mb-2 flex ${
            msg.sender === "User" ? "justify-end" : "justify-start"
          }`}
        >
    <span
      className={`inline-block p-3 rounded-lg shadow-md max-w-md ${
        msg.sender === "User"
          ? "bg-purple-600 text-white"
          : "bg-gray-300 text-black"
      }`}
    >
      {msg.text}
    </span>
  </div>
))}
  <div ref={messagesEndRef}>
          {loading && (
            <div className="text-center text-gray-500">
              Agent is Typing 💬...
            </div>
          )}

  </div>
      </div>

      <div className="flex mt-auto"   style={{
    zIndex: 111999999,
    position: 'relative', // Ensure proper positioning context
  }}
      >
        <div className="flex w-full border-t border-gray-300 rounded-lg bg-gray-100 p-2 shadow-md">
          <input
            type="text"
            className="flex-1 p-3 border-none rounded-lg focus:outline-none"
            placeholder="Type a message..."
            value={input}
            // onChange={e => setInput(e.target.value)}
            // onKeyPress={e => {
            //   if (e.key === 'Enter') sendMessageToBackend()
            // }}
            onChange={(e) => setInput(e.target.value)}
            ref={inputRef}

            onKeyPress={(e) => {
              if (e.key === "Enter") handleSendMessage();
            }}
            disabled={loading} // Disable input while waiting for bot response
            style={{
              fontSize: '16px',
              borderRadius: '8px',
              boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.1)',
            }}
          />
          <button
            onClick={handleSendMessage}
            className="ml-2 py-2 px-6 text-white rounded-lg"
            style={{
              background: 'linear-gradient(to right, #0078d4, #0053a6)', // Blue gradient for button
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
              fontWeight: 'bold',
            }}
          >
            Send
          </button>
        </div>
      </div>
      <div className="flex justify-center items-center group">
        <a
          className="text-sm underline text-gray-400 hover:text-black relative"
          href="https://aisa-x.ai/"
        >
          Free AI Agent
          <span className="absolute z-50 left-0 top-[-1.5rem] hidden text-sm text-gray-500 group-hover:inline">
            Visit Aisa-x.ai
          </span>
        </a>
      </div>
    </div>
  )
}
