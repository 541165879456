import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import aisaXLogo from '../../src/img/aisaXLogo2.png'
import { EyecloseIcon, EyeopenIcon, Google } from '../../src/Svg icons/Svg'

export const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false) // State to toggle password visibility
  const navigate = useNavigate()
  // const location = useLocation();
  // const { redirectUrl, paymentType } = useParams() // Access chatbotId from URL params
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectUrl = queryParams.get('redirectUrl'); // Extracts 'paymentPage'
  const paymentType = queryParams.get('paymentType');
  
  // console.log(redirectUrl,paymentType,"heree")
  const apiUrl = process.env.REACT_APP_API_URL

  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  const loginParams = redirectUrl ? `?redirectUrl=${redirectUrl}&paymentType=${paymentType}` : ''; // Check if redirectUrl is present
  
  const loginUrl = `${apiUrl}/login-auth${loginParams}`;


  const handleLogin = e => {
    e.preventDefault()
    axios
      .post(loginUrl, { email, password })
      .then(response => {
        const { message } = response.data
        const { userId, isAdmin, token, subscriptionStatus } =
          response.data.data

          console.log(response.data,message,"redirectUrl=")

        if (message === "Login successful.") {
          localStorage.setItem('userId', userId)
          localStorage.setItem('token', token)
          localStorage.setItem('isAdmin', isAdmin)
          localStorage.setItem('subscriptionStatus', subscriptionStatus)

          //  Display success toast
          toast.success("Login successful.", {
            position: 'bottom-right',
            autoClose: 300,

            onClose: () => {
              if (response.data.redirectUrl) {
                // Navigate to the payment page with query parameters
                navigate(`/paymentpage?paymentType=${response.data.paymentType}&redirectUrl=${response.data.redirectUrl}`);
              } else if (isAdmin) {
                // Navigate to the admin-only page
                navigate('/admin-only');
              } else {
                // Navigate to the dashboard or chatbots page
                navigate('/dashboard');
              }
              
            },
          })
        } else {
          toast.error(message, {
            position: 'bottom-right',
            autoClose: 3000,
          })
        }
      })
      .catch(err => {
        console.error('Login error:', err.response || err) // Log full error response for better debugging
        // Log the error message
        const errorMessage =
          err.response?.data?.message ||
          'An error occurred during login. Please try again.'
        console.log('Erroar message:', errorMessage) // Log error message
        toast.error(errorMessage, {
          position: 'bottom-right',
          autoClose: 3000, // Automatically close the toast after 3 seconds
        })
      })
  }

  useEffect(() => {
    // Grab the token and any other query params from the URL
    const queryParams = new URLSearchParams(window.location.search)
    const token = queryParams.get('token')
    const userId = queryParams.get('userId')
    const email = queryParams.get('email')
    const isAdmin = queryParams.get('isAdmin')
    const subscriptionStatus = queryParams.get('subscriptionStatus')
    // const redirectUrl = queryParams.get("redirectUrl");
    // const paymentType = queryParams.get("paymentType");

    if (token) {
      // Store in local storage
      localStorage.setItem('token', token)
      localStorage.setItem('userId', userId)
      localStorage.setItem('email', email)
      localStorage.setItem('isAdmin', isAdmin)
      localStorage.setItem('subscriptionStatus', subscriptionStatus)

      // Redirect to wherever you want after success
      navigate('/dashboard')
    } else {
      // If no token, handle error or redirect somewhere
      // navigate('/login')
    }
  }, [navigate])

  const handleGoogleLogin = () => {
    window.location.href = `${apiUrl}/auth/google`
  }

  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-r from-purple-400 via-blue-500 to-teal-400 p-6">
      <div className="bg-white shadow-[20px_20px_30px_rgba(122,125,132,0.7)] rounded-[30px] w-[430px] h-300 p-10">
        <div className="flex flex-col  mb-6">
          <a
            href="https://aisa-x.ai"
            className="flex items-center justify-center"
          >
            <img
              src={aisaXLogo}
              alt="AiSA-X Logo"
              className="w-34 h-30 md:w-32 "
            />
          </a>
          <h2 className="text-md font-bold text-gray-800">Sign In</h2>
        </div>
        <form onSubmit={handleLogin} className="space-y-4">
          <div>
            <label>Email</label>
            <input
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
              className="w-full px-4 py-2 border rounded-md"
            />
          </div>
          <div className="relative">
            <label>Password</label>
            <input
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={e => setPassword(e.target.value)}
              placeholder="Enter your password"
              required
              className="w-full px-4 py-2 border rounded-md"
            />
            <span
              className="absolute right-3 top-9 cursor-pointer text-gray-500"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <EyecloseIcon /> : <EyeopenIcon />}
            </span>
          </div>
          <button
            type="submit"
            className="w-full py-2 mt-4 bg-purple-600 text-white rounded-md"
          >
            Login
          </button>
        </form>
        <hr className="my-6 border-t border-gray-300" />
        <div className="flex flex-col items-center">
  {/* Sign in with Google */}
          <button
            onClick={handleGoogleLogin}
            // className="flex items-center px-6 py-3 rounded-lg border-2 border-blue-300 bg-gradient-to-r from-purple-400 to-purple-600 hover:from-purple-500 hover:to-purple-700 hover:border-blue-600 text-white font-semibold transition-all duration-300 shadow-lg hover:shadow-2xl transform hover:scale-105"
            className="flex items-center px-6 py-3 rounded-lg border-2 border-blue-300 bg-black from-purple-400 to-purple-600 hover:from-purple-500 hover:to-purple-700 hover:border-blue-600 text-white font-semibold transition-all duration-300 shadow-lg hover:shadow-2xl transform hover:scale-105"

          >
            <Google className="mr-3" />
            Sign in with Google
          </button>
        </div>


        <p className="mt-2 text-center">
          Don't have an account?{' '}
          <Link to="/register">
            <span className="text-purple-500 underline font-bold">Sign up</span>
          </Link>
        </p>
      </div>
      <ToastContainer position="bottom-right" />
    </div>
  )
}
