import React from 'react';

const RecentActivities = () => {
  const activities = [
    { id: 1, text: 'New agent added: "SupportBot"', time: '2 mins ago' },
    { id: 2, text: 'Document uploaded by John Doe', time: '10 mins ago' },
    { id: 3, text: 'Agent "SalesBot" was trained', time: '30 mins ago' },
  ];

  return (
    <div className="bg-white shadow-md rounded-lg p-4">
      <h2 className="text-lg font-semibold mb-2">Recent Activities</h2>
      <ul className="space-y-2">
        {activities.map((activity) => (
          <li key={activity.id} className="text-md text-gray-600">
            <span className="block">{activity.text}</span>
            <span className="text-sm text-gray-400">{activity.time}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RecentActivities;
