import React, { useContext , useState, useEffect } from "react";
import { FetchDataFromLocalStorage } from "../../hooks/FetchDataFromLocalStorage";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify"; // Importing ToastContainer and toast
import "react-toastify/dist/ReactToastify.css"; // Import toast CSS
import {UsercircleIcon} from "../../Svg icons/Svg";
import Sidebar from "./SideBar";
import {AuthContext } from "../../contexts/authContext";

const UserProfile = () => {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { setUserName } = useContext(AuthContext);

  const [profile, setProfile] = useState({
    firstName: "",
    lastName: "",
    userId: "",
    address: "",
    email: "",
 
  });

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.matchMedia("(max-width: 768px)").matches;
      setIsSidebarOpen(!isMobile); // Close sidebar on mobile, open on larger screens
    };

    // Initialize state based on the initial screen size
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
  };

  const token = FetchDataFromLocalStorage("token");
  const userId = FetchDataFromLocalStorage("userId");

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/customers/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.success) {
          setProfile(response.data.customer); // Set profile data if user exists
        }
      } catch (error) {
        console.error("Error fetching profile:", error.response?.data || error.message);
      }
    };

    if (token && userId) {
      fetchProfile();
    }
  }, [token, userId, apiUrl]);

  const handleEdit = () => {
    navigate(`/edit-profile/${profile.userId}`); // Redirect to the edit profile page
  };

  const handleAddProfile = () => {
    navigate("/edit-profile"); // Redirect to add new profile page
  };

  

  return (
    <>
   
      <Sidebar onToggle={handleSidebarToggle} />
    <div className="min-h-screen w-full max-w-4xl  bg-white overflow-y-auto"
      style={{ marginLeft: isSidebarOpen ? '280px' : '0', transition: 'margin 0.3s ease-in-out' }}
      >

<div className="min-h-screen flex items-center justify-center  bg-white">
  <div className="rounded-xl shadow-lg p-10 border border-gray-300 max-w-8xl w-full flex flex-col sm:flex-row">
    {/* Left Section: Profile Card */}
    <div className="bg-gradient-to-b from-indigo-500 to-purple-600 rounded-lg flex flex-col items-center justify-center p-8 sm:w-1/3 sm:mr-8 text-white">
      <div className="relative w-32 h-32 bg-white mb-6 rounded-full flex items-center justify-center shadow-lg">
        <UsercircleIcon className="text-indigo-500 w-20 h-20" />
      </div>
      <h2 className="text-2xl font-semibold">Welcome</h2>
      {/* User ID Below */}
      <p className="text-md mt-6 opacity-80 bg-white text-indigo-600 rounded-full px-6 py-2 shadow-md">
        {profile.userId || "N/A"}
      </p>
    </div>

    {/* Right Section: Details */}
    <div className="flex-1 mt-8 sm:mt-0">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
        <div className="border-b pb-6">
          <label className="text-gray-400 text-md font-semibold block mb-2">First Name</label>
          <p className="text-gray-800  font-medium mt-2 text-lg">{profile.firstName || "N/A"}</p>
        </div>
        <div className="border-b pb-6">
          <label className="text-gray-400 text-md font-semibold block pl-4 mb-2">Last Name</label>
          <p className="text-gray-800 font-medium mt-2 pl-4 text-lg">{profile.lastName || "N/A"}</p>
        </div>
        <div className="border-b pb-6">
          <label className="text-gray-400 text-md font-semibold block mb-2">Email</label>
          <p className="text-gray-800 font-medium mr-5 mt-2 text-lg">{profile.email || "N/A"}</p>
        </div>
        <div className="border-b pb-6">
          <label className="text-gray-400 text-md font-semibold block pl-4 mb-2">Address</label>
          <p className="text-gray-800 font-medium mt-2 text-lg pl-4 ">{profile.address || "N/A"}</p>
        </div>
      </div>

      {/* Edit Profile Button */}
      <div className="flex justify-center mt-8">
  <button
    onClick={handleEdit}
    className="px-8 py-3 bg-gradient-to-r from-indigo-500 to-purple-600 text-white text-lg font-medium rounded-full shadow-lg hover:shadow-xl hover:scale-105 transition-transform duration-300"
  >
    Edit Profile
  </button>
</div>

    </div>
  </div>
</div>









      {/* Toast Container */}
      <ToastContainer position="bottom-right" />
    </div>
    </>

  );
};

export default UserProfile;