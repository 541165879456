import React from "react";

// PricingCard Component
const PricingCard = ({ plan }) => {
  return (
    <div className="border rounded-xl shadow-lg p-6 bg-white flex flex-col items-center text-center transition-transform hover:scale-105">
      <h2 className="text-xl font-bold text-gray-800">{plan.name}</h2>
      <p className="text-2xl font-extrabold text-blue-600 mt-2">{plan.price}</p>
      <p className="text-sm text-gray-500">{plan.duration}</p>
      <button
        className="mt-4 py-2 px-6 bg-blue-500 text-white rounded-lg shadow hover:bg-blue-600"
        onClick={() => plan.onSelect(plan.name)}
      >
        {plan.cta}
      </button>
      <ul className="mt-4 space-y-2 text-gray-700 text-sm">
        {plan.features.map((feature, index) => (
          <li
            key={index}
            className={`flex items-center ${
              feature.included ? "text-green-600" : "text-red-600"
            }`}
          >
            {feature.included ? "✔" : "✘"} {feature.text}
          </li>
        ))}
      </ul>
    </div>
  );
};

// PayementDetails Component
const PayementDetails = () => {
  const pricingPlans = [
    {
      name: "Free",
      price: "₹0.00",
      duration: "Free",
      cta: "Try For Free",
      onSelect: (plan) => alert(`Selected Plan: ${plan}`),
      features: [
        { text: "1 Agent", included: true },
        { text: "6000 chats/month", included: true },
        { text: "Analytics Included", included: true },
        { text: "Advanced AI Training", included: true },
        { text: "In-built Lead Management", included: true },
        { text: "AI Smart Sales Agent", included: false },
        { text: "API & SDK", included: false },
        { text: "WhatsApp Integration", included: false },
      ],
    },
    {
      name: "Ignite",
      price: "₹432.65",
      duration: "+1 month free",
      cta: "Buy Now",
      onSelect: (plan) => alert(`Selected Plan: ${plan}`),
      features: [
        { text: "1 Agent", included: true },
        { text: "6000 chats/month", included: true },
        { text: "Analytics Included", included: true },
        { text: "Advanced AI Training", included: true },
        { text: "In-built Lead Management", included: true },
        { text: "AI Smart Sales Agent", included: true },
        { text: "API & SDK", included: false },
        { text: "WhatsApp Integration", included: false },
      ],
    },
    {
      name: "Elite",
      price: "₹1038.36",
      duration: "+2 months free",
      cta: "Buy Now",
      onSelect: (plan) => alert(`Selected Plan: ${plan}`),
      features: [
        { text: "3 Agents", included: true },
        { text: "15,000 chats/month", included: true },
        { text: "Analytics Included", included: true },
        { text: "Advanced AI Training", included: true },
        { text: "In-built Lead Management", included: true },
        { text: "AI Smart Sales Agent", included: true },
        { text: "API & SDK", included: true },
        { text: "WhatsApp Integration", included: true },
      ],
    },
    {
      name: "Stellar",
      price: "₹1730.60",
      duration: "+3 months free",
      cta: "Buy Now",
      onSelect: (plan) => alert(`Selected Plan: ${plan}`),
      features: [
        { text: "5 Agents", included: true },
        { text: "30,000 chats/month", included: true },
        { text: "Analytics Included", included: true },
        { text: "Advanced AI Training", included: true },
        { text: "In-built CRM Integration", included: true },
        { text: "AI Smart Sales Agent", included: true },
        { text: "API & SDK", included: true },
        { text: "WhatsApp Integration", included: true },
      ],
    },
  ];

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      {/* Header Section */}
      <div className="text-center mb-10">
        <h1 className="text-4xl font-bold text-gray-800">Our Pricing Plans</h1>
        <p className="text-gray-600 mt-2">
          Choose a plan that suits your needs and get started today.
        </p>
      </div>

      {/* Pricing Cards Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        {pricingPlans.map((plan, index) => (
          <PricingCard key={index} plan={plan} />
        ))}
      </div>
    </div>
  );
};

export default PayementDetails;
