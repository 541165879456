import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { FillFileIcon,OutlineFileTextIcon,OutlineGlobalIcon,OutlineDeleteIcon, } from "../../Svg icons/Svg";
import { FetchDataFromLocalStorage } from "../../hooks/FetchDataFromLocalStorage";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingGif from "../../img/LoadingGif.gif";
import useDrivePicker from 'react-google-drive-picker';

export const FileUploadPage = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // State for the loader
  const [collection, setCollection] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false); // State for the hamburger menu
  const [openPicker, authResponse] = useDrivePicker();  
  const { uuid } = useParams();
  const apiUrl = process.env.REACT_APP_API_URL;
  const userId = FetchDataFromLocalStorage("userId") ;
  const token = FetchDataFromLocalStorage('token') 

  const location = useLocation();
  const modelUrl = process.env.REACT_APP_AI_AGENT_URL;

  const handleUpdateCollectionName = async (uniqueId, collectionName) => {
    try {
      const response = await axios.put(`${apiUrl}/update-collection-name`, {
        uniqueId,
        collectionName,
      });

      if (response.data && response.data.updatedAgent) {
        console.log("Collection name updated successfully!");
      } else {
        console.warn("Unexpected response format:", response.data);
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Error updating collection name.",
        {
          position: "bottom-right",
        }
      );
    }
  };

  const handleTraining = async () => {
    setIsLoading(true); // Show loader
    try {
      console.log("Sending token:", token); // Debug the token value
      const response = await axios.post(
        `${modelUrl}/retrain`,
        {
          user_id: userId,
          agent_id: uuid,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      console.log("Training response:", response.data);
  
      if (response.data && response.data.details.collection_id) {
        toast.success("Training successful!", {
          position: "bottom-right",
        });
        handleUpdateCollectionName(uuid, response.data.details.collection_id);
        setCollection(response.data.details.collection_id);
      } else {
        console.warn("Unexpected response format:", response.data);
      }
    } catch (error) {
      console.error("Error during retraining:", error.response?.data || error);
      toast.error(
        error.response?.data?.detail || "Error initiating model retraining.",
        {
          position: "bottom-right",
        }
      );
    } finally {
      setIsLoading(false); // Hide loader
    }
  };

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await fetch(`${apiUrl}/getDocuments`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userId, uuid, type: "File" }),
        });

        const data = await response.json();

        if (response.ok) {
          const documents = Array.isArray(data?.data?.[`AiAgent ${uuid}`])
            ? data.data[`AiAgent ${uuid}`]
            : [];
            // console.log(documents.length);
          setDocumentList(documents);
        } else {
          toast.error(data.message || "Failed to load documents.", {
            position: "bottom-right",
          });
        }
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    };

    fetchDocuments();
  }, [apiUrl, userId, uuid]);

  const handleFileUpload = async (event) => {
    const maxFileSize = 10 * 1024 * 1024; // Maximum file size: 5MB
    const filesize = Array.from(event.target.files);

    // Filter files based on size
    const validFiles = filesize.filter((file) => {
      if (file.size > maxFileSize) {
        toast.warning(`File ${file.name} exceeds the maximum size of 1MB.`, {
          position: "top-center",
        });
        return false;
      }
      return true;
    });
    // 
    const files = event.target.files;
    if (!files.length) return;

    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("uuid", uuid);
    formData.append("DocumentType", "File");

    Array.from(files).forEach((file) => {
      formData.append("documents", file);
    });

    try {
      const response = await fetch(`${apiUrl}/uploadDocuments`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      const result = await response.json();

      if (result.message === "File uploaded successfully!") {
        toast.success("File uploaded successfully!", {
          position: "bottom-right",
        });
        window.location.reload();
      } else {
        toast.error("Error: " + result.message, {
          position: "bottom-right",
        });
      }
    } catch (error) {
      window.location.reload();
    }
  };

  const handleDeleteDocument = async document => {
    try {
      const response = await fetch(`${apiUrl}/deleteDocument`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId, documentName:document.documentName , uuid, DocumentType }),
      })

      if (response.ok) {
        setDocumentList(prevList =>
          prevList.filter(doc => doc.documentId !== document.documentId),
        )
        toast.success('Document deleted successfully!', {
          position: 'bottom-right',
        })
      } else {
        const errorData = await response.json()
        toast.error(errorData.message || 'Failed to delete document.', {
          position: 'bottom-right',
        })
      }
    } catch (error) {
      // toast.error("An error occurred while deleting the document.", {
      //   position: "bottom-right",
      // });
    }
  }

  const handleGoogleDriveUpload = () => {
    openPicker({
      clientId: "32122455482-aqfupteb6mi995h3ajsa5qtcejo1n2ge.apps.googleusercontent.com",
      developerKey: "AIzaSyBUVoQvwjOE0eBQ6FVG0zQd0FbpfQote4w",
      viewId: "DOCS",
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      callbackFunction: (data) => {
        if (data.action === 'picked') {
          const pickedFiles = data.docs.map((doc) => ({
            id: doc.id,
            name: doc.name,
            url: `https://drive.google.com/file/d/${doc.id}/view`,
          }));

          // Update the document list with new files
          setDocumentList((prevList) => [...prevList, ...pickedFiles]);
        }
      },
    });
  };

  // const handleDeleteDocument(doc) => {
  //   setDocumentList((prevList) => prevList.filter((item) => item.id !== doc.id));
  // };
      

  return (
    <div className="flex w-full shadow-lg fixed h-full flex bg-gray-100">
      {/* Hamburger Menu Button */}
      <button
        className="lg:hidden p-4 bg-purple-500 text-white fixed top-10 left-4 z-50 rounded-full hover:bg-purple-600 transition"
        onClick={() => setMenuOpen(!menuOpen)}
      >
        <div className="space-y-2">
          <span
            className={`block h-1 bg-white rounded transition-transform duration-300 ${
              menuOpen ? "transform rotate-45 translate-y-2" : ""
            }`}
          ></span>
          <span
            className={`block h-1 bg-white rounded transition-opacity duration-300 ${
              menuOpen ? "opacity-0" : ""
            }`}
          ></span>
          <span
            className={`block h-1 bg-white rounded transition-transform duration-300 ${
              menuOpen ? "transform -rotate-45 -translate-y-2" : ""
            }`}
          ></span>
        </div>
      </button>

      {/* Left Sidebar */}
      <div
        className={`lg:w-64 bg-white h-full p-6 shadow-lg fixed lg:static z-40 transition-transform duration-300 ${
          menuOpen ? "translate-x-0" : "-translate-x-full"
        } md:translate-x-0`}
      >
        <h1 className="text-2xl font-bold mb-6">Data Sources</h1>
        <ul className="space-y-4">
  <li>
    <Link
      to={`/files/${uuid}/files`}
      className="flex items-center py-2 px-4 rounded-lg hover:bg-[#e6f7fd] transition duration-300"
    >
      <FillFileIcon className="mr-2 text-[#007BFF]" /> {/* Updated icon color */}
      <span className="text-[#333] font-medium hover:text-[#007BFF]">
        Files
      </span>
    </Link>
  </li>
  <li>
    <Link
      to={`/files/${uuid}/text`}
      className="flex items-center py-2 px-4 rounded-lg hover:bg-[#e6f7fd] transition duration-300"
    >
      <OutlineFileTextIcon /> {/* Updated icon color */}
      <span className="text-[#333] font-medium hover:text-[#007BFF]">
        Text
      </span>
    </Link>
  </li>
  <li>
    <Link
      to={`/files/${uuid}/website`}
      className="flex items-center py-2 px-4 rounded-lg hover:bg-[#e6f7fd] transition duration-300"
    >
      <OutlineGlobalIcon/> {/* Updated icon color */}
      <span className="text-[#333] font-medium hover:text-[#007BFF]">
        Website
      </span>
    </Link>
  </li>
</ul>


        <div className="mt-6 lg:mt-auto py-10 w-full">
          <button
            onClick={handleTraining}
            className="w-full py-3 bg-blue-500 text-white font-bold rounded-lg hover:bg-[#8acfeb] transition duration-300"
            disabled={isLoading}
          >
            {isLoading ? "Training AI Agent..." : "Train AI Agent"}
          </button>
        </div>
      </div>

         {/* Main Page Container */}
      <div className="flex-1 p-6 h-screen overflow-y-auto">
        <div className="flex-1 p-6">
        <h1 className="text-3xl font-bold mb-6">Upload Files</h1>
        <p className="text-lg mb-6">
          Select files to upload from your device or Google Drive
        </p>

        {/* File Upload Section */}
        <div className="border bg-white p-5 rounded-lg mb-4">
          <h2 className="text-2xl font-bold mb-4">Files</h2>
          <p className="mb-4">
            Drag & drop files here, click to select files, or use Google Drive.
          </p>

          <div className="flex flex-col md:flex-row gap-4">
            <div className="border-2 border-dashed border-gray-300 p-6 rounded-lg cursor-pointer relative w-full md:w-1/2">
              <input
                type="file"
                multiple
                accept=".doc,.docx,.txt,.pdf"
                onChange={handleFileUpload}
                className="w-full h-full opacity-0 cursor-pointer absolute top-0 left-0"
              />
              <p className="text-center">Click or drag files here to upload</p>
              <p className="text-center text-sm text-gray-500 mt-2">
                Supported File Types: .pdf, .doc, .docx, .txt
              </p>
                {/* Static Warning Message */}
  <p className="text-center text-sm text-red-500 font-semibold mt-4">
    Please don’t upload any confidential data.
  </p>
            </div>

            <button
              onClick={handleGoogleDriveUpload}
              className="w-full md:w-1/2 py-3 bg-blue-500 text-white font-bold rounded-lg hover:bg-[#6bb9d6] transition duration-300"
            >
              Upload from Google Drive
            </button>
          </div>
        </div>

       {/* Display Uploaded Documents */}
       <div className="border bg-white p-5 rounded-lg mt-4">
        {documentList.length > 0 ? (
          <div className="mt-6">
            <h3 className="text-xl font-bold mb-4">Uploaded Documents</h3>
            <div className="h-60 overflow-y-auto border-t border-gray-200 pt-4">
              <ul className="space-y-2">
                {documentList.map((doc) => (
                  <li
                    key={doc.documentId}
                    className="flex items-center justify-between group hover:bg-gray-50 px-3 py-2 rounded-lg"
                  >
                    <div className="flex-1">
                      <a
                        href={doc.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        {doc.documentName}
                      </a>
                    </div>
                    <button
                      onClick={() => handleDeleteDocument(doc)}
                      className="text-red-500 hover:text-red-700 transition flex items-center"
                    >
                      
                      <OutlineDeleteIcon className="text-xl" />
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ) : (
          <p className="text-gray-500 mt-6">No documents uploaded yet.</p>
        )}
      </div>
    </div>
</div>


      {/* Loader Popup */}
      {isLoading && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg text-center">
            <img
              src={LoadingGif}
              alt="Loading..."
              className="w-16 h-16 mx-auto mb-4"
            />
            <p className="text-lg font-bold mb-2">Please wait...</p>
            <p>Your AI Agent is reading the data.</p>
          </div>
        </div>
      )}

      {/* Toast Notifications */}
      <ToastContainer position="bottom-right" autoClose={3000} />
    </div>
  );
};
