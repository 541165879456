import React, { useState, useEffect, useMemo } from 'react'
import {
  FaArrowLeft,
  FaArrowRight,
  FaPlus,
  FaSave,
  FaTimes,
} from 'react-icons/fa'
import axios from 'axios'
import { FetchDataFromLocalStorage } from '../../hooks/FetchDataFromLocalStorage'
import { useLocation, useNavigate } from 'react-router-dom'
import AgentsTable from '../AgentsTable'
import Sidebar from './SideBar'
import BreadCrumb from './BreadCrumb'
import { useAuth } from '../../contexts/authContext'
import JoyrideDemo from '../Agents/JoyrideDemo'
import { SaveIcon, XMarkIcon } from '../../Svg icons/Svg'
import { toast } from 'react-toastify'

export const Chatbots = () => {
  const [chatbots, setChatbots] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [chatbotName, setChatbotName] = useState('')
  const [customizationModalOpen, setCustomizationModalOpen] = useState(false)
  const [selectedChatbot, setSelectedChatbot] = useState(null)
  const [collection, setCollection] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [customizationData, setCustomizationData] = useState({
    agentColor: '#000000',
    userColor: '#000000',
    font: 'Arial',
    agentImg: null,
    backgroundColor: '#FFFFFF',
    name: '',
    welcomeMessage: '',
  })

  const itemsPerPage = 5
  const navigate = useNavigate()
  const location = useLocation()
  const apiUrl = process.env.REACT_APP_API_URL
  const modelUrl = process.env.REACT_APP_AI_AGENT_URL

  const { checkToken } = useAuth()
  const userId = FetchDataFromLocalStorage('userId')
  const token = FetchDataFromLocalStorage('token')

  useEffect(() => {
    const fetchChatbots = async () => {
      setIsLoading(true)
      try {
        const response = await axios.post(
          `${apiUrl}/getMyChatBot`,
          { userId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )

        const allChatbots = response.data.data.map(chatbot => ({
          _id: chatbot._id || chatbot.aiAgentId,
          uniqueId: chatbot.uniqueId || chatbot._id || chatbot.aiAgentId,
          url: chatbot.url || '',
          name: chatbot.name || 'Unnamed Agent',
          userId: chatbot.userId || userId,
          createdAt: chatbot.createdAt || new Date().toISOString(),
          collectionName: chatbot.collectionName || null,
        }))

        setChatbots(allChatbots)
      } catch (error) {
        console.error('Error fetching chatbots:', error)
        alert('Failed to fetch chatbots. Please try again.')
      } finally {
        setIsLoading(false)
      }
    }

    if (token) fetchChatbots()
  }, [userId, token, apiUrl])

  const handleSidebarToggle = isOpen => {
    setIsSidebarOpen(isOpen)
  }

  const onAgentDeleted = agentId => {
    setChatbots(prevChatbots =>
      prevChatbots.filter(agent => agent.uniqueId !== agentId),
    )
  }

  const openCustomizationModal = chatbotz => {
    localStorage.setItem('current_uuid', chatbotz.uniqueId)
    setSelectedChatbot(chatbotz)
    console.log(chatbotz, 'chatbot')
    setCustomizationData({
      agentId: chatbotz._id,
      agentColor: chatbotz.agentColor || '#000000',
      userColor: chatbotz.userColor || '#000000',
      font: chatbotz.font || 'Arial',
      agentImg: null,
      backgroundColor: chatbotz.backgroundColor || '#FFFFFF',
      name: chatbotz.name || '',
      welcomeMessage: chatbotz.welcomeMessage || '',
    })
    setCustomizationModalOpen(true)
  }

  // Handle customization input changes
  const handleCustomizationChange = (field, value) => {
    setCustomizationData(prevData => ({
      ...prevData,
      [field]: value,
    }))
  }

  // const handleCreateChatbot = async () => {
  //   try {
  //     const response = await axios.post(
  //       `${apiUrl}/create-chatbot`,
  //       { name: chatbotName, userId },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       },
  //     )

  //     const { data } = response

  //     console.log(response,"data creation")

  //     const newChatbot = {
  //       _id: data.data.aiAgentId,
  //       uniqueId: data.data.uniqueId,
  //       url: data.data.url,
  //       name: chatbotName,
  //       userId,
  //       createdAt: data.data.createdAt,
  //       collectionName: null,
  //     }

  //     setChatbots(prevChatbots => [...prevChatbots, newChatbot])
  //     setChatbotName('')
  //     setModalOpen(false)
  //   } catch (error) {
  //     console.error('Error creating chatbot:', error)
  //     // alert('Failed to create chatbot. Please try again.')
  //   }
  // }

  const handleCreateChatbot = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/create-chatbot`,
        { name: chatbotName, userId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      const { data } = response;
  
      console.log(response, "data creation");
  
      const newChatbot = {
        _id: data.data.aiAgentId,
        uniqueId: data.data.uniqueId,
        url: data.data.url,
        name: chatbotName,
        userId,
        createdAt: data.data.createdAt,
        collectionName: null,
      };
  
      setChatbots(prevChatbots => [...prevChatbots, newChatbot]);
      setChatbotName('');
      setModalOpen(false);
    } catch (error) {
      console.error('Error creating chatbot:', error);
  
      // Check if the error has a response and a specific message
      if (error.response && error.response.data && error.response.data.message) {
        toast.error(error.response.data.message, {
          position: 'bottom-right',
          autoClose: 3000,
        });
      setChatbotName('');

      setModalOpen(false);

      } else {
        // Fallback for unexpected errors
        toast.error('Failed to create chatbot. Please try again.', {
          position: 'bottom-right',
          autoClose: 3000,
        });
      }
    }
  };
  

  const handleCancelCustomizations = () => {
    setCustomizationModalOpen(false)
    setSelectedChatbot(null)
  }

  React.useEffect(() => {
    if (!localStorage.getItem('firstVisit')) {
      setShowModal(true)
    }
  }, [])

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleImageChange = file => {
    setCustomizationData(prevData => ({
      ...prevData,
      agentImg: file,
    }))
  }
  const handleCreation = () => {
    setModalOpen(true)
    checkToken()
  }

  const { currentChatbots, totalPages } = useMemo(() => {
    const indexOfLastItem = currentPage * itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage
    return {
      currentChatbots: chatbots.slice(indexOfFirstItem, indexOfLastItem),
      totalPages: Math.ceil(chatbots.length / itemsPerPage),
    }
  }, [chatbots, currentPage])

  const handleSaveCustomizations = async () => {
    if (!selectedChatbot) return

    const formData = new FormData()
    formData.append('agentId', selectedChatbot._id)
    formData.append('AgentColor', customizationData.agentColor)
    formData.append('UserColor', customizationData.userColor)
    formData.append('font', customizationData.font)
    formData.append('BackGroundColor', customizationData.backgroundColor)
    formData.append('name', customizationData.name)
    formData.append('welcomeMessage', customizationData.welcomeMessage)
    if (customizationData.agentImg) {
      formData.append('agentImg', customizationData.agentImg)
    }

    try {
      await axios.post(`${apiUrl}/agent-update`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })

      alert('Customizations saved successfully!')
      setCustomizationModalOpen(false)
      setSelectedChatbot(null)
    } catch (error) {
      console.error('Error saving customizations:', error)
      alert('Failed to save customizations. Please try again.')
    }
  }

  const handlePrevious = () => {
    setCurrentPage(prevPage => prevPage - 1)
  }

  const handleNext = () => {
    setCurrentPage(prevPage => prevPage + 1)
  }

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.matchMedia('(max-width: 768px)').matches
      setIsSidebarOpen(!isMobile) // Close sidebar on mobile, open on larger screens
    }

    // Initialize state based on the initial screen size
    handleResize()

    // Add event listener for window resize
    window.addEventListener('resize', handleResize)

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      <div
        className="flex flex-col  bg-[#F8F9FA] w-full shadow-lg fixed justify-start py-3 px-4 "
        style={{ height: '100vh' }}
      >
        <Sidebar onToggle={handleSidebarToggle} />
        <div
          className=" h-full"
          style={{
            marginLeft: isSidebarOpen ? '250px' : '0',
            transition: 'margin 0.3s ease-in-out',
          }}
        >
          <BreadCrumb main="Dashboard" sub1="Agents" />
          <div className="flex justify-between items-center mt-6">
            <h4 className="text-2xl font-semibold">
              AI <span className="text-[#6600ff]"> - </span> Agents
            </h4>
            {/* <button
              id="newagent"
              onClick={() => setModalOpen(true)}
              className="py-2 px-4  flex items-center gap-2 justify-centerrounded-md bg-[#000000] text-white hover:bg-[#4d00bf] transition-all duration-300 ease-in-out"
              style={{borderRadius: '5px'}}
            >
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor"><path d="M450-450H220v-60h230v-230h60v230h230v60H510v230h-60v-230Z"/></svg>
              Create New Agent
            </button> */}
          </div>

          {isLoading ? (
            <div className="text-lg font-semibold text-gray-600">
              Loading AI Agents...
            </div>
          ) : (
            <AgentsTable
              isSidebarOpen={isSidebarOpen}
              currentChatbots={currentChatbots}
              currentPage={currentPage}
              totalPages={totalPages}
              openCustomizationModal={openCustomizationModal}
              onAgentDeleted={onAgentDeleted}
              setModalOpen={setModalOpen}
            />
          )}
          {totalPages > 1 && (
            <div className="flex justify-between items-center p-4 bg-gray-50 border-t">
              <button
                onClick={handlePrevious}
                disabled={currentPage === 1 || isLoading}
                className={`inline-flex items-center px-4 py-2 rounded-md ${
                  currentPage === 1 || isLoading
                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                    : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-300'
                } transition-colors duration-200`}
              >
                <FaArrowLeft className="mr-2" />
                <span>Previous</span>
              </button>
              <span className="text-gray-600 font-medium">
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={handleNext}
                disabled={currentPage === totalPages || isLoading}
                className={`inline-flex items-center px-4 py-2 rounded-md ${
                  currentPage === totalPages || isLoading
                    ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                    : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-300'
                } transition-colors duration-200`}
              >
                <span>Next</span>
                <FaArrowRight className="ml-2" />
              </button>
            </div>
          )}
        </div>
        {modalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full mx-4">
              <h2 className="text-lg font-semibold ">
                Enter Agent Name
                 {/* <span className='text-[#6600ff] text-base'>  */}
                   
                    {/* </span>  */}
              </h2>
              <input
                type="text"
                value={chatbotName}
                onChange={e => setChatbotName(e.target.value)}
                placeholder="Eg: Vinsights"
                className="w-full px-4 py-2 border rounded-md mb-6 focus:outline-none focus:ring-2 focus:ring-[#6600ff]"
              />
              <div className="flex justify-end space-x-4">
                <button
                  onClick={() => setModalOpen(false)}
                  className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors"
                >
                  Cancel
                </button>
                <button
                  onClick={handleCreateChatbot}
                  className="px-4 py-2 bg-[#6600ff] text-white rounded hover:bg-[#5500cc] transition-colors"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}

        {customizationModalOpen && (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-6 rounded-lg w-3/4 md:w-2/3 mt-8 lg:w-1/2 shadow-lg max-h-[80vh] overflow-y-auto relative">
              {/* Close Button */}
              <button
                id="setting"
                onClick={handleCancelCustomizations}
                className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
              >
                <FaTimes className="text-xl" />
              </button>

              <h2 className="text-xl font-semibold mb-4">Customize AI-Agent</h2>

              {/* Chatbox Preview */}
              <div
                className="bg-gray-50 p-6 rounded-lg shadow-lg border border-gray-200 mt-6"
                style={{
                  backgroundImage: customizationData.agentImg
                    ? `url(${URL.createObjectURL(customizationData.agentImg)})`
                    : 'none', // If no image, no background image
                  backgroundColor: customizationData.agentImg
                    ? 'transparent'
                    : customizationData.backgroundColor, // Solid color if no image
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  fontFamily: customizationData.font, // Apply selected font
                }}
              >
                <h3 className="text-lg font-semibold mb-4 text-gray-700 text-center">
                  Chatbox Preview
                </h3>

                <div className="space-y-6">
                  {/* Agent Message */}
                  <div className="flex items-start">
                    <div
                      className="p-4 rounded-tl-none rounded-lg shadow-md max-w-xs"
                      style={{
                        backgroundColor: customizationData.agentColor,
                        fontFamily: customizationData.font, // Apply selected font
                      }}
                    >
                      <p className="text-white text-sm">
                        Hello! How can I assist you today?
                      </p>
                    </div>
                  </div>

                  {/* User Message */}
                  <div className="flex items-end justify-end">
                    <div
                      className="p-4 rounded-tr-none rounded-lg shadow-md max-w-xs"
                      style={{
                        backgroundColor: customizationData.userColor,
                        fontFamily: customizationData.font, // Apply selected font
                      }}
                    >
                      <p className="text-white text-sm">
                        I need help with my account.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 gap-6 mb-6">
                {/* Grouping Agent Color, User Color, and Background Color */}
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
                  {/* Agent Color */}
                  <div className="flex items-center">
                    <label className="text-gray-700 w-1/2 text-right pr-4">
                      Agent Color:
                    </label>
                    <input
                      type="color"
                      value={customizationData.agentColor}
                      onChange={e =>
                        handleCustomizationChange('agentColor', e.target.value)
                      }
                      className="w-16 h-8 border border-gray-300 rounded-md"
                    />
                  </div>

                  {/* User Color */}
                  <div className="flex items-center">
                    <label className="text-gray-700 w-1/2 text-right pr-4">
                      User Color
                    </label>
                    <input
                      type="color"
                      value={customizationData.userColor}
                      onChange={e =>
                        handleCustomizationChange('userColor', e.target.value)
                      }
                      className="w-16 h-8 border border-gray-300 rounded-md"
                    />
                  </div>

                  {/* Background Color */}
                  <div className="flex items-center">
                    <label className="text-gray-700 w-1/2 text-right pr-4">
                      Background Color
                    </label>
                    <input
                      type="color"
                      value={customizationData.backgroundColor}
                      onChange={e =>
                        handleCustomizationChange(
                          'backgroundColor',
                          e.target.value,
                        )
                      }
                      className="w-16 h-8 border border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </div>
              {/* Group Font and Agent Name into a single row */}
              <div className="flex gap-6">
                {/* Font Selector */}
                <div className="flex-1 p-5">
                  <label className="block font-bold text-block mb-2">
                    Font
                  </label>
                  <select
                    value={customizationData.font}
                    onChange={e =>
                      handleCustomizationChange('font', e.target.value)
                    }
                    className="border px-4 py-2 rounded-md w-full"
                    style={{ border: '1px solid #007fff' }}
                  >
                    <option value="Arial">Arial</option>
                    <option value="Verdana">Verdana</option>
                    <option value="Times New Roman">Times New Roman</option>
                    <option value="Courier New">Courier New</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Tahoma">Tahoma</option>
                    <option value="Trebuchet MS">Trebuchet MS</option>
                    <option value="Impact">Impact</option>
                  </select>
                </div>

                {/* Agent Name */}
                <div className="flex-1 p-5">
                  <label className="block font-bold text-gray-700 mb-2">
                    Agent Name
                  </label>
                  <input
                    type="text"
                    value={customizationData.name}
                    onChange={e =>
                      handleCustomizationChange('name', e.target.value)
                    }
                    className="border border-blue-500 px-4 py-2 rounded-md w-full"
                    placeholder="Enter Agent Name"
                  />
                </div>
              </div>
              {/* Agent Image and Welcome Message */}
              <div className="flex gap-6">
                {/* Agent Image */}
                <div className="flex-1 max-w-xs">
                  <label className="block text-gray-700 mb-2 font-bold">
                    Agent Image
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={e => handleImageChange(e.target.files[0])}
                    className="border border-blue-500 px-4 py-2 rounded-md w-full"
                  />
                  {customizationData.agentImg ? (
                    <img
                      src={URL.createObjectURL(customizationData.agentImg)}
                      alt="Agent Preview"
                      className="mt-2 w-16 h-16 object-cover rounded"
                    />
                  ) : selectedChatbot.agentImg ? (
                    <img
                      src={selectedChatbot.agentImg}
                      alt="Agent"
                      className="mt-2 w-16 h-16 object-cover rounded"
                    />
                  ) : null}
                </div>
                {/* Welcome Message */}
                <div className="flex-1">
                  <label className="block font-bold text-gray-700 mb-2">
                    Welcome Message
                  </label>
                  <textarea
                    value={customizationData.welcomeMessage}
                    onChange={e =>
                      handleCustomizationChange(
                        'welcomeMessage',
                        e.target.value,
                      )
                    }
                    className="border px-4 py-2 border-blue-500 rounded-md w-full h-20" // Smaller height
                    rows="4" // Adjusted rows for smaller height
                    placeholder="Enter welcome message"
                  />
                </div>
              </div>
              {/* Save Button */}
              <div className="mt-6 flex justify-center">
                <button
                  onClick={handleSaveCustomizations}
                  className="bg-blue-500 text-white font-bold py-3 px-8 rounded-full shadow-lg hover:from-green-600 hover:bg-blue-600 transform transition-all duration-300 ease-in-out flex items-center justify-center"
                >
                  <FaSave className="font-bold mr-3 text-xl" /> Save Changes
                </button>
              </div>
            </div>
          </div>
        )}
        <JoyrideDemo isVisible={showModal} onClose={handleCloseModal} />
      </div>
    </>
  )
}
