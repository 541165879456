import React from 'react';

const Announcements = () => {
  const announcements = [
    'New feature "Chatbot Analytics" launched!',
    'Server maintenance scheduled for this Friday.',
    'Introducing dynamic themes for the dashboard.',
  ];

  return (
    <div className="bg-white shadow-md rounded-lg p-4">
      <h2 className="text-lg font-semibold mb-2">Announcements</h2>
      <ul className="space-y-2">
        {announcements.map((announcement, index) => (
          <li key={index} className="text-md text-gray-600">
            <i className="fas fa-bullhorn text-purple-600 mr-2"></i>
            {announcement}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Announcements;
