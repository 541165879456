import React from 'react';

const StatsBox = ({ title, value, isActive }) => {
  return (
    <div
      className={`px-4 py-2 rounded-lg cursor-pointer ${
        isActive ? 'text-purple-600 font-bold' : 'text-gray-600'
      }`}
    >
      <h3 className="text-md">{title}</h3>
      <p className="text-lg">{value}</p>
    </div>
  );
};

export default StatsBox;
