import React from "react";
import { Link } from "react-router-dom";
import { FillFileIcon,OutlineFileTextIcon,OutlineGlobalIcon } from "../../Svg icons/Svg";

export const MainPage = () => {

  console.log("hello");
  return (
    <div className="flex w-full shadow-lg fixed h-full flex bg-gray-100">
      {/* Left Sidebar */}
      <div className="w-64 bg-white h-screen p-6 shadow-lg fixed">
        <h1 className="text-2xl font-bold mb-6">Data Sources</h1>
        <ul className="space-y-4">
          <li>
            <Link
              to="/files"
              className="flex items-center py-2 px-4 rounded-lg hover:bg-purple-200 transition duration-300"
            >
              <FillFileIcon className="mr-2 text-purple-500" /> {/* File icon */}
              Files
            </Link>
          </li>
          <li>
            <Link
              to="/text"
              className="flex items-center py-2 px-4 rounded-lg hover:bg-purple-200 transition duration-300"
            >
              <OutlineFileTextIcon className="mr-2 text-purple-500" />{" "}
              {/* Text icon */}
              Text
            </Link>
          </li>
          <li>
            <Link
              to="/website"
              className="flex items-center py-2 px-4 rounded-lg hover:bg-purple-200 transition duration-300"
            >
              <OutlineGlobalIcon className="mr-2 text-purple-500" />{" "}
              {/* Website icon */}
              Website
            </Link>
          </li>
          
        </ul>
      </div>

      {/* Main Content */}
      <div className="flex-1 p-6 ml-64">
        <h1 className="text-3xl font-bold mb-4">Welcome to the Main Page</h1>
        <p className="text-lg">
          This is your main landing page. From here, you can navigate to
          different sections of the app.
        </p>
      </div>
    </div>
  );
};


