import React, { useState } from 'react'
import { Link, NavLink, useParams } from 'react-router-dom'
import {FillFileIcon,
OutlineFileTextIcon,
  OutlineGlobalIcon,BarsIcon,
  XMarkIcon
} from "../../Svg icons/Svg"
import { FetchDataFromLocalStorage } from '../../hooks/FetchDataFromLocalStorage'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import aisaXLogo from '../../img/aisaXLogo.png'


export const AgentTextUploadPage = () => {
  const [text, setText] = useState('') // State to hold the input text
  const { uuid } = useParams() // Access chatbotId from URL params
  const chatbotId = uuid
  const apiUrl = process.env.REACT_APP_API_URL // Replace with your actual API URL
  const clientUrl = process.env.REACT_APP_URL
  const [loading, setLoading] = useState(false) // State to manage loading
  const [message, setMessage] = useState('') // State for feedback message
  const [final, setFinal] = useState('') // State for feedback message
  const userId = FetchDataFromLocalStorage('userId') // Retrieve user ID from local storage
  const uuuid = FetchDataFromLocalStorage('current_uuid')
  const token = FetchDataFromLocalStorage('token') 
const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false) // State for the loader
  const [collection, setCollection] = useState(null)

  // const uuId = FetchDataFromLocalStorage("current_uuid") || 100; // Retrieve user ID from local storage

  const modelUrl = process.env.REACT_APP_AI_AGENT_URL

  const handleTextUpload = async () => {
    if (!text.trim()) {
      setMessage('Text field is empty. Please enter some text.')
      return
    }

    setLoading(true)
    setMessage('') // Clear any existing messages

    try {
      // Prepare the data for the API call
      const payload = {
        uuid: uuuid,
        DocumentType: 'Text',
        documentText: text,
        userId: userId,
      }

      // API call to upload text
      const response = await fetch(`${apiUrl}/uploadDocuments`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      })

      const result = await response.json()
      setFinal(result)

      if (response.ok) {
        setMessage('Text uploaded successfully!')
        setText('') // Clear the text area
      } else {
        setMessage(result.message || 'Failed to upload text.')
      }
    } catch (error) {
      console.error('Error uploading text:', error)
      setMessage('An error occurred while uploading the text.')
    } finally {
      setLoading(false)
    }
  }

  const handleUpdateCollectionName = async (uniqueId, collectionName) => {
    try {
      const response = await axios.put(`${apiUrl}/update-collection-name`, {
        uniqueId,
        collectionName,
      })

      if (response.data && response.data.updatedAgent) {
        // toast.success("Collection name updated successfully!", {
        //   position: "bottom-right",
        // });
      } else {
        console.warn('Unexpected response format:', response.data)
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || 'Error updating collection name.',
        {
          position: 'bottom-right',
        },
      )
    }
  }

  // const { uuid } = useParams();

  const handleTraining = async () => {
    setIsLoading(true) // Show loader
    try {
      const response = await axios.post(`${modelUrl}/retrain`, {
        user_id: userId,
        agent_id: uuuid,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
      console.log(response, 'response')

      if (response.data && response.data.details.collection_id) {
        toast.success('Training successful!', {
          position: 'bottom-right',
        })
        handleUpdateCollectionName(uuuid, response.data.details.collection_id)
        setCollection(response.data.details.collection_id)
      } else {
        console.warn('Unexpected response format:', response.data)
      }
    } catch (error) {
      toast.error(
        error.response?.data?.detail || 'Error initiating model retraining.',
        {
          position: 'bottom-right',
        },
      )
    } finally {
      setIsLoading(false) // Hide loader
    }
  }

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
   const closeSidebar = (e) => {
    if (e.target.closest('#sidebar')) return;
    setIsSidebarOpen(false);
  };
  return (
    <>
       <div className=' min-h-screen bg-gradient-to-r from-blue-500 to-purple-500'> 
            {/* <TopNav/> */}
            <div className={`min-h-screen  transition-colors duration-300`}>
              {/* Top Navbar */}
                   <div className="fixed top-0 left-0 right-0 z-50 bg-blue-500 shadow-md py-2 flex items-center">
                     {/* Logo */}
                     <div className="ml-4">
                       <a href={`${clientUrl}/agents`}>
                         <img
                           src={aisaXLogo}
                           alt="AiSA-X Logo"
                           className="w-36 h-auto cursor-pointer"
                         />
                       </a>
                     </div>
             
                     {/* Hamburger Icon for Sidebar */}
                     <button
                       onClick={toggleSidebar}
                       className="ml-auto mr-4 text-white text-2xl focus:outline-none lg:hidden"
                     >
                       {isSidebarOpen ? <XMarkIcon /> : <BarsIcon />}
                     </button>
             
                     {/* Navigation Tabs for Larger Screens */}
                     <ul className="hidden lg:flex justify-around w-full px-[20%]">
                       <li className="text-white font-bold cursor-pointer">
                         <NavLink
                           to={`/agent/${chatbotId}`}
                           className={({ isActive }) =>
                             isActive ? 'text-yellow-300' : 'text-white'
                           }
                           end
                         >
                           Playground
                         </NavLink>
                       </li>
                       {['Activity', 'Analysis', 'Sources', 'Connect', 'Settings'].map(
                         section => (
                           <li key={section} className="text-white font-bold cursor-pointer">
                             <NavLink
                               to={`/agent/${chatbotId}/${section.toLowerCase()}`}
                               className={({ isActive }) =>
                                 isActive ? 'text-yellow-300' : 'text-white'
                               }
                               end
                             >
                               {section}
                             </NavLink>
                           </li>
                         )
                       )}
                     </ul>
                   </div>
                  
                   {/* Sidebar for Smaller Screens */}
                   {isSidebarOpen && (
                     <div className="fixed top-0 right-0 w-64 h-full bg-white shadow-lg z-50 p-4 lg:hidden">
                       <ul>
                         <li className="text-gray-800 font-bold cursor-pointer mb-4">
                           <NavLink
                             to={`/agent/${chatbotId}`}
                             onClick={toggleSidebar}
                             className={({ isActive }) =>
                               isActive ? 'text-blue-500' : 'text-gray-800'
                             }
                             end
                           >
                             Playground
                           </NavLink>
                         </li>
                         {['Activity', 'Analysis', 'Sources', 'Connect', 'Settings'].map(
                           section => (
                             <li
                               key={section}
                               className="text-gray-800 font-bold cursor-pointer mb-4"
                             >
                               <NavLink
                                 to={`/agent/${chatbotId}/${section.toLowerCase()}`}
                                 onClick={toggleSidebar}
                                 className={({ isActive }) =>
                                   isActive ? 'text-blue-500' : 'text-gray-800'
                                 }
                                 end
                               >
                                 {section}
                               </NavLink>
                             </li>
                           )
                         )}
                       </ul>
                     </div>
                   )}
             


             <div className="pt-10 mt-10 flex w-full flex-col h-full bg-gradient-to-r from-blue-500 to-purple-500">
      
        {/* Hamburger Button for Mobile */}
        {/* <button
        className="fixed top-16 left-4 z-50 bg-gray-500 text-white  p-2 rounded-full lg:hidden"
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        {isSidebarOpen ? <XMarkIcon className="w-6 h-6" /> : <BarsIcon className="w-6 h-6" />}
      </button> */}

        {/* Left Sidebar */}
        <div
          id="sidebar"
          className={`w-64 bg-white h-screen p-6 shadow-lg fixed transform transition-transform duration-300 z-40 lg:translate-x-0 ${
            isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
          }`}
        >
          <h1 className="text-2xl font-bold mb-6">Data Sources</h1>
          <ul className="space-y-4">
            <li>
              <NavLink
                to={`/agent/${uuid}/files`}
                className={({ isActive }) =>
                  `flex items-center py-2 px-4 rounded-lg transition duration-300 ${
                    isActive ? 'bg-[#8acfeb] text-white' : 'hover:bg-[#e6f7fd] text-[#333]'
                  }`
                }
              >
                < FillFileIcon />
                <span className="font-medium">Files</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/agent/${uuid}/text`}
                className={({ isActive }) =>
                  `flex items-center py-2 px-4 rounded-lg transition duration-300 ${
                    isActive ? 'bg-[#8acfeb] text-black' : 'hover:bg-[#e6f7fd] text-[#333]'
                  }`
                }
              >
                <OutlineFileTextIcon />
                <span className="font-medium">Text</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/agent/${uuid}/website`}
                className={({ isActive }) =>
                  `flex items-center py-2 px-4 rounded-lg transition duration-300 ${
                    isActive ? 'bg-[#8acfeb] text-white' : 'hover:bg-[#e6f7fd] text-[#333]'
                  }`
                }
              >
                < OutlineGlobalIcon />
                <span className="font-medium">Website</span>
              </NavLink>
            </li>
          </ul>
          <div className="mt-auto py-10 w-full">
            <button
              onClick={handleTraining}
              className="w-full py-3 bg-blue-500 text-white font-bold rounded-lg hover:bg-blue-600 transition duration-300"
              disabled={isLoading}
            >
              {isLoading ? 'Training AI Agent...' : 'Train AI Agent'}
            </button>
          </div>
        </div>

        {/* Overlay for closing sidebar */}
       
        {isSidebarOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-30 lg:hidden"
            onClick={closeSidebar}
          ></div>
        )}

        {/* Main Content for Text Upload */}
        <div className="flex-1 lg:ml-64 p-6 overflow-y-auto  min-h-screen p-6 bg-gray-100">
        {/* <div className=""> */}
          
          <h1 className="text-3xl font-bold mb-4">Text Upload</h1>
          <p className="text-lg mb-2">
            Enter the text you would like to upload for training your AI Agent.
          </p>
          <p className="text-sm text-red-500 font-semibold mb-4">
            Please don’t upload any confidential Text.
          </p>
          <div className="bg-white p-6 rounded-lg shadow-lg mb-6">
            <textarea
              className="w-full h-64 p-4 border border-gray-300 rounded-lg"
              placeholder="Enter text here..."
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </div>
          <button
            onClick={handleTextUpload}
            className="px-6 py-3 bg-blue-500 text-white font-bold rounded-lg hover:bg-blue-600 transition duration-300"
            disabled={loading}
          >
            {loading ? 'Uploading...' : 'Upload Text'}
          </button>

          {message && (
            <p
              className={`mt-4 ${final.ok ? 'text-green-500' : 'text-red-500'}`}
            >
              {message}
            </p>
          )}
        {/* </div> */}
        <ToastContainer position="bottom-right" autoClose={3000} />
      </div>
    </div>
      </div>
      </div>
    </>
  )
}
