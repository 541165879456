import { useEffect, useState } from "react";
import ChatImg from "../../img/Chatingimg.jpg"; // Adjust the path if needed
import { Outlet, useParams, NavLink, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FetchDataFromLocalStorage } from "../../hooks/FetchDataFromLocalStorage";
import axios from "axios";

export default function Playground() {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState("");
    const [loading, setLoading] = useState(false);
    const { chatbotId } = useParams(); // Retrieve chatbotId from URL parameters
    // console.log(chatbotId,"chatbotId")
    const location = useLocation();
    const navigate = useNavigate();
    const modelUrl = process.env.REACT_APP_AI_AGENT_URL;
    const apiUrl = process.env.REACT_APP_API_URL;


    const userId = FetchDataFromLocalStorage("userId") || 100; 
    const uuid = FetchDataFromLocalStorage("current_uuid") || 100; 


    const fetchInitialMessage = async () => {
      if (!modelUrl) {
        console.error("Model URL is not defined.");
        return;
      }
    

      const params = {
        userId: userId,          // Add user ID as a query parameter
        agentId: chatbotId,      // Add agent ID as a query parameter
        collection_id: chatbotId // Add collection_id as a query parameter
      };
    
      try {
        // Send GET request with query parameters
        const response = await axios.get(`${apiUrl}/initial_message/${uuid}`, { params });
    
        // Check response structure
        if (response.data.success && response.data.agent_Details) {
          const welcomeMessage = response.data.agent_Details.welcomeMessage;
          const name =response.data.agent_Details.name;
          if (welcomeMessage) {
            // console.log(welcomeMessage, "data");
            typeMessage(welcomeMessage); 
          } else {
            typeMessage(`hi i am ${name} , How can i assisst you `); 

          }
        } else {
          throw new Error("Invalid response format");
        }
      } catch (error) {
        console.error("Error fetching initial message:", error);
        setMessages([
          { text: "Error: Could not load the initial message.", sender: "Bot" },
        ]);
      }
    };
    
    
  
    useEffect(() => {
      fetchInitialMessage();
    }, []);
  

    const sendMessageToBackend = async (userMessage) => {
      try {
        const response = await fetch(`${modelUrl}/query`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            question: userMessage,
            collection_id: chatbotId,
            company_name: "company",
          }),
        });
    
        if (!response.body) throw new Error("No response body received");
    
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
    
        // Start a new streaming bot message
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: "", sender: "Bot", isStreaming: true },
        ]);
    
        let liveMessage = ""; // To hold the message as chunks arrive
    
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
    
          const chunk = decoder.decode(value, { stream: true });
          const lines = chunk.split("\n");
    
          for (const line of lines) {
            if (line.startsWith("data:")) {
              const messagePart = line.replace("data:", "").trim();
              if (messagePart) {
                liveMessage += messagePart + " ";
    
                // Format the live message
                const formattedMessage = formatMessage(liveMessage);
    
                setMessages((prevMessages) => {
                  const updatedMessages = [...prevMessages];
                  const lastMessage = updatedMessages[updatedMessages.length - 1];
    
                  if (lastMessage.sender === "Bot" && lastMessage.isStreaming) {
                    lastMessage.text = formattedMessage; // Update live message
                  }
                  return updatedMessages;
                });
              }
            }
          }
        }
    
        // Finalize the bot message
        setMessages((prevMessages) => {
          const updatedMessages = [...prevMessages];
          const lastMessage = updatedMessages[updatedMessages.length - 1];
          if (lastMessage.isStreaming) delete lastMessage.isStreaming;
          return updatedMessages;
        });
      } catch (error) {
        console.error("Error fetching response:", error);
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: "Error: Could not connect to the agent.", sender: "Bot" },
        ]);
      } finally {
        setLoading(false);
      }
    };
    
    // Function to format message: Bold **text** and structure numbered items
    const formatMessage = (text) => {
      // Step 1: Replace **text** with bold HTML tags
      let formattedText = text.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
    
      // Step 2: Remove numbers like "1." and push the content to a new line as a heading
      formattedText = formattedText.replace(
        /(\d+\.)\s(.*?)(?=(\d+\.)|$)/g, // Match "1. Content" up to the next number or end
        (match, _, content) =>
          `<div style="margin-top:10px;">
             <h4 style="margin:0; font-weight:bold; font-size:16px;">${content}</h4>
           </div>`
      );
    
      // Step 3: Replace newlines with <br /> for clean HTML rendering
      return formattedText.replace(/\n/g, "<br />");
    };
    
    
    
    
    
    
    
    
    
    
  
    const handleSendMessage = async () => {
      if (input.trim()) {
        const newMessage = { text: input, sender: "User" };
        setMessages([...messages, newMessage]);
        setInput("");
        setLoading(true);
    
        await sendMessageToBackend(input);
      }
    };
    
    const typeMessage = async (text) => {
      setLoading(true);
    
      // Start a new bot message to append the data
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: "", sender: "Bot", isStreaming: true },
      ]);
    
      const chunks = text.split("\n").filter((chunk) => chunk.trim()); // Split into chunks and remove empty ones
    
      for (const chunk of chunks) {
        const cleanedChunk = chunk.replace(/^data:\s*/, ""); // Remove "data: " prefix
    
        if (cleanedChunk) {
          setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages];
            const lastMessage = updatedMessages[updatedMessages.length - 1];
    
            // Append the cleaned chunk to the existing bot message
            if (lastMessage.sender === "Bot" && lastMessage.isStreaming) {
              lastMessage.text += (lastMessage.text ? " " : "") + cleanedChunk; // Append with a space
            }
    
            return updatedMessages;
          });
    
          await new Promise((resolve) => setTimeout(resolve, 50)); // Typing effect delay
        }
      }
    
      // Finalize the bot message
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        const lastMessage = updatedMessages[updatedMessages.length - 1];
        if (lastMessage.isStreaming) delete lastMessage.isStreaming; // Remove streaming flag
        return updatedMessages;
      });
    
      setLoading(false);
    };
    
  
    const handleClick = (section) => {
      navigate(`/${section.toLowerCase()}`);
    };

  
    const formatTextWithBold = (text) => {
      // Remove unnecessary symbols and format numbered headings as bold
      const cleanedText = text.replace(/[#!*-]/g, ""); // Remove #, *, and - symbols
      return cleanedText.replace(
        /(\d+\.\s)(.*?)(:)/g,
        "<strong>$1$2</strong>$3"
      ); // Apply bold formatting to numbered headings
    };

  return (
    <div className=" z-30 flex w-full flex-col items-center">
    {/* Display chat interface on the main chat route, otherwise render Outlet */}
      <div className="w-full max-w-md h-full md:h-5/6 p-4 bg-white shadow-xl rounded-lg relative">
        <h1 className="text-2xl font-bold text-center mb-4">
          AI Assistants
        </h1>

        {/* Chat window with background image */}
        <div
          className="h-96 md:h-[400px] overflow-y-auto mb-4 bg-gray-100 p-4 rounded-lg shadow-inner"
          style={{
            backgroundImage: `url(${ChatImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {messages.map((msg, index) => (
            <div
              key={index}
              className={`mb-2 ${
                msg.sender === "User" ? "text-right" : "text-left"
              }`}
            >
              <span
                className={`inline-block p-2 rounded-lg shadow-md ${
                  msg.sender === "User" ? "bg-purple-600 text-white" : "bg-gray-300 text-black"
                }`}
                dangerouslySetInnerHTML={{ __html: msg.text }} // Render formatted HTML
              ></span>

            </div>
          ))}
          {loading && (
            <div className="text-center text-gray-500">
              Agent is Typing 💬...
            </div>
          )}
        </div>

        {/* Input area */}
        <div className="absolute bottom-4 left-0 right-0 px-4">
          <div className="flex">
            <input
              type="text"
              className="flex-1 p-2 border border-gray-300 rounded-l-lg focus:outline-none"
              placeholder="Type a message..."
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") handleSendMessage();
              }}
              disabled={loading} // Disable input while waiting for bot response
            />
            <button
              onClick={handleSendMessage}
              className="bg-purple-600 text-white py-2 px-4 rounded-r-lg hover:bg-purple-700 transition duration-300"
              disabled={loading} // Disable button while waiting for bot response
            >
              Send
            </button>
          </div>
        </div>
      </div>

  </div>
  );
}
