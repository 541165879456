import React, { useState, useEffect } from 'react'
import { Link, NavLink, useLocation, useParams } from 'react-router-dom'
import aisaXLogo from '../../img/aisaXLogo.png'
import ChatComponent from './ChatComponent';
import { FillFileIcon,OutlineFileTextIcon,OutlineGlobalIcon,OutlineDeleteIcon,BarsIcon,XMarkIcon } from "../../Svg icons/Svg";
import { FetchDataFromLocalStorage } from '../../hooks/FetchDataFromLocalStorage'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import LoadingGif from '../../img/LoadingGif.gif'
import { TopNav } from './TopNav'


export const AgentFileUploadPage = () => {
  const [selectedFiles, setSelectedFiles] = useState([])
  const [documentList, setDocumentList] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false) // State for the loader
  const [collection, setCollection] = useState(null)
  const [documentCount, setDocumentCount] = useState()

  const { uuid } = useParams()
  const chatbotId = uuid
  const apiUrl = process.env.REACT_APP_API_URL
  const clientUrl = process.env.REACT_APP_URL
  const userId = FetchDataFromLocalStorage('userId')
  const uuuid = FetchDataFromLocalStorage('current_uuid')
  const token = FetchDataFromLocalStorage("token");


  const location = useLocation()
  const modelUrl = process.env.REACT_APP_AI_AGENT_URL
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isHeaderOpen, setIsHeaderOpen] = useState(false);

  const handleUpdateCollectionName = async (uniqueId, collectionName) => {
    try {
      const response = await axios.put(`${apiUrl}/update-collection-name`, {
        uniqueId,
        collectionName,
      })

      if (response.data && response.data.updatedAgent) {
        // toast.success("Collection name updated successfully!", {
        //   position: "bottom-right",
        // });
      } else {
        console.warn('Unexpected response format:', response.data)
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || 'Error updating collection name.',
        {
          position: 'bottom-right',
        },
      )
    }
  }

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await fetch(`${apiUrl}/getDocuments/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId, uuid: uuuid }),
        })

        const data = await response.json()

        setDocumentCount(data.data[`AiAgent ${uuuid}`].length)

        if (response.ok) {
          const documents = Array.isArray(data?.data?.[`AiAgent ${uuuid}`])
            ? data.data[`AiAgent ${uuuid}`]
            : []
          setDocumentList(documents)
        } else {
          toast.error(data.message || 'Failed to load documents.', {
            position: 'bottom-right',
          })
        }
      } catch (error) {
        // toast.error("An error occurred while fetching documents.", {
        //   position: "bottom-right",
        // });
      }
    }

    fetchDocuments()
  }, [apiUrl, userId, uuuid])

  console.log(documentCount, 'datadata')

  const handleTraining = async () => {
    setIsLoading(true) // Show loader
    try {
      const response = await axios.post(`${modelUrl}/retrain`, {
        user_id: userId,
        agent_id: uuuid,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      console.log(response, 'response')

      if (response.data && response.data.details.collection_id) {
        toast.success('Training successful!', {
          position: 'bottom-right',
        })
        handleUpdateCollectionName(uuuid, response.data.details.collection_id)
        setCollection(response.data.details.collection_id)
      } else {
        console.warn('Unexpected response format:', response.data)
      }
    } catch (error) {
      toast.error(
        error.response?.data?.detail || 'Error initiating model retraining.',
        {
          position: 'bottom-right',
        },
      )
    } finally {
      setIsLoading(false) // Hide loader
    }
  }

  const handleFileUpload = async event => {
    const maxFileSize = 10 * 1024 * 1024 // Maximum file size: 5MB
    const filesize = Array.from(event.target.files)

    // Filter files based on size
    const validFiles = filesize.filter(file => {
      if (file.size > maxFileSize) {
        // alert(`File ${file.name} exceeds the maximum size of 5MB.`);
        toast.warning(`File ${file.name} exceeds the maximum size of 1MB.`, {
          position: 'top-center',
        })
        return false
      }
      return true
    })
    //
    const files = event.target.files
    if (!files.length) return

    const formData = new FormData()
    formData.append('userId', userId)
    formData.append('uuid', uuuid)
    formData.append('DocumentType', 'File')

    Array.from(files).forEach(file => {
      formData.append('documents', file)
    })

    try {
      const response = await fetch(`${apiUrl}/uploadDocuments`, {
        method: 'POST',
        body: formData,
      })
      const result = await response.json()

      if (result.message == 'File uploaded successfully!') {
        toast.success('File uploaded successfully!', {
          position: 'bottom-right',
        })
        window.location.reload()
      } else {
        toast.error('Error: ' + result.message, {
          position: 'bottom-right',
        })
      }
    } catch (error) {
      // toast.error("File upload failed.", {
      //   position: "bottom-right",
      // });
      window.location.reload()
    }
  }

  const handleDeleteDocument = async document => {
    console.log(document, 'document')
    try {
      const response = await fetch(`${apiUrl}/deleteDocument`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId,
          documentName: document.documentName,
          uuid: uuuid,
        }),
      })

      if (response.ok) {
        setDocumentList(prevList =>
          prevList.filter(doc => doc.documentId !== document.documentId),
        )
        toast.success('Document deleted successfully!', {
          position: 'bottom-right',
        })
      } else {
        const errorData = await response.json()
        toast.error(errorData.message || 'Failed to delete document.', {
          position: 'bottom-right',
        })
      }
    } catch (error) {
      // toast.error("An error occurred while deleting the document.", {
      //   position: "bottom-right",
      // });
    }
  }

  const handleGoogleDriveUpload = () => {
    toast.info('Google Drive upload feature coming soon!', {
      position: 'bottom-right',
    })
  }
  const toggleSidebar = () => {
    setIsHeaderOpen(!isHeaderOpen);
  };
  return (
    <>
     <div className=' min-h-screen bg-gradient-to-r from-blue-500 to-purple-500'> 
      {/* <TopNav/> */}
      <div className={`min-h-screen  transition-colors duration-300`}>
        {/* Top Navbar */}
             <div className="fixed top-0 left-0 right-0 z-50 bg-blue-500 shadow-md py-2 flex items-center">
               {/* Logo */}
               <div className="ml-4">
                 <a href={`${clientUrl}/agents`}>
                   <img
                     src={aisaXLogo}
                     alt="AiSA-X Logo"
                     className="w-36 h-auto cursor-pointer"
                   />
                 </a>
               </div>
       
               {/* Hamburger Icon for Sidebar */}
               <button
                 onClick={toggleSidebar}
                 className="ml-auto mr-4 text-white  text-2xl focus:outline-none lg:hidden"
               >
                 {isHeaderOpen ? <XMarkIcon /> : <BarsIcon />}
               </button>
       
               {/* Navigation Tabs for Larger Screens */}
               <ul className="hidden lg:flex justify-around w-full px-[20%]">
                 <li className="text-white font-bold cursor-pointer">
                   <NavLink
                     to={`/agent/${chatbotId}`}
                     className={({ isActive }) =>
                       isActive ? 'text-yellow-300' : 'text-white'
                     }
                     end
                   >
                     Playground
                   </NavLink>
                 </li>
                 {['Activity', 'Analysis', 'Sources', 'Connect', 'Settings'].map(
                   section => (
                     <li key={section} className="text-white font-bold cursor-pointer">
                       <NavLink
                         to={`/agent/${chatbotId}/${section.toLowerCase()}`}
                         className={({ isActive }) =>
                           isActive ? 'text-yellow-300' : 'text-white'
                         }
                         end
                       >
                         {section}
                       </NavLink>
                     </li>
                   )
                 )}
               </ul>
             </div>
            
             {/* Sidebar for Smaller Screens */}
             {isHeaderOpen && (
               <div className="fixed top-0 right-0 w-64 h-full bg-white shadow-lg z-50 p-4 lg:hidden">
                 <ul>
                  <li className='flex justify-end font-bold'>
                    <p 
                 onClick={toggleSidebar}

                    >
                      X 
                    </p>
                  </li>
                   <li className="text-gray-800 font-bold cursor-pointer mb-4">

                     <NavLink
                       to={`/agent/${chatbotId}`}
                       onClick={toggleSidebar}
                       className={({ isActive }) =>
                         isActive ? 'text-blue-500' : 'text-gray-800'
                       }
                       end
                     >
                       Playground
                     </NavLink>
                   </li>
                   {['Activity', 'Analysis', 'Sources', 'Connect', 'Settings'].map(
                     section => (
                       <li
                         key={section}
                         className="text-gray-800 font-bold cursor-pointer mb-4"
                       >
                         <NavLink
                           to={`/agent/${chatbotId}/${section.toLowerCase()}`}
                           onClick={toggleSidebar}
                           className={({ isActive }) =>
                             isActive ? 'text-blue-500' : 'text-gray-800'
                           }
                           end
                         >
                           {section}
                         </NavLink>
                       </li>
                     )
                   )}
                 </ul>
               </div>
             )}
       
            
             
               {/* Render ChatComponent */}
               <ChatComponent />

        <div className="flex w-full h-full">
      {/* Sidebar */}
      <div
        className={`fixed top-0 left-0 bg-white shadow-lg mt-20  fixed h-full  p-6 z-50 transition-transform duration-300 ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } lg:translate-x-0 lg:w-64`}
      >
        <h1 className="text-2xl font-bold mb-6">Data Sources</h1>
        <ul className="space-y-4">
          <li>
            <NavLink
              to={`/agent/${uuid}/files`}
              className={({ isActive }) =>
                `flex items-center py-2 px-4 rounded-lg transition duration-300 ${
                  isActive ? "bg-[#8acfeb] text-black font-bold" : "hover:bg-[#e6f7fd] text-[#333]"
                }`
              }
            >
              <FillFileIcon className="mr-2 text-[#007BFF]" />
              <span className="font-medium">Files</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/agent/${uuid}/text`}
              className={({ isActive }) =>
                `flex items-center py-2 px-4 rounded-lg transition duration-300 ${
                  isActive ? "bg-[#8acfeb] text-black" : "hover:bg-[#e6f7fd] text-[#333]"
                }`
              }
            >
              <OutlineFileTextIcon className="mr-2 text-[#007BFF]" />
              <span className="font-medium">Text</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to={`/agent/${uuid}/website`}
              className={({ isActive }) =>
                `flex items-center py-2 px-4 rounded-lg transition duration-300 ${
                  isActive ? "bg-[#8acfeb] text-black" : "hover:bg-[#e6f7fd] text-[#333]"
                }`
              }
            >
              <OutlineGlobalIcon className="mr-2 text-[#007BFF]" />
              <span className="font-medium">Website</span>
            </NavLink>
          </li>
        </ul>
        <div className="mt-auto py-10 w-full">
          <button
            onClick={handleTraining}
            className="w-full py-3 bg-blue-500 text-white font-bold rounded-lg hover:bg-blue-600 transition duration-300"
          >
            {isLoading ? "Training AI Agent..." : "Train AI Agent"}
          </button>
        </div>
      </div>
        {/* Hamburger Button for Mobile */} 
      {/* <button
        className="fixed top-16 left-4 z-50 bg-gray-500 text-white  p-2 rounded-full lg:hidden"
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        {isSidebarOpen ? <XMarkIcon className="w-6 h-6" /> : <BarsIcon className="w-6 h-6" />}
      </button> */}
      {/* Main Contentflex w-full shadow-lg fixed h-full flex mt-10 bg-gray-100 */}
      <div className="flex-1 lg:ml-64 p-6 overflow-y-auto w-full min-h-screen p-6 bg-gray-100">
      
      
       
          
       

        {/* File Upload Section */}
        <h1 className="text-3xl font-bold mb-6">Upload Files</h1>
        <p className="text-lg mb-6">Select files to upload from your device or Google Drive</p>
        <div className="border bg-white p-5 rounded-lg mb-4">
          <h2 className="text-2xl font-bold mb-4">Files</h2>
          <p className="mb-4">Drag & drop files here, click to select files, or use Google Drive.</p>
          <div className="flex flex-col md:flex-row gap-4">
            <div
              className={`border-2 border-dashed p-6 rounded-lg relative w-full md:w-1/2 ${
                documentCount > 2 ? "border-gray-300 opacity-50 cursor-not-allowed" : "border-gray-300 cursor-pointer"
              }`}
            >
              <input
                type="file"
                accept=".doc,.docx,.txt,.pdf"
                multiple
                onChange={handleFileUpload}
                className="w-full h-full opacity-0 absolute top-0 left-0"
                disabled={documentCount > 2}
              />
              <p className="text-center">
                {documentCount > 2 ? "Upload disabled: Max files reached" : "Click or drag files here to upload"}
              </p>
             
             <p className="text-center text-sm text-gray-500 mt-2">Supported File Types: .pdf, .doc, .docx, .txt</p>
             <p className="text-center text-sm text-red-500 font-semibold mt-2">
    Please don’t upload any confidential data.
  </p>
            </div>
            <button
              onClick={handleGoogleDriveUpload}
              className="w-full md:w-1/2 py-3 bg-blue-500 text-white font-bold rounded-lg hover:bg-blue-600 transition duration-300"
            >
              Upload from Google Drive
            </button>
          </div>
        </div>

        {/* Display Uploaded Documents */}
        <div className="border bg-white p-5 rounded-lg">
          {documentList.length > 0 ? (
            <div className="mt-6">
              <h3 className="text-xl font-bold mb-4">Uploaded Documents</h3>
              <ul className="space-y-2">
                {documentList.map((doc, index) => (
                  <li
                    key={index}
                    className="flex items-center justify-between group hover:bg-gray-50 px-3 py-2 rounded-lg"
                  >
                    <div className="flex-1">
                      <span className="text-gray-700">{doc.documentName}</span>
                    </div>
                    <button
                      onClick={() => handleDeleteDocument(doc)}
                      className="text-red-500 hover:text-red-700 transition flex items-center"
                    >
                      <OutlineDeleteIcon  />
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <p className="text-gray-500 mt-6">No documents uploaded yet.</p>
          )}
        </div>
         {/* Close Sidebar on Outside Click */}
   {isHeaderOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-25 z-30 lg:hidden"
          onClick={() => setIsSidebarOpen(false)}
        ></div>
      )}
      </div>
    </div>
      </div>
      </div>
    
    </>
  )
}
