import React, { useState, useEffect } from "react";
import { NavLink, useParams } from "react-router-dom";
import {BarsIcon, CodeIcon, CopyIcons, LinkIcon, ShareIcon, XMarkIcon, }from "../../Svg icons/Svg"
import { FetchDataFromLocalStorage } from "../../hooks/FetchDataFromLocalStorage";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Connect = () => {
  const [activeSection, setActiveSection] = useState("Embed");
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Sidebar toggle state
  const { chatbotId } = useParams();
  const uuid = FetchDataFromLocalStorage("current_uuid");
  const userId = FetchDataFromLocalStorage("userId");

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);

  const menuItems = [
    { name: "Embed", icon: <LinkIcon className="w-5 h-5 text-[#007BFF]" />, section: "Embed" },
    { name: "Share", icon: <ShareIcon className="w-5 h-5 text-[#007BFF]" />, section: "Share" },
    { name: "Integrations", icon: <CodeIcon  />, section: "Integrations" },
  ];

  // Reset scroll position on render or when activeSection changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeSection]);

  return (
    <div className="flex w-full h-full">
      {/* Hamburger Button for Mobile */}
      {/* <button
        className="fixed top-16 left-4 z-50 bg-gray-500 text-white  p-2 rounded-full lg:hidden"
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
      >
        {isSidebarOpen ? <XMarkIcon className="w-6 h-6" /> : <BarsIcon className="w-6 h-6" />}
      </button> */}

      {/* Sidebar */}
      <div
        className={`mt-20 fixed inset-y-0 left-0 bg-white p-6 shadow-lg transition-transform duration-300 z-40 lg:w-64 ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } lg:translate-x-0`}
      >
        <h1 className="text-2xl font-bold mb-6">Connect</h1>
        <ul className="space-y-4">
          {menuItems.map((item) => (
            <li key={item.name}>
              <button
                onClick={() => {
                  setActiveSection(item.section);
                  setIsSidebarOpen(false); // Close sidebar on selection
                }}
                className="flex items-center py-2 px-4 rounded-lg hover:bg-[#e6f7fd] transition duration-300 w-full text-left"
              >
                {item.icon}
                <span className="ml-2">{item.name}</span>
              </button>
            </li>
          ))}
        </ul>
      </div>

      {/* Main Content */}
      <main className="flex-1 lg:ml-64 p-6 overflow-y-auto min-h-screen bg-[#f9f9f9]">
        <h1 className="text-3xl font-bold mb-6 text-gray-800">Agent Integration</h1>

        {/* Conditional Rendering Based on Active Section */}
        {activeSection === "Embed" && (
          <section className="mb-10">
            <h2 className="text-2xl font-semibold mb-4">Embed the AI Agent</h2>
            <p className="mb-4 text-gray-600">
              To add the AI Agent anywhere on your website, add this iframe:
            </p>
            <div className="flex items-center space-x-4 block p-4 bg-gray-100 rounded-lg text-sm shadow">
            <code >
              {`<iframe src="${url.origin}/agentlink/${chatbotId}?user=${userId}&agent=${uuid}" width="100%" style="height: 100%; min-height: 700px" frameborder="0"></iframe>`}
            </code>
            
            <button
  onClick={() => {
    navigator.clipboard.writeText(
      `<iframe src="${url.origin}/agentlink/${chatbotId}?user=${userId}&agent=${uuid}" width="100%" style="height: 100%; min-height: 700px" frameborder="0"></iframe>`
    );
    toast.success("iFrame code copied to clipboard!", {
      position: "bottom-right",
      autoClose: 300,
    });
  }}
  className="flex items-center py-2 px-4 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 transition focus:outline-none space-x-2"
>
  {/* Copy Icon */}
  <CopyIcons className="w-5 h-5" />
  {/* Button Text */}
  <span>Copy</span>
</button></div>

      
{/* Chat Bubble Script Section */}
<h2 className="text-2xl font-semibold mt-8 mb-4">Add Chat Bubble</h2>
<p className="mb-4 text-gray-600">
  To add a chat bubble to the bottom right of your website, add this script tag to your HTML:
</p>
<div className="flex items-center space-x-4 block p-4 bg-gray-100 rounded-lg text-sm shadow">
  <code >
    {`<script>
  window.embeddedChatbotConfig = {
    chatbotId: "6vuASiNrQ_VnlWiCTmU-g",
    domain: "www.Aisa-x.co"
  }
</script>
<script
  src="${url.origin}/aiagent.min.js"
  chatbotId="6vuASiNrQ_VnlWiCTmU-g"
  domain="www.Aisa-x.ai"
  defer>
</script>`}
  </code>
  <button
  onClick={() => {
    navigator.clipboard.writeText(
      `<script>
  window.embeddedChatbotConfig = {
    chatbotId: "6vuASiNrQ_VnlWiCTmU-g",
    domain: "www.Aisa-x.co"
  }
</script>
<script
  src="${url.origin}/aiagent.min.js"
  chatbotId="6vuASiNrQ_VnlWiCTmU-g"
  domain="www.Aisa-x.ai"
  defer>
</script>`
    );
    toast.success("Chat Bubble Script copied to clipboard!", {
      position: "bottom-right",
      autoClose: 300,
    });
  }}
  className="flex items-center py-2 px-4 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 transition focus:outline-none space-x-2"
>
  {/* Copy Icon */}
  <CopyIcons className="w-5 h-5" />
  <span>Copy</span>
</button>
</div>
   </section>
        )}

        {activeSection === "Share" && (
          <section className="mb-10">
            <h2 className="text-2xl font-semibold mb-4">Share the AI Agent</h2>
            <p className="text-gray-600">Use this link to share the AI Agent with others:</p>
          <div className="flex items-center space-x-4 block p-4 bg-gray-100 rounded-lg text-sm shadow">
            <code className="flex-1 truncate">
              {`https://www.aisa-x.co/chatbot/6vuASiNrQ_VnlWiCTmU-g`}
            </code>
            <button
  onClick={() => {
    navigator.clipboard.writeText(
      "https://www.aisa-x.co/chatbot/6vuASiNrQ_VnlWiCTmU-g"
    );
    toast.success("Share link copied to clipboard!", {
      position: "bottom-right",
      autoClose: 300,
    });
    
  }}
  className="py-2 px-4 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 transition focus:outline-none flex items-center space-x-2"
>
  {/* Copy Icon */}
  <CopyIcons className="w-5 h-5" />
  <span>Copy</span>
</button>

</div>
          </section>
        )}

        {activeSection === "Integrations" && (
          <section className="mb-10">
            <h2 className="text-2xl font-semibold mb-4">Integrations</h2>
            <p className="text-gray-600">Explore various integrations to add more functionality to your AI Agent.</p>
          </section>
        )}
        
   {/* Close Sidebar on Outside Click */}
   {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-25 z-30 lg:hidden"
          onClick={() => setIsSidebarOpen(false)}
        ></div>
      )}

      </main>
       <ToastContainer position="bottom-right" />
    </div>
  );
};
