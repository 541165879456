import React from "react";
import "./FileUploadCard.css";

const FileUploadCard = () => {
  return (
    <div className="file-upload-card">
      <h3 className="file-title">Files</h3>
      <div className="file-drop-area">
        <span className="file-upload-icon">⬆️</span>
        <p>Drag & drop files here, or click to select files</p>
        <p className="file-types">Supported File Types: .pdf, .doc, .docx, .txt</p>
      </div>
      <p className="file-note">
        If you are uploading a PDF, make sure you can select/highlight the text.
      </p>
    </div>
  );
};

export default FileUploadCard;
