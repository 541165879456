import React, { useState } from "react";
import "./Sidebar.css";
import { useLocation, useNavigate } from "react-router-dom";

const Sidebar = ({ onToggle }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const sideTab = params.get("sideTab") || "files";
  const [isOpen, setIsOpen] = useState(true);

  const handleSideClick = (tab) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("sideTab", tab);
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    onToggle(!isOpen); // Notify parent component about sidebar toggle
  };

  return (
    <div
      className={`sidebar ${isOpen ? "open" : "closed"}`}
      style={{
        transition: "transform 0.3s ease-in-out",
        transform: isOpen ? "translateX(0)" : "translateX(-100%)",
      }}
    >
      <button className="sidebar-toggle" onClick={toggleSidebar}>
        {isOpen ? "←" : "→"}
      </button>
      <h2 className="sidebar-title">Sources</h2>
      <ul className="sidebar-list">
        <li
          className={`sidebar-item ${sideTab === "files" ? "active" : ""}`}
          onClick={() => handleSideClick("files")}
        >
          <span className="sidebar-icon">📄</span> Files
        </li>
        <li
          className={`sidebar-item ${sideTab === "Text" ? "active" : ""}`}
          onClick={() => handleSideClick("Text")}
        >
          <span className="sidebar-icon">📝</span> Text
        </li>
        <li
          className={`sidebar-item ${sideTab === "Website" ? "active" : ""}`}
          onClick={() => handleSideClick("Website")}
        >
          <span className="sidebar-icon">🌐</span> Website
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
