import React, { useEffect, useState } from "react";
import axios from "axios";
import SideBar from "./SideBar"; // Import Sidebar
import {
  ArrowLeftIcon,
  ArrowrightIcon,
  EmailIcons,
  LinkIcon,
  UserIcon,
} from "../../Svg icons/Svg";
import { ToastContainer, toast } from "react-toastify"; // Importing ToastContainer and toast
import "react-toastify/dist/ReactToastify.css"; // Import toast CSS
import { FetchDataFromLocalStorage } from "../../hooks/FetchDataFromLocalStorage";

const Table = () => {
  const [chatbots, setChatbots] = useState([]); // Store chatbots data
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // Define items per page
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // State for sidebar toggle

  const apiUrl = process.env.REACT_APP_API_URL;
  const userId = FetchDataFromLocalStorage("userId");
  const token = FetchDataFromLocalStorage("token");

  useEffect(() => {
    const fetchChatbots = async () => {
      try {
        const response = await axios.post(
          `${apiUrl}/getALLChatBot`,
          { userId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setChatbots(response.data || []);
      } catch (error) {
        console.error("Error fetching chatbots:", error);
      }
    };

    fetchChatbots();
  }, [userId, apiUrl]);

  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
  };

  const handleBlockUser = async (userId, isBlocked) => {
    try {
      const endpoint = "blockUser";
      const response = await axios.post(
        `${apiUrl}/${endpoint}`,
        { userId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.success) {
        setChatbots((prevChatbots) =>
          prevChatbots.map((bot) =>
            bot.userId._id === userId
              ? { ...bot, userId: { ...bot.userId, blocked: !isBlocked } }
              : bot
          )
        );
        toast.success(
          isBlocked
            ? "User unblocked successfully!"
            : "User blocked successfully!",
          {
            position: "bottom-right",
            autoClose: 3000,
          }
        );
      } else {
        toast.error("Failed to update block status.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      const response = await axios.post(
        `${apiUrl}/deleteusers`,
        { userId, delete: true },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.success) {
        setChatbots((prevChatbots) =>
          prevChatbots.filter((bot) => bot.userId._id !== userId)
        );
        toast.success("User deleted successfully!", {
          position: "bottom-right",
          autoClose: 3000,
        });
      } else {
        toast.error("Failed to delete user.", {
          position: "bottom-right",
          autoClose: 3000,
        });
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
    }
  };

  const handleDeleteConfirmation = (userId) => {
    const toastId = toast.info(
      <div>
        <p>Are you sure you want to delete this user?</p>
        <div className="flex justify-end mt-2">
          <button
            className="px-4 py-2 mr-2 text-white bg-red-500 rounded-md hover:bg-red-600"
            onClick={() => {
              toast.dismiss(toastId);
              handleDeleteUser(userId);
            }}
          >
            Yes
          </button>
          <button
            className="px-4 py-2 text-white bg-gray-500 rounded-md hover:bg-gray-600"
            onClick={() => toast.dismiss(toastId)}
          >
            Cancel
          </button>
        </div>
      </div>,
      {
        autoClose: false,
        closeButton: false,
      }
    );
  };

  const filteredData = chatbots.filter((bot) => {
    const { username, email } = bot.userId;
    const { name, url } = bot;
    return (
      username?.toLowerCase().includes(search.toLowerCase()) ||
      email?.toLowerCase().includes(search.toLowerCase()) ||
      name?.toLowerCase().includes(search.toLowerCase()) ||
      url?.toLowerCase().includes(search.toLowerCase())
    );
  });

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, startIndex + itemsPerPage);

  const handleNext = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevious = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  return (
    <>
      <SideBar onToggle={handleSidebarToggle} />
      <div
        className="p-5 bg-cover bg-center transition-all duration-300"
        style={{ marginLeft: isSidebarOpen ? "250px" : "0" }}
      >
        <h1 className="text-3xl font-bold text-white mb-6 text-center">
          AI Agents Table
        </h1>

        <div className="mb-4 flex justify-between items-center">
          <input
            type="text"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Search by username, email, name, or URL..."
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring focus:border-blue-300 mr-4"
          />
        </div>

        <div className="overflow-x-auto shadow-xl rounded-lg bg-white">
          <table className="table-auto w-full border-collapse border border-gray-200">
            <thead className="bg-gray-800 text-white text-sm md:text-base">
              <tr>
                <th className="border border-gray-300 px-4 py-2">No</th>
                <th className="border border-gray-300 px-4 py-2">Username</th>
                <th className="border border-gray-300 px-4 py-2">Email</th>
                <th className="border border-gray-300 px-4 py-2">Name</th>
                <th className="border border-gray-300 px-4 py-2">URL</th>
                <th className="border border-gray-300 px-4 py-2">Action</th>
                <th className="border border-gray-300 px-4 py-2">Delete</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.length > 0 ? (
                paginatedData.map((bot, index) => (
                  <tr key={bot._id} className="text-center hover:bg-gray-100">
                    <td className="border border-gray-300 px-4 py-2">
                      {startIndex + index + 1}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {bot.userId.username}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {bot.userId.email}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      {bot.name}
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      <a
                        href={bot.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        {bot.url}
                      </a>
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      <button
                        onClick={() =>
                          handleBlockUser(bot.userId._id, bot.userId.blocked)
                        }
                        className={`px-4 py-2 rounded-md text-white ${
                          bot.userId.blocked
                            ? "bg-green-500 hover:bg-green-600"
                            : "bg-red-500 hover:bg-red-600"
                        }`}
                      >
                        {bot.userId.blocked ? "Unblock" : "Block"}
                      </button>
                    </td>
                    <td className="border border-gray-300 px-4 py-2">
                      <button
                        onClick={() => handleDeleteConfirmation(bot.userId._id)}
                        className="px-4 py-2 rounded-md bg-gray-500 text-white hover:bg-gray-600"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="7"
                    className="text-center text-gray-500 border border-gray-300 px-4 py-4"
                  >
                    No matching records found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="flex justify-between items-center mt-4">
          <button
            onClick={handlePrevious}
            disabled={currentPage === 1}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:opacity-50 flex items-center"
          >
            <ArrowLeftIcon />
          </button>
          <span className="text-white">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={handleNext}
            disabled={currentPage === totalPages}
            className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 disabled:opacity-50 flex items-center"
          >
            <ArrowrightIcon />
          </button>
        </div>
        <ToastContainer position="bottom-right" />
      </div>
    </>
  );
};

export default Table;
