import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import RazorpayImg from "../../img/Razorpay.png";
import CardImg from "../../img/card.jpg";

import paypalImg from "../../img/paypal.png";
import { FetchDataFromLocalStorage } from "../../hooks/FetchDataFromLocalStorage";
import Sidebar from "./SideBar";

const PaymentPage = () => {
  const [selectedPayment, setSelectedPayment] = useState("");
  const [userTier, setUserTier] = useState("basic");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)

  const [showModal, setShowModal] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;
  const userId = FetchDataFromLocalStorage("userId");

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const paymentType = params.get("paymentType");
    if (paymentType) {
      setUserTier(paymentType);
    }
  }, [location.search]);

  const initializePayPalButton = () => {
    if (window.paypal) {
      window.paypal
        .Buttons({
          style: {
            shape: "rect",
            color: "gold",
            layout: "vertical",
            // label: "subscribe",
          },
          createSubscription: (data, actions) => {
            return actions.subscription.create({
              plan_id: "P-9AV65756RK9798306M6HVVFI", // Replace with your PayPal subscription plan ID
            });
          },
          onApprove: async (data, actions) => {
            try {
              const res = await fetch(`${apiUrl}/pay/paypal`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  subscriptionID: data.subscriptionID,
                  userId,
                  userTier,
                }),
              });
  
              const result = await res.json();
              if (result.success) {
                toast.success(
                  `Subscription successful for ${userTier} plan!`,
                  { position: "bottom-right" }
                );
                setShowModal(false); // Close the modal on success
              } else {
                toast.error(
                  result.message || "Subscription verification failed.",
                  { position: "bottom-right" } 
                );
              }
            } catch (error) {
              console.error("Error verifying subscription:", error);
              toast.error("Error verifying subscription. Please try again.", {
                position: "bottom-right",
              });
            }
          },
            onCancel: (data, actions) => {
              
              console.error("PayPal Button Cancelled:", data, actions);
              toast.error("Payment cancelled. Please try again.", {
                position: "bottom-right",
              });
            },
          onError: (err) => {
            console.error("PayPal Button Error:", err);
            toast.error("An error occurred with PayPal. Please try again.", {
              position: "bottom-right",
            });
          },
        })
        .render("#paypal-button-container");
    } else {
      toast.error("PayPal SDK not loaded. Please try again later.", {
        position: "bottom-right",
      });
    }
  };
  

  const handlePaymentSelection = (method) => {
    setSelectedPayment((prevMethod) => (prevMethod === method ? "" : method));
  };

  const handleSidebarToggle = isOpen => {
    setIsSidebarOpen(isOpen)
  }

  const handleProceedPayment = async () => {
    if (selectedPayment === "PayPal") {
      setShowModal(true); // Open modal for PayPal
      const script = document.createElement("script");
      script.src = "https://www.paypal.com/sdk/js?client-id=Acapfp5yDyjmZOQ5zG7NOJHIU5N6fpD1OjLgCCZlbef35waeSMbqeohMwL88pCBTsYopVz56c9t6BRHp&vault=true&intent=subscription";
      script.setAttribute("data-sdk-integration-source", "button-factory");
      script.async = true;
      script.onload = initializePayPalButton;
      document.body.appendChild(script);
    } else if (selectedPayment === "Credit or Debit") {
      try {
        const orderId = `ORDER_${new Date().getTime()}`;
        const options = {
          key: "rzp_test_h6Yt9eVJuogzxn", // Replace with your Razorpay Test Key
          amount: 10000, // Amount in paise
          currency: "INR",
          name: "Your Company Name",
          description: `Transaction for ${userTier} plan`,
          handler: async (response) => {
            try {
              const res = await fetch(`${apiUrl}/pay/razor`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  razorpay_payment_id: response.razorpay_payment_id,
                  merchant_order_id: orderId,
                  userId,
                  userTier,
                }),
              });

              const data = await res.json();
              if (data.success) {
                toast.success(
                  `Payment successful for ${userTier} plan and subscription updated!`,
                  { position: "bottom-right" }
                );
              } else {
                toast.error(data.message || "Payment verification failed.", {
                  position: "bottom-right",
                });
              }
            } catch (err) {
              console.error("Error verifying payment:", err);
              toast.error("Error verifying payment. Please try again.", {
                position: "bottom-right",
              });
            }
          },
          prefill: {
            name: "Customer Name",
            email: "customer@example.com",
            contact: "9876543210",
          },
          theme: {
            color: "#3399cc",
          },
        };

        const rzp = new window.Razorpay(options);
        rzp.open();
      } catch (error) {
        console.error("Error initializing Razorpay:", error);
        toast.error("Failed to initialize payment. Please try again.", {
          position: "bottom-right",
        });
      }
    } else {
      toast.error("Please select a payment method.", {
        position: "bottom-right",
      });
    }
  };

  const paymentOptions = [
    { name: "Credit or Debit", img: CardImg },
    { name: "PayPal", img: paypalImg },
  ];

  const userTiers = [ 'Ignite', 'Elite', 'Stellar'];

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gradient-to-r from-blue-600 to-purple-700 p-4">
      <Sidebar onToggle={handleSidebarToggle} />

      <div className="bg-white rounded-2xl shadow-2xl p-8 w-full max-w-xl"
              style={{
                marginLeft: isSidebarOpen ? '250px' : '0',
                transition: 'margin 0.3s ease-in-out',
              }}>
        <h1 className="text-3xl font-bold text-center text-gray-800 mb-6">
          Select Your Payment Method
        </h1>

        {/* User Tier Selection */}
        <div className="mb-6">
          <h2 className="text-lg font-semibold text-gray-700 mb-4">
            Choose Your Plan
          </h2>
          <select
            value={userTier}
            onChange={(e) => setUserTier(e.target.value)}
            className="w-full p-3 border rounded-lg shadow-sm text-gray-700"
          >
            {userTiers.map((tier) => (
              <option key={tier} value={tier}>
                {tier.charAt(0).toUpperCase() + tier.slice(1)} Plan
              </option>
            ))}
          </select>
        </div>

        {/* Payment Method Selection */}
        <div className="grid grid-cols-2 gap-6">
          {paymentOptions.map((option, index) => (
            <div
              key={index}
              className={`relative border rounded-xl p-5 cursor-pointer flex flex-col items-center shadow-md transition-transform duration-300 hover:shadow-lg ${
                selectedPayment === option.name
                  ? "border-blue-500 bg-blue-50 scale-105"
                  : "border-gray-200 hover:scale-105 hover:border-blue-400"
              }`}
              onClick={() => handlePaymentSelection(option.name)}
            >
              {selectedPayment === option.name && (
                <div className="absolute top-2 right-2 bg-blue-500 text-white w-6 h-6 flex items-center justify-center rounded-full shadow-md">
                  ✓
                </div>
              )}
              <img
                src={option.img}
                alt={option.name}
                className="w-26 h-20 mb-4"
              />
              <span className="text-gray-800 font-semibold text-lg">
                {option.name}
              </span>
            </div>
          ))}
        </div>

        <button
          onClick={handleProceedPayment}
          className="w-full mt-8 py-3 bg-blue-500 text-white font-bold rounded-lg hover:bg-blue-600 shadow-lg transform transition duration-300"
        >
          Confirm Payment
        </button>
      </div>

      {/* PayPal Modal */}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <h2 className="text-xl font-bold mb-4">Pay with PayPal</h2>
            <div id="paypal-button-container"></div>
            <button
              onClick={() => setShowModal(false)}
              className="mt-4 w-full bg-red-500 text-white py-2 rounded-lg hover:bg-red-600"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default PaymentPage;
