import React, { useEffect, useState } from 'react';
import { useParams, NavLink, useLocation } from 'react-router-dom';
import ChatComponent from './ChatComponent';
import EmbedChatComponent from './EmbedChatComponent';
import Sidebar from './SideBar';
import BreadCrumb from './BreadCrumb';
import Header from '../Agents/Header';
import { FetchDataFromLocalStorage } from '../../hooks/FetchDataFromLocalStorage';
import { SourcePage } from './SourcePage';
import { Usage } from './Usage';
import { AgentUsage } from './AgentUsage';

export const Chat = () => {
  const { chatbotId } = useParams();
  const location = useLocation();
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const uuid = FetchDataFromLocalStorage('current_uuid');
  const userId = FetchDataFromLocalStorage('userId');

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('playground'); // Default tab

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabFromUrl = queryParams.get('tabs');
    setActiveTab(tabFromUrl || 'playground'); // Default to playground if no tab is provided
  }, [location.search]);
  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.matchMedia("(max-width: 768px)").matches;
      setIsSidebarOpen(!isMobile); // Close sidebar on mobile, open on larger screens
    };

    // Initialize state based on the initial screen size
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    window.history.replaceState(null, '', `?tabs=${tabName}`);
  };

  return (
    <>
      <Sidebar onToggle={handleSidebarToggle} />
      <div
        className="min-h-screen  "
        style={{
          marginLeft: isSidebarOpen ? '250px' : '0',
          transition: 'margin 0.3s ease-in-out',
        }}
      >
        {/* Breadcrumb and Header */}
        {/* <BreadCrumb main="Dashboard" sub1="Agents" sub2={activeTab} /> */}

        <Header
        
          activeTab={activeTab}
          handleTabClick={handleTabClick}
        />


        {/* Main Content */}
        <div className="flex flex-col items-center justify-center bg-red-600  " style={{height:'100%',width:"100%", backgroundColor: "rgb(243, 244, 246)"}}>
        

          {activeTab === 'playground' && (
            <div className="w-full max-w-4xl">
              <ChatComponent />
            </div>
          )}
          {activeTab === 'connect' && (
            <div className="w-full ">
              <EmbedChatComponent
                url={url}
                chatbotId={chatbotId}
                userId={userId}
                uuid={uuid}
              />
            </div>
          )}

            {activeTab === 'sources' && (
            <div className="w-full  " style={{height:'87vh'}}>
                <SourcePage/>
            </div>
          )}
                      {activeTab === 'usage' && (
            <div className="w-full ">
                <AgentUsage/>
            </div>
          )}
          {activeTab === 'other' && (
            <div className="w-full max-w-4xl bg-white shadow-md rounded-lg p-6 text-center">
              <h2 className="text-2xl font-semibold">Other Content</h2>
              <p className="text-gray-600 mt-4">
                This is a placeholder for other tab content.
              </p>
            </div>
          )}
        </div>

        {/* Close Sidebar on Outside Click */}
        {isSidebarOpen && (
          <div
            className="fixed inset-0 bg-black bg-opacity-25 z-30 lg:hidden"
            onClick={() => setIsSidebarOpen(false)}
          ></div>
        )}
      </div>
    </>
  );
};

