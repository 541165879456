import React, { useState } from 'react';

const Card = ({ icon, value, label }) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      className={`transition-transform transform rounded-lg shadow-lg p-3 bg-white 
        ${hovered ? 'shadow-[0_0_7px_rgba(102,0,255,0.5)]' : 'shadow-lg'} 
        hover:shadow-[0_0_7px_rgba(102,0,255,0.5)] hover:scale-105`}
      style={{ minWidth: '250px' }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className="text-lg text-purple-600 mb-1">{icon}</div>
      <h2 className="text-lg font-bold">{value}</h2>
      <p className="text-gray-500">{label}</p>
    </div>
  );
};

export default Card;