import { useState } from "react";
import { toast } from "react-toastify";
import CryptoJS from "crypto-js";
import {BarsIcon, CodeIcon, CopyIcons, LinkIcon, ShareIcon, XMarkIcon, }from "../../Svg icons/Svg"
// Assuming CopyIcons is a reusable component

export default function EmbedChatComponent({ url, chatbotId, userId, uuid }) {
  const [activeTab, setActiveTab] = useState("embed");
  const [copiedCode, setCopiedCode] = useState(null);
  const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY || "default_key";

  const encryptedChatbotId = CryptoJS.AES.encrypt(chatbotId, encryptionKey).toString();
  const objectToEncrypt = {
  chatbotId: chatbotId,
  user: userId,
  agent: uuid,
};

const encryptedObject = CryptoJS.AES.encrypt(
  JSON.stringify(objectToEncrypt),
  encryptionKey
).toString();



  // const iframeCode = `<iframe src="${url.origin}/agentlink/${encryptedChatbotId}?user=${encryptedUserId}&agent=${encryptedUuid}" width="100%" style="height: 100%; min-height: 700px" frameborder="0"></iframe>`;
  const iframeCode = `<iframe src="${url.origin}/agentlink/${encryptedObject}" width="100%" style="height: 100%; min-height: 700px" frameborder="0"></iframe>`;
  
  const chatBubbleScript = `
<script 
  src="${url.origin}/aiagent.min.js" 
  defer 
></script>

<script>
  window.embeddedChatbotConfig = {
    iframeSrc: "${url.origin}/agentlink/${encryptedObject}" 
  };
</script>`;

const handleCopy = (code) => {
  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard.writeText(code).then(() => {
      setCopiedCode(code); // Update copied status for the specific code
      setTimeout(() => setCopiedCode(null), 2000);
    });
  } else {
    setCopiedCode(code); 
    // Reset "Copied" message after 2 seconds
    setTimeout(() => setCopiedCode(null), 2000);
  }
};

  return (
<div className="bg-white shadow-md rounded-lg p-4 sm:p-6 lg:p-8">
  {/* Tab Navigation */}
  <div className="flex flex-wrap justify-start sm:justify-between space-x-4 border-b border-gray-300 mb-6">
    <button
      className={`py-2 px-4 ${
        activeTab === "embed"
          ? "border-b-2 border-blue-600 text-blue-600 font-semibold"
          : "text-gray-600 hover:text-blue-600"
      }`}
      onClick={() => setActiveTab("embed")}
    >
      Embed AI Agent
    </button>
    <button
      className={`py-2 px-4 ${
        activeTab === "bubble"
          ? "border-b-2 border-blue-600 text-blue-600 font-semibold"
          : "text-gray-600 hover:text-blue-600"
      }`}
      onClick={() => setActiveTab("bubble")}
    >
      Add Chat Bubble
    </button>
    <button
      className={`py-2 px-4 ${
        activeTab === "other1"
          ? "border-b-2 border-blue-600 text-blue-600 font-semibold"
          : "text-gray-600 hover:text-blue-600"
      }`}
      onClick={() => setActiveTab("other1")}
    >
      other1 other other
    </button>
    {/* <button
      className={`py-2 px-4 ${
        activeTab === "other"
          ? "border-b-2 border-blue-600 text-blue-600 font-semibold"
          : "text-gray-600 hover:text-blue-600"
      }`}
      onClick={() => setActiveTab("other")}
    >
      Other Tab
    </button> */}
  </div>

  {/* Tab Content */}
  {activeTab === "embed" && (
    <div>
      <h2 className="text-xl font-semibold mb-4">Embed the AI Agent</h2>
      <p className="mb-4 text-gray-600">
        To add the AI Agent anywhere on your website, add this iframe:
      </p>
      <div className="flex flex-wrap items-start space-x-4 p-4 bg-gray-100 rounded-lg text-sm shadow overflow-auto">
        <code className="break-all text-gray-800">{iframeCode}</code>
        <div className="flex justify-center w-full">
        <button
          onClick={() => {
            handleCopy(iframeCode)
            navigator.clipboard.writeText(iframeCode);
            toast.success("iFrame code copied to clipboard!", {
              position: "bottom-right",
              autoClose: 300,
            });
          }}
          className="flex items-center py-2 mt-2 px-4 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 transition focus:outline-none space-x-2"
        >
          <CopyIcons className="w-5 h-5" />
          {copiedCode === iframeCode ? (
          <span className="text-black font-medium ml-2">Copied!</span>
          ) : (
          <span className="text-white font-medium ml-2">Copy</span>
          )}
        </button>
        </div>
      </div>
    </div>
  )}

  {activeTab === "bubble" && (
    <div>
      <h2 className="text-xl font-semibold mb-4">Add Chat Bubble</h2>
      <p className="mb-4 text-gray-600">
        To add a chat bubble to the bottom right of your website, add this
        script tag to your HTML:
      </p>
      <div className="flex flex-wrap items-start space-x-4 p-4 bg-gray-100 rounded-lg text-sm shadow overflow-auto">
        <code className="break-all text-gray-800">{chatBubbleScript}</code>
        <div className="flex justify-center w-full">
        <button
          onClick={() => {
            handleCopy(chatBubbleScript)
            navigator.clipboard.writeText(chatBubbleScript);
            toast.success("Chat Bubble Script copied to clipboard!", {
              position: "bottom-right",
              autoClose: 300,
            });
          }}
          className="flex items-center py-2 px-4 mt-2 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 transition focus:outline-none space-x-2"
        >
          <CopyIcons className="w-5 h-5" />
          {copiedCode === chatBubbleScript ? (
          <span className="text-black font-medium ml-2">Copied!</span>
          ) : (
          <span className="text-white font-medium ml-2">Copy</span>
          )}
        </button>
      </div>
      </div>
    </div>
  )}
    {activeTab === "other1" && (
    <div>
      <h2 className="text-xl font-semibold mb-4">Add Chat Bubble</h2>
      <p className="mb-4 text-gray-600">
        To add a chat bubble to the bottom right of your website, add this
        script tag to your HTML:
      </p>
      <div className="flex flex-wrap items-start space-x-4 p-4 bg-gray-100 rounded-lg text-sm shadow overflow-auto">
        <code className="break-all text-gray-800">{chatBubbleScript}</code>
         <div className="flex justify-center w-full">
        <button
          onClick={() => {
            handleCopy(chatBubbleScript)
            navigator.clipboard.writeText(chatBubbleScript);
            toast.success("Chat Bubble Script copied to clipboard!", {
              position: "bottom-right",
              autoClose: 300,
            });
          }}
          className="flex items-center py-2 px-4 mt-2 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 transition focus:outline-none space-x-2"
        >
          <CopyIcons className="w-5 h-5" />
          {copiedCode === chatBubbleScript ? (
          <span className="text-black font-medium ml-2">Copied!</span>
          ) : (
          <span className="text-white font-medium ml-2">Copy</span>
          )}
        </button></div>
      </div>
    </div>
    
  )}

  {activeTab === "other" && (
    <div>
      <h2 className="text-xl font-semibold mb-4">Other Tab Content</h2>
      <p className="mb-4 text-gray-600">
        You can add additional content here for this tab.
      </p>
      <div className="p-4 bg-gray-100 rounded-lg text-sm shadow">
        <p>This is a placeholder for other content.</p>
      </div>
    </div>
  )}
</div>

  );
}
