export function FetchDataFromLocalStorage(key) {
  try {
    const storedData = localStorage.getItem(key);
    
    if (!storedData) {
      console.warn(`No data found for ${key}`);
      return null;
    }

    // For token, verify it's not expired if it's a JWT
    if (key === 'token') {
      try {
        const payload = JSON.parse(atob(storedData.split('.')[1]));
        if (payload.exp && Date.now() >= payload.exp * 1000) {
          console.warn('Token is expired');
          localStorage.removeItem('token');
          return null;
        }
      } catch (e) {
        console.warn('Invalid token format');
        return null;
      }
    }

    return storedData;
  } catch (error) {
    console.error(`Error fetching ${key} from localStorage:`, error);
    return null;
  }
}
