import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BlockedIcon } from "../../Svg icons/Svg";

const Blocked = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to contact or support page after 10 seconds
    const timer = setTimeout(() => {
      navigate("/login"); // Adjust to your support/contact page route
    }, 10000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-purple-600 via-blue-500 to-blue-500 p-6">
      <div className="bg-white p-8 shadow-2xl rounded-xl max-w-md w-full text-center transform transition duration-500 ease-in-out hover:scale-105">
        <div className="mb-6">
          <div className="flex justify-center items-center mb-4">
              <BlockedIcon/>
          </div>
          <h1 className="text-4xl font-extrabold text-red-700 mb-2">
            Blocked
          </h1>
          <p className="text-gray-700 text-lg mb-6">
            Your account has been blocked. Please contact admin for further assistance.
          </p>
        </div>

        <div className="text-left bg-red-50 p-4 rounded-lg border border-red-200">
          <p className="text-gray-800 font-medium">For support, reach out to:</p>
          <p>
            <a
              href="mailto:aisa-x.ai@gmail.com"
              className="text-blue-600 underline font-semibold">
                aisa-x.ai@gmail.com
            </a>
          </p>
          <p className="mt-2 text-sm text-gray-600">
            You will be redirected to the support page in 10 seconds.
          </p>
        </div>
        <button
          onClick={() => navigate("/login")}
          className="mt-6 px-5 py-2 bg-red-500 text-white rounded-lg shadow-md hover:bg-red-700 transition"
        >
          Go to Login Now
        </button>

      </div>
    </div>
  );
};

export default Blocked;
