import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios'
import useDrivePicker from 'react-google-drive-picker'
import LoadingGif from '../../img/LoadingGif.gif'
import {
  FillFileIcon,
  OutlineFileTextIcon,
  OutlineGlobalIcon,
  OutlineDeleteIcon,
} from '../../Svg icons/Svg'

import { FetchDataFromLocalStorage } from '../../hooks/FetchDataFromLocalStorage'
import { useNavigate, useParams } from 'react-router-dom'
import Sidebar from './SideBar'
import { checkForMaliciousContent } from './Utils/checkFiles'



export const SourcePage = () => {
  const [activeTab, setActiveTab] = useState('file')
  const [websiteUrl, setWebsiteUrl] = useState('')
  const [siteUrl, setSiteUrl] = useState('')

  const [text, setText] = useState('')
  const [documentType, setDocumentType] = useState('File')
  const [collection, setCollection] = useState(null)
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)
  const [initialSidebarOpen, setInitialSidebarOpen] = useState(false)
  const [websiteLinks, setWebsiteLinks] = useState([])
  const sourcesTabs = ['file', 'text', 'website']
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [isTraining, setIsTraining] = useState(false)
  const [documentList, setDocumentList] = useState([])
  const [refreshTrigger, setRefreshTrigger] = useState(false)
  const [documentName, setDocumentName] = useState("")


  // const [websiteUrl, setWebsiteUrl] = useState("");
  const [internalLinks, setInternalLinks] = useState([])
  const [selectedLinks, setSelectedLinks] = useState([])
  const [sitemapUrls, setSitemapUrls] = useState([]) // To store fetched sitemap URLs
  const parameters = useParams() // Access chatbotId from URL params
  const chatbotId = parameters.uuid
  const [openPicker] = useDrivePicker()
  const apiUrl = process.env.REACT_APP_API_URL
  const modelUrl = process.env.REACT_APP_AI_AGENT_URL
  const userId = FetchDataFromLocalStorage('userId')
  const token = FetchDataFromLocalStorage('token')
  const uuid = FetchDataFromLocalStorage('current_uuid')


  useEffect(() => {
    if (activeTab === 'website') {
      setDocumentType('Url')
    } else if (activeTab === 'text') {
      setDocumentType('Text')
    } else {
      setDocumentType('File')
    }
  }, [activeTab])


  useEffect(() => {
    if (!chatbotId) {
      // setIsSidebarOpen(false);
      setInitialSidebarOpen(false)
      console.log(isSidebarOpen)
    } else {
      setInitialSidebarOpen(true)
    }
  }, [chatbotId])

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.matchMedia('(max-width: 768px)').matches
      setIsSidebarOpen(!isMobile) // Close sidebar on mobile, open on larger screens
    }

    // Initialize state based on the initial screen size
    handleResize()

    // Add event listener for window resize
    window.addEventListener('resize', handleResize)

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const fetchWebsite = async () => {
      try {
        const response = await fetch(`${apiUrl}/getBacklink/${uuid}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          // body: JSON.stringify({ userId, uuid, type: "Text" }),
        })

        const data = await response.json()
        // setWebsiteLinks(data)

        setWebsiteLinks(data.data);

        console.log(data.data, 'gogg')

        setSelectedLinks(data.data?.qodeinteractive_com || []);

      } catch (error) {
        console.error('Error fetching documents:', error)
      }
    }

    fetchWebsite()
  }, [apiUrl, userId, uuid, documentType])

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await fetch(`${apiUrl}/getDocuments`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId, uuid, type: documentType }),
        })

        const data = await response.json()

        if (response.ok) {
          const documents = Array.isArray(data?.data?.[`AiAgent ${uuid}`])
            ? data.data[`AiAgent ${uuid}`]
            : []
          setDocumentList(documents)
        } else {
          toast.error(data.message || 'Failed to load documents.', {
            position: 'bottom-right',
          })
        }
      } catch (error) {
        console.error('Error fetching documents:', error)
      }
    }

    fetchDocuments()
  }, [apiUrl, userId, uuid, documentType, refreshTrigger, isLoading])



  console.log(documentList, 'doc list', refreshTrigger)

  const handleSidebarToggle = isOpen => {
    setIsSidebarOpen(isOpen)
  }

  // const handleFileUpload = async event => {
  //   const maxFileSize = 10 * 1024 * 1024 // 1MB
  //   const files = Array.from(event.target.files)

  //   const validFiles = files.filter(file => {
  //     if (file.size > maxFileSize) {
  //       toast.warning(`File ${file.name} exceeds the maximum size of 1MB.`, {
  //         position: 'top-center',
  //       })
  //       return false
  //     }
  //     return true
  //   })

  //   if (!validFiles.length) return

  //   const formData = new FormData()
  //   formData.append('userId', userId)
  //   formData.append('uuid', uuid)
  //   formData.append('DocumentType', documentType)
  //   validFiles.forEach(file => formData.append('documents', file))

  //   setIsLoading(true)
  //   try {
  //     const response = await fetch(`${apiUrl}/uploadDocuments`, {
  //       method: 'POST',
  //       headers: { Authorization: `Bearer ${token}` },
  //       body: formData,
  //     })

  //     const result = await response.json()
  //     if (response.ok) {
  //       toast.success('Files uploaded successfully!', {
  //         position: 'bottom-right',
  //       })
  //       // await updateCounter("DocumentCount")

  //       setRefreshTrigger(prev => !prev) // Trigger refresh
  //     } else {
  //       toast.error(result.message || 'Failed to upload files.', {
  //         position: 'bottom-right',
  //       })
  //     }
  //   } catch (error) {
  //     toast.error('Error uploading files.', { position: 'bottom-right' })
  //   } finally {
  //     setIsLoading(false)
  //   }
  // }

  const handleFileUpload = async event => {
    const maxFileSize = 10 * 1024 * 1024 // 1MB
    const files = Array.from(event.target.files)

    const validFiles = files.filter(file => {
      if (file.size > maxFileSize) {
        toast.warning(`File ${file.name} exceeds the maximum size of 1MB.`, {
          position: 'top-center',
        })
        return false
      }
      return true
    })

  const validFilesContent = [];

  for (const file of validFiles) {
    const isHarmful = await checkForMaliciousContent(file);
    console.log(isHarmful,'isHarmful')
    if (isHarmful) {
      toast.error(`File "${file.name}" contains potentially harmful content. Please upload a clean file.`, {
        position: 'bottom-right',
      });
    } else {
      validFilesContent.push(file);
    }
  }

    if (!validFiles.length || !validFilesContent.length) return

    const formData = new FormData()
    formData.append('userId', userId)
    formData.append('uuid', uuid)
    formData.append('DocumentType', documentType)
    validFilesContent.forEach(file => formData.append('documents', file))

    setIsLoading(true)
    try {
      const response = await fetch(`${apiUrl}/uploadDocuments`, {
        method: 'POST',
        headers: { Authorization: `Bearer ${token}` },
        body: formData,
      })

      const result = await response.json()
      console.log(result,"resultresultresult")
      if (response.ok) {
        toast.success('Files uploaded successfully!', {
          position: 'bottom-right',
        })
        await updateCounter("DocumentCount")

        setRefreshTrigger(prev => !prev) // Trigger refresh
      } else if(response.message) {
        toast.error(response.message || 'Failed to upload files.', {
          position: 'bottom-right',
        })
      }
       else {
        toast.error(result.message || 'Failed to upload files.', {
          position: 'bottom-right',
        })
      }
    } catch (error) {
      toast.error('Error uploading files.', { position: 'bottom-right' })
    } finally {
      setIsLoading(false)
    }
  }


  const handleDeleteDocument = async document => {
    try {
      const response = await fetch(`${apiUrl}/deleteDocument`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId,
          documentName: document.documentName,
          uuid,DocumentType:documentType
        }),
      })

      if (response.ok) {
        setDocumentList(prevList =>
          prevList.filter(doc => doc.documentId !== document.documentId),
        )
        await updateCounter("DocumentCount", "decrement")
        setRefreshTrigger((prev) =>!prev);
        toast.success('Document deleted successfully!', {
          position: 'bottom-right',
        })

      } else {
        const errorData = await response.json()
        toast.error(errorData.message || 'Failed to delete document.', {
          position: 'bottom-right',
        })
      }
    } catch (error) {
      // toast.error("An error occurred while deleting the document.", {
      //   position: "bottom-right",
      // });
    }
  }

  const handleTraining = async (links = []) => {
    setIsTraining(true);

    try {
      const payload = {
        user_id: userId,
        agent_id: uuid,
      };

      if (links.length > 0) {
        payload.links = links;
        payload.company_name = 'company';
      }

      const trainingResponse = await axios.post(`${modelUrl}/retrain`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (trainingResponse.data?.details?.collection_id) {
        toast.success('AI Agent trained successfully!', {
          position: 'bottom-right',
        });

        await handleUpdateCollectionName(uuid, trainingResponse.data.details.collection_id);
        setCollection(trainingResponse.data.details.collection_id);

        await updateCounter('TotalTrains');

        if (links.length > 0) {
          const updateResponse = await axios.post(
            `${apiUrl}/updateBacklink`,
            {
              uuid: uuid,
              backlinks: links.reduce((acc, link) => {
                try {
                  const parsedUrl = new URL(link);
                  const domain = parsedUrl.hostname;

                  if (!acc[domain]) {
                    acc[domain] = [];
                  }

                  acc[domain].push(link);
                } catch (error) {
                  console.error(`Invalid URL skipped: ${link}`);
                }

                return acc;
              }, {}),
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (updateResponse.data.success) {
            // toast.success('Backlinks updated successfully.', {
            //   position: 'bottom-right',
            // });
          } else {
            // toast.error('Failed to update backlinks.', {
            //   position: 'bottom-right',
            // });
          }
        }

        if (chatbotId) {
          navigate(`/agent/${uuid}`);
        }
      } else {
        toast.error('Failed to train AI Agent.', { position: 'bottom-right' });
      }
    } catch (error) {
      toast.error(
        error.response?.data?.detail || 'Error training AI Agent.',
        { position: 'bottom-right' }
      );
    } finally {
      setIsTraining(false);
    }
  };

  const handleGoogleDriveUpload = () => {
    openPicker({
      clientId: 'YOUR_CLIENT_ID',
      developerKey: 'YOUR_DEVELOPER_KEY',
      viewId: 'DOCS',
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      callbackFunction: data => {
        if (data.action === 'picked') {
          toast.success('Files uploaded from Google Drive!', {
            position: 'bottom-right',
          })
        }
      },
    })
  }

  const handleBackLinks = async () => {
    try {
      setIsLoading(true)
      const response = await axios.post(
        `${modelUrl}/crawl-page`,
        { url: websiteUrl },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      )
      console.log(response.data.data, 'sdfsdf')

      if (response.data.data.internal_links) {
        setInternalLinks(response.data.data.internal_links)

        toast.success('Crawling successful!', { position: 'bottom-right' })
      } else {
        toast.warn('Unexpected response format.', { position: 'bottom-right' })
      }
    } catch (error) {
      toast.error(error.response?.data?.detail || 'Error during crawling.', {
        position: 'bottom-right',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleCheckboxChange = link => {
    setSelectedLinks(prev =>
      prev.includes(link)
        ? prev.filter(item => item !== link)
        : [...prev, link],
    )
  }

  const handleEraseLink = (domain, linkToRemove) => {

    setWebsiteLinks(prevState => ({
      ...prevState,
      [domain]: prevState[domain]?.filter(link => link !== linkToRemove)
    }));
  }
  // Fetch Sitemap Function
  const handleSitemapCrawl = async () => {
    try {
      const response = await axios.post(
        `${modelUrl}/fetch-sitemap`,
        { url: siteUrl },
        { headers: { 'Content-Type': 'application/json' } },
      )

      if (response.data?.data?.sitemap_urls) {
        // setSitemapUrls(response.data.data.sitemap_urls)
        setInternalLinks(response.data.data.sitemap_urls)
        // console.log(internalLinks, 'internalLinks')

        toast.success('Sitemap fetched successfully!', {
          position: 'bottom-right',
        })
      } else {
        toast.warn('No links found in the sitemap.', {
          position: 'bottom-right',
        })
      }
    } catch (error) {
      toast.error(
        error.response?.data?.detail || 'Error fetching sitemap.',
        // console.error(error.response?.data || "Error occurred"),
        { position: 'bottom-right' },
      )
    } finally {
      setIsLoading(false)
    }
  }



  const handleTextUpload = async () => {
    if (!text.trim()) {
      toast.warn('Text field is empty. Please enter some text.', {
        position: 'bottom-right',
      })
      return
    }

    setIsLoading(true)
    try {
      const response = await axios.post(
        `${apiUrl}/uploadDocuments`,
        { uuid, DocumentType: 'Text', documentText: text, userId ,documentName:documentName },
        { headers: { Authorization: `Bearer ${token}` } },
      )

      if (response.status === 200) {
        toast.success('Text uploaded successfully!', {
          position: 'bottom-right',
        })
        await updateCounter("TextCount")
        setText('')
      } else {
        toast.error('Failed to upload text.', { position: 'bottom-right' })
      }
    } catch (error) {
      toast.error('Error uploading text.', { position: 'bottom-right' })
    } finally {
      setIsLoading(false)
    }
  }

  const handleUpdateCollectionName = async (uniqueId, collectionName) => {
    try {
      const response = await axios.put(`${apiUrl}/update-collection-name`, {
        uniqueId,
        collectionName,
      })

      if (response.data && response.data.updatedAgent) {
        // toast.success("Collection name updated successfully!", {
        //   position: "bottom-right",
        // });
      } else {
        console.warn('Unexpected response format:', response.data)
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || 'Error updating collection name.',
        {
          position: 'bottom-right',
        },
      )
    }
  }

  const updateCounter = async (Type, process = "increment") => {

    try {
      const response = await axios.post(
        `${apiUrl}/update-agent-counter`,
        {
          uuid: uuid,
          process: process,
          userId: userId,
          counterType: Type
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )

      if (response.data.success) {
        // toast.success('train incremented successfully!', {
        //   position: 'bottom-right',
        // })
        // handleUpdateCollectionName(uuid, response.data.details.collection_id)
        // setCollection(response.data.details.collection_id)


        // if (chatbotId) {
        //   navigate(`/agent/${response.data.details.collection_id}`)
        // }
      } else {
        // toast.error('Failed to train AI Agent.', { position: 'bottom-right' })
      }
    } catch (error) {
      // toast.error('Error training AI Agent.', { position: 'bottom-right' })
    } finally {
      // setIsTraining(false)
    }

  }

  const handleTrainButtonClick = async () => {
    if (selectedLinks.length > 0) {
      await handleTraining(selectedLinks);
    } else {
      await handleTraining();
    }
  };


  return (
    <>
      {chatbotId && <Sidebar onToggle={handleSidebarToggle} />}

      <div
        className="flex bg-gray-50"
        style={{
          // width: '100%',
          height: '100%',
          marginLeft: initialSidebarOpen
            ? isSidebarOpen
              ? '280px'
              : '0'
            : '0',
          transition: 'margin 0.3s ease-in-out',
        }}
      >
        {/* Sidebar */}
        <aside className="w-1/4 p-6 bg-white border-r">
          <h2 className="text-2xl font-bold mb-6">Sources</h2>
          <nav className="space-y-4">
            {sourcesTabs.map(tab => (
              <button
                key={tab}
                onClick={() => setActiveTab(tab)}
                className={`flex items-center px-4 py-2 rounded-lg font-medium ${activeTab === tab
                    ? 'bg-purple-100 text-purple-600'
                    : 'hover:bg-gray-100 text-gray-700'
                  }`}
              >
                {tab === 'file' && ' Files'}
                {tab === 'text' && ' Text'}
                {tab === 'website' && ' Website'}
                {/* {tab === "qa" && "💬 Q&A"} */}
                {/* {tab === "notion" && "📓 Notion"} */}
              </button>
            ))}
          </nav>

          <div>
            {/* <h2 className="text-xl font-bold mb-4">Sources</h2> */}
            {/* <p>Total detected characters</p>
        <p className="text-lg font-bold">34,840 / 400,000 limit</p> */}
            <button
              onClick={handleTrainButtonClick}
              disabled={isTraining}
              className={`w-full mt-4 px-6 py-3 rounded-lg font-bold ${isTraining
                  ? 'bg-gray-400 text-gray-700 cursor-not-allowed'
                  : 'bg-purple-500 hover:bg-purple-600 text-white'
                }`}
            >
              {isTraining ? 'Training...' : 'Retrain AI Agent'}
            </button>

            <br />
          </div>
        </aside>

        {/* Main Content */}
        <main className="flex-1 p-6 space-y-6">
          {activeTab === 'file' && (
            <section>
              <h2 className="text-xl font-bold mb-4">Upload Files</h2>
              <input
                type="file"
                accept=".doc,.docx,.txt,.pdf"
                className="hidden"
                multiple
                onChange={handleFileUpload}
                id="fileInput"
              />
              <label
                htmlFor="fileInput"
                className="block p-6 border-2 border-dashed rounded-lg text-center cursor-pointer"
              >
                Drag & drop files here, or click to select files
                <p className="text-sm text-gray-500 mt-2">
                  Supported formats: PDF, DOC, TXT
                </p>
              </label>
            </section>
          )}

{activeTab === 'text' && (
  <section>
    <h2 className="text-xl font-bold mb-4">Upload Text</h2>
    <br />
    <label>Document Name:</label>
    <input
      type="text"
      className="p-2 border rounded-lg"
      value={documentName}
      onChange={(e) => setDocumentName(e.target.value)}
      placeholder="Enter document name"
    />
    <br />

    <textarea
      className="w-full mt-4 h-40 p-4 border rounded-lg"
      value={text}
      onChange={(e) => setText(e.target.value)}
      placeholder="Enter your text here..."
    ></textarea>

    <button
      onClick={handleTextUpload}
      disabled={!documentName.trim() || !text.trim()} // Button disabled if either field is empty
      className={`mt-4 px-6 py-3 rounded-lg text-white ${
        documentName.trim() && text.trim()
          ? 'bg-purple-500 hover:bg-purple-600'
          : 'bg-gray-300 cursor-not-allowed'
      }`}
    >
      Upload Text
    </button>
  </section>
)}


          {activeTab === 'website' && (
            <section>
              <h2 className="text-xl font-bold mb-2 overflow-auto">
                Website Data Source
              </h2>
              <div>
                {' '}
                <input
                  type="text"
                  className="w-full p-4 border rounded-lg "
                  value={websiteUrl}
                  onChange={e => setWebsiteUrl(e.target.value)}
                  placeholder="https://www.example.com"
                />
                <p className='text-sm text-gray-600'>This will crawl all the links starting with the URL (not including files on the website).</p>
                <div className="flex justify-center">
                  <button
                    onClick={handleBackLinks}
                    className="bg-purple-500 text-white font-bold py-2 px-6 m-3 ml-3 rounded-lg hover:bg-purple-600 transition"
                    disabled={isLoading}
                  >
                    {isLoading ? 'Crawling Links...' : 'Crawl Links'}
                  </button>
                </div>{' '}
              </div>
              <hr className="my-6 border-t border-gray-300" />
              <div className='flex justify-center mb-2'>
                <p>  OR</p>

              </div>


              <div>
                <input
                  type="text"
                  className="w-full p-4 border rounded-lg "
                  value={siteUrl}
                  onChange={e => setSiteUrl(e.target.value)}
                  placeholder="https://www.example.com/sitemap.xml "
                />
                <div className="flex justify-center">
                  <button
                    onClick={handleSitemapCrawl}
                    className={`mt-4 bg-purple-500 text-white font-bold py-2  px-6 rounded-lg ${isLoading
                        ? 'cursor-not-allowed opacity-50'
                        : 'hover:bg-purple-600'
                      }`}
                    disabled={isLoading}
                  >
                    {isLoading ? 'Loading Sitemap...' : 'Load Sitemap'}
                  </button>
                </div>
              </div>
            </section>
          )}

          {documentType == 'Url' && internalLinks.length > 0 && (
            <div className="bg-white p-2 rounded-lg shadow-lg mb-3">
              <div className="flex justify-between">
                <h2 className="text-2xl font-semibold mb-4">
                  Select Links for Training
                </h2>
              </div>

              <ul
                className="space-y-2 max-h-64 overflow-y-auto border border-gray-200 rounded-lg bg-gray-50 p-4 shadow-lg"
                style={{
                  scrollbarWidth: 'thin', // Firefox
                  scrollbarColor: '#A0AEC0 #EDF2F7', // Firefox scrollbar thumb and track
                }}
              >
                <div className="flex items-center mb-4">
                  <input
                    type="checkbox"
                    className="mr-2"
                    onChange={e => {
                      if (e.target.checked) {
                        setSelectedLinks(internalLinks) // Select all links
                      } else {
                        setSelectedLinks([]) // Deselect all links
                      }
                    }}
                    checked={
                      selectedLinks.length === internalLinks.length &&
                      internalLinks.length > 0
                    } // Check if all are selected
                  />
                  <span>Select All</span>
                </div>
                {internalLinks.map((link, index) => (
                  <li key={index} className="flex items-center">
                    <input
                      type="checkbox"
                      className="mr-2"
                      value={link}
                      onChange={() => handleCheckboxChange(link)}
                      checked={selectedLinks.includes(link)}
                    />
                    <span>{link}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </main>

        {/* Right Sidebar */}
        <aside className="w-1/4 p-6 bg-white border-l overflow-y-auto">

          <div style={{ height: '80vh' }}>
            {/* Header for Uploaded Content */}
            <h3 className="text-sm font-bold mb-4">
              Uploaded {activeTab === 'files' ? 'Files' : activeTab === 'text' ? 'Text' : activeTab === 'website' ? 'Website' : 'Documents'}
            </h3>

            {/* Content Section */}
            {documentType !== 'Url' ? (
              // Files or Text Content
              documentList.length > 0 ? (
                <div className="overflow-y-auto border-t border-gray-200 pt-4">
                  <ul className="space-y-2 text-sm">
                    {documentList.map((doc) => (
                      <li
                        key={doc.documentId}
                        className="flex items-center justify-between group hover:bg-gray-50 px-3 py-2 rounded-lg"
                      >
                        <div className="flex-1 min-w-0">
                          <a
                            href={doc.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-blue-500 hover:underline truncate block w-full"
                            title={doc.documentName} // Tooltip for full name
                            style={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {doc.documentName}
                          </a>
                        </div>

                        <button
                          onClick={() => handleDeleteDocument(doc)}
                          className="text-red-500 hover:text-red-700 transition flex items-center ml-2 flex-shrink-0"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 -960 960 960"
                            width="24px"
                            fill="currentColor"
                          >
                            <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
                          </svg>
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : (
                <p className="text-gray-500 mt-6">No documents uploaded yet.</p>
              )
            ) : (
              <div className="p-4">
                {websiteLinks.length === 0 ? (
                  <>
                    {/* <h2 className="text-xl font-bold mb-4">Website Links</h2> */}
                    <p>No links available.</p>
                  </>
                ) : (
                  <ul className="space-y-4">
                    {documentType === "Url" && (
                      <>
                        {/* <h2 className="text-xl font-bold mb-4">Website Links</h2> */}
                        <div className="flex justify-between"></div>
                      </>
                    )}

                    {Object.entries(websiteLinks).flatMap(([domain, links]) =>
                      links.map((link) => (
                        <li
                          key={link}
                          className="flex items-center justify-between border rounded-lg p-4 group hover:bg-gray-50"
                        >
                          <div className="flex items-center flex-1 min-w-0">
                            {/* <input
                              type="checkbox"
                              className="mr-2"
                              checked={selectedLinks.includes(link)}
                              onChange={() => handleCheckboxChange(link)}
                            /> */}
                            <span
                              className="flex-grow truncate"
                              title={link} // Tooltip for full URL
                              style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                              }}
                            >
                              {link}
                            </span>
                          </div>

                          <button
                            onClick={() => handleEraseLink(domain, link)}
                            className="text-red-500 hover:text-red-700 transition flex items-center ml-2 flex-shrink-0"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24px"
                              viewBox="0 -960 960 960"
                              width="24px"
                              fill="currentColor"
                            >
                              <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" />
                            </svg>
                          </button>
                        </li>
                      ))
                    )}
                  </ul>
                )}
              </div>
            )}
          </div>
        </aside>

        {/* Loader */}
        {(isLoading || isTraining) && (
          <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex items-center justify-center">
            <div className="bg-white p-6 rounded shadow text-center">
              <img
                src={LoadingGif}
                alt="Loading"
                className="w-16 h-16 mx-auto mb-4"
              />
              <p className="font-bold">Processing...</p>
            </div>
          </div>
        )}
        <ToastContainer position="bottom-right" />
      </div>
    </>
  )
}