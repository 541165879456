import React, { useState, useEffect } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import {
  FaFolder,

  FaUser,
 
  FaChevronDown,
  FaChevronUp,
} from 'react-icons/fa'
import aisaXLogo from '../../img/aisaXLogo.png'
import { AdminIcons, AgentIcon, HomeIcon, Intelligence, LogoutIcon, PaymentIcon, Playground, Usage, UsageIcon, UserIcon, UsersettingIcons } from '../../Svg icons/Svg'

const Sidebar = ({ onToggle }) => {
  const [isOpen, setIsOpen] = useState(true)
  const location = useLocation()
  const [isLogoHovered, setIsLogoHovered] = useState(false)
  const [showExperience, setShowExperience] = useState(true)
  const [showSubItems, setShowSubItems] = useState(false) // State for subitems
  const clientUrl = process.env.REACT_APP_URL

  const toggleSidebar = () => {
    setIsOpen(!isOpen)
    onToggle(!isOpen)
  }

  const { chatbotId, uuid } = useParams();

  // Log the values dynamically based on the route
  // console.log("Chatbot ID:", chatbotId || "Not Available");
  // console.log("UUID:", uuid || "Not Available");

  const handleLogout = () => {
    localStorage.removeItem('token')
    // checkToken();
    window.location.href = `${clientUrl}/`
  }
  const isActive = path => location.pathname === path

  useEffect(() => {
    const interval = setInterval(() => {
      setShowExperience(prev => !prev)
    }, 3000) // Change every 3 seconds

    return () => clearInterval(interval)
  }, [])

  const toggleSubItems = () => {
    setShowSubItems(!showSubItems) // Toggle subitems
  }

  return (
    <div
      className={`fixed   hidden md:block top-0 left-0 h-full bg-[#000000] text-white transition-transform duration-300 shadow-lg ${
        isOpen ? 'translate-x-0' : '-translate-x-full'
      }`}
      style={{ width: '250px', height: '100vh' }}
    >
      {/* Toggle Button */}
      <button
        onClick={toggleSidebar}
        className="absolute bottom-3 -right-7 p-2 bg-gray-100 hover:bg-gray-300 text-white rounded-full shadow-md"
      >
        {isOpen ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="#5f6368"
          >
            <path d="M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 -960 960 960"
            width="24px"
            fill="#5f6368"
          >
            <path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z" />
          </svg>
        )}
      </button>

      {/* Sidebar Logo */}
      <div className="flex flex-col justify-center items-center mt-8 mb-8 space-y-4">
        <Link to="/dashboard">
        <div
          className={`relative bg-white p-6 rounded-full shadow-2xl transform transition-all duration-300 hover:scale-110 ${
            isLogoHovered ? 'animate-spin' : ''
          }`}
          // onMouseEnter={() => setIsLogoHovered(true)}
          // onMouseLeave={() => setIsLogoHovered(false)}
        >

          <img
            src={aisaXLogo}
            alt="AiSA-X Logo"
             className="w-12 h-auto object-cover md:w-16 lg:w-18 xl:w-12 transform scale-150"
            // className="w-12 h-auto object-cover md:w-16 lg:w-18 xl:w-16"
          />
          <div className="absolute -bottom-3 -right-3 bg-[#6600ff] text-white text-xs font-bold px-3 py-1 rounded-full shadow-md md:text-sm lg:text-sm">
            Beta
          </div>
        </div>
        </Link>
        <div className="text-[10px]  font-bold text-gray-500 uppercase tracking-wide md:text-xs lg:text-xs">
          {showExperience ? (
            <div className="whitespace-nowrap animate-marquee">
              Experience the Future of AI
            </div>
          ) : (
            <div className="whitespace-nowrap animate-marquee-hidden">
              Aisa-X
            </div>
          )}
        </div>
      </div>

      {/* Navigation Links */}
      <nav className="px-3 bg-[#050505] " style={{ height: '69vh' }}>
        <ul className="space-y-7 mt-2">
         {/* Admin Only Link */}
         {location.pathname === "/admin-only" && (
             <li
             className={`flex items-center space-x-4 p-3 mt-7 rounded-lg cursor-pointer ${
               isActive('/admin-only') ? 'bg-purple-900' : 'hover:bg-purple-600 transition'
             }`}
           >
             <AdminIcons className="text-white text-xl" />
             <Link
               to="/admin-only"
               className={`text-white text-md ${
                 isActive('/admin-only') ? 'font-bold' : 'font-medium'
               }`}
             >
               Admin 
             </Link>
           </li>
         )}
         
         


         {/* <Link to="/dashboard">
  <li
    className={`flex items-center space-x-4 p-3 mt-6 cursor-pointer rounded-lg ${
      isActive('/dashboard')
        ? 'bg-purple-900'
        : 'hover:bg-purple-600 transition'
    }`}
  >
    <HomeIcon className="text-white text-xl" />
    <span
      className={`text-white text-md ${
        isActive('/dashboard') ? 'font-bold' : 'font-medium'
      }`}
    >
      Dashboard
    </span>
  </li>
</Link> */}

          {/* <li
            className={`flex flex-col p-3 rounded-lg ${
              isActive('/agents')
                ? 'bg-purple-900'
                : 'hover:bg-purple-600 transition'
            }`}
          > */}
            {/* Main Dropdown Item */}
            {/* <Link to="/agents">

            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={toggleSubItems}
            >
              <div className="flex items-center space-x-2">
                <AgentIcon />

                <span
                  className={`text-white text-md ml-2 ${
                    isActive('/agents') ? 'font-bold' : 'font-medium'
                  }`}
                >
                  
                  Agents */}
                  {/* </Link> */}
                {/* </span>
              </div> */}
              {/* <div className="text-white">
                {showSubItems ? <FaChevronUp /> : <FaChevronDown />}
              </div> */}
            {/* </div>

            </Link>

          

          </li> */}
      {/* {showSubItems && ( // Render subitems only if dropdown is open
            <li className="ml-8   bg-purple-900 rounded-md  shadow-md">
              <ul className="  ">
                <li className="p-2 flex   hover:bg-purple-600 transition">
                  <Playground/>
                  <Link to="/playground" className={`text-white ml-2 text-md ${isActive('/playground') ? 'font-bold' : 'font-medium'}`}>Playground</Link>
                </li>
                <li className="p-2 flex  hover:bg-purple-600 transition">
                  <Usage/>
                  <Link to="/usage" className={`text-white ml-3 text-md ${isActive('/usage') ? 'font-bold' : 'font-medium'}`}>Usage</Link>
              

                </li>
                <li className="p-2 hover:bg-purple-600 transition">
                  <Link to="/activity" className={`text-white text-md ${isActive('/activity') ? 'font-bold' : 'font-medium'}`}>Activity</Link>
                </li>
              </ul>
             </li>
          )}  */}

<Link to="/dashboard">
  <li
    className={`flex items-center space-x-4 p-3 mt-4 rounded-lg cursor-pointer pl-10 ${
      isActive('/dashboard') ? 'bg-purple-900' : 'hover:bg-purple-600 transition'
    }`}
  >
    <HomeIcon className="text-white text-xl" />
    <span
      className={`text-white text-md${
        isActive('/dashboard') ? 'font-bold' : 'font-medium'
      }`}
    >
      Dashboard
    </span>
  </li>
</Link>


            <Link to="/agents">
  <li
    className={`flex items-center space-x-4 p-3 mt-4 rounded-lg cursor-pointer pl-10 ${
      isActive('/agents') ? 'bg-purple-900' : 'hover:bg-purple-600 transition'
    }`}
  >
    <AgentIcon className="text-white text-xl" />
    <span
      className={`text-white text-md ${
        isActive('/agents') ? 'font-bold' : 'font-medium'
      }`}
    >
       Agents
    </span>
  </li>
</Link>
          <Link to="/usage">
  <li
    className={`flex items-center space-x-4 p-3 mt-4 rounded-lg cursor-pointer pl-10 ${
      isActive('/usage') ? 'bg-purple-900' : 'hover:bg-purple-600 transition'
    }`}
  >
    <UsageIcon className="text-white text-xl" />
    <span
      className={`text-white text-lg ${
        isActive('/usage') ? 'font-bold' : 'font-medium'
      }`}
    >
      Usage
    </span>
  </li>
</Link>
        
<Link to="/settings">
  <li
    className={`flex items-center space-x-4 p-3 mt-4 rounded-lg cursor-pointer pl-10 ${
      isActive('/settings') ? 'bg-purple-900' : 'hover:bg-purple-600 transition'
    }`}
  >
    <UsersettingIcons className="text-white text-xl" />
    <span
      className={`text-white text-lg ${
        isActive('/settings') ? 'font-bold' : 'font-medium'
      }`}
    >
      Settings
    </span>
  </li>
</Link>

<Link to="/paymentpage">
  <li
    className={`flex items-center space-x-4 p-3 mt-4 rounded-lg cursor-pointer pl-10 ${
      isActive('/paymentpage')
        ? 'bg-purple-900'
        : 'hover:bg-purple-600 transition'
    }`}
  >
    <PaymentIcon className="text-white text-xl" />
    <span
      className={`text-white text-lg ${
        isActive('/paymentpage') ? 'font-bold' : 'font-medium'
      }`}
    >
      Payment
    </span>
  </li>
</Link>


<Link to="/UserProfile">
  <li
    className={`flex items-center space-x-4 p-3 mt-4 rounded-lg cursor-pointer pl-10 ${
      isActive('/UserProfile')
        ? 'bg-purple-900'
        : 'hover:bg-purple-600 transition'
    }`}
  >
    <UserIcon className="text-white text-xl" />
    <span
      className={`text-white text-md ${
        isActive('/UserProfile') ? 'font-bold' : 'font-medium'
      }`}
    >
      Profile
    </span>
  </li>
</Link>


        </ul>
      </nav>
      <div className="fixed bottom-0 right-7">
  <button
    onClick={handleLogout}
    className={`flex items-center space-x-2 p-4 rounded-lg text-white font-medium transition ${
      isActive('/logout') ? 'bg-purple-900' : 'hover:bg-purple-600'
    }`}
  >
    <LogoutIcon className="text-white text-xl" /> {/* Replace with desired icon */}
    <span>Log Out</span>
  </button>
</div>

      </div>
    
  )
}

export default Sidebar;

