import React from 'react';
import StatsBox from './StatsBox';

const StatsSection = ({ stats }) => {
  return (
    <div className="bg-white shadow-md rounded-lg p-4">
     <h2 className="text-lg font-semibold ">Agent Statistic</h2>
      <div className="flex justify-between ">
        {stats.map((stat, index) => (
          <StatsBox
            key={index}
            title={stat.title}
            value={stat.value}
            isActive={stat.isActive}
          />
        ))}
      </div>
    </div>
  );
};

export default StatsSection;
