import React from "react";
import "./SourcesSummaryCard.css";

const SourcesSummaryCard = () => {
  return (
    <div className="sources-summary-card">
      <h3 className="summary-title">Sources</h3>
      <p className="summary-links">8 Links (34,840 detected chars)</p>
      <p className="summary-characters">
        <strong>Total detected characters:</strong> 34,840/400,000 limit
      </p>
      <button className="retrain-btn">Retrain Chatbot</button>
    </div>
  );
};

export default SourcesSummaryCard;
