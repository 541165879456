import { useEffect, useState, useRef } from "react";
import ChatImg from "../../img/Chatingimg.jpg";
import { Outlet, useParams, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FetchDataFromLocalStorage } from "../../hooks/FetchDataFromLocalStorage";
import axios from "axios";

import {
  FaArrowLeft,
  FaArrowRight,
  FaPlus,
  FaSave,
  FaTimes,
} from 'react-icons/fa'
import { useAuth } from "../../contexts/authContext";
import likeIcon from '../../img/liked.png'; // Import your like icon
import dislikeIcon from '../../img/disliked.png'; // Import your dislike icon
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

export default function ChatComponent() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [isLoading, setIsLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedChatbot, setSelectedChatbot] = useState(null)

  // setIsLoading(false)

  const { chatbotId } = useParams();
  const { checkToken } = useAuth()

  const location = useLocation();
  const navigate = useNavigate();
  const modelUrl = process.env.REACT_APP_AI_AGENT_URL;
  const apiUrl = process.env.REACT_APP_API_URL;
  const [customizationData, setCustomizationData] = useState({
    agentColor: '#000000',
    userColor: '#000000',
    font: 'Arial',
    agentImg: null,
    backgroundColor: '#FFFFFF',
    name: '',
    welcomeMessage: '',
  })
  const [customizationModalOpen, setCustomizationModalOpen] = useState(false)

  const userId = FetchDataFromLocalStorage("userId") ;
  const uuid = FetchDataFromLocalStorage("current_uuid") ;
  const current_agent_id = FetchDataFromLocalStorage("current_id") ;
  const current_agent_name = FetchDataFromLocalStorage("current_name") ;
  const stored_session_id = FetchDataFromLocalStorage('stored_session_id');


  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);

  const token = FetchDataFromLocalStorage('token')

  // Auto-scroll to the latest message
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const handleImageChange = file => {
    setCustomizationData(prevData => ({
      ...prevData,
      agentImg: file,
    }))
  }
  const handleCreation = () => {
    setModalOpen(true)
    checkToken()
  }

  const handleSaveCustomizations = async () => {
    // if (!selectedChatbot) return

    const formData = new FormData()
    formData.append('agentId', current_agent_id)
    formData.append('AgentColor', customizationData.agentColor)
    formData.append('UserColor', customizationData.userColor)
    formData.append('font', customizationData.font)
    formData.append('BackGroundColor', customizationData.backgroundColor)
    formData.append('name', customizationData.name)
    formData.append('welcomeMessage', customizationData.welcomeMessage)
    if (customizationData.agentImg) {
      formData.append('agentImg', customizationData.agentImg)
    }

    try {
      await axios.post(`${apiUrl}/agent-update`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })

      alert('Customizations saved successfully!')
      setCustomizationModalOpen(false)
      setSelectedChatbot(null)
    } catch (error) {
      console.error('Error saving customizations:', error)
      alert('Failed to save customizations. Please try again.')
    }
  }

  useEffect(() => {
    scrollToBottom()
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [messages])

  const handleCancelCustomizations = () => {
    setCustomizationModalOpen(false)
    // setSelectedChatbot(null)
  }

  // const messagesEndRef = useRef(null);

  // // Auto-scroll to the latest message
  // const scrollToBottom = () => {
  //   if (messagesEndRef.current) {
  //     messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  const fetchInitialMessage = async () => {
    try {
      const params = {
        userId,
        agentId: chatbotId,
        collection_id: chatbotId,
      }

      const response = await axios.get(`${apiUrl}/initial_message/${uuid}`, {
        params,
      })

      if (response.data.success && response.data.agent_Details) {
        const welcomeMessage = response.data.agent_Details.welcomeMessage;
        const name = response.data.agent_Details.name;
        if(stored_session_id){
          setSessionId(stored_session_id)

        }else{
          setSessionId(response.data.sessionId);

          localStorage.setItem('stored_session_id', response.data.sessionId)
        }

        if (welcomeMessage) {
          typeMessage(welcomeMessage)
        } else {
          typeMessage(`Hi, I am ${name}. How can I assist you?`)
        }
      } else {
        throw new Error('Invalid response format')
      }
    } catch (error) {
      console.error('Error fetching initial message:', error)
      setMessages([
        { text: 'Error: Could not load the initial message.', sender: 'Bot' },
      ])
    }
  }

  useEffect(() => {
    fetchInitialMessage()
  }, [])

  const sendMessageToBackend = async userMessage => {
    try {
      const response = await fetch(`${modelUrl}/query`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          question: userMessage,
          collection_id: `${userId}_${chatbotId}`,
          company_name: current_agent_name,
          session_id: sessionId,
          user_id: userId,
          agent_id: chatbotId,
        }),
      })

      if (!response.ok)
        throw new Error(`Failed to fetch response: ${response.statusText}`)

      const reader = response.body.getReader()
      const decoder = new TextDecoder()

      setMessages(prev => [
        ...prev,
        { text: '', sender: 'Bot', isStreaming: true },
      ])

      let accumulatedMessage = ''

      while (true) {
        const { done, value } = await reader.read()
        if (done) break

        const chunk = decoder.decode(value, { stream: true })
        // Remove 'data:' prefix and split
        const words = chunk
          .replace(/data:\s*/g, '') // Remove 'data:' prefix
          .split(/\s+/)
          .filter(word => word.trim() !== '')

        for (const word of words) {
          accumulatedMessage += word + ' '

          // Immediate UI update for each word
          setMessages(prevMessages => {
            const newMessages = [...prevMessages]
            const lastMessage = newMessages[newMessages.length - 1]
            if (lastMessage.sender === 'Bot' && lastMessage.isStreaming) {
              lastMessage.text = accumulatedMessage.trim()
            }
            return newMessages
          })

          // Small delay to simulate typing effect
          await new Promise(resolve => setTimeout(resolve, 30))
        }
      }

      // Final update to remove streaming flag
      setMessages(prevMessages => {
        const newMessages = [...prevMessages]
        const lastMessage = newMessages[newMessages.length - 1]
        if (lastMessage.isStreaming) {
          delete lastMessage.isStreaming
        }
        return newMessages
      })
    } catch (error) {
      console.error('Streaming error:', error)
      setMessages(prev => [
        ...prev,
        { text: 'Error: Could not connect to the agent.', sender: 'Bot' },
      ])
    } finally {
      setLoading(false)
    }
  }

  const handleSendMessage = async () => {
    if (loading) return // Prevent multiple submissions
    if (input.trim()) {
      const messageText = input
      setInput('') // Clear input immediately

      // Immediately add user message to chat
      setMessages(prev => [...prev, { text: messageText, sender: 'User' }])

      setLoading(true)
      try {
        await sendMessageToBackend(input)
      } catch (error) {
        console.error('Error sending message:', error)
        setMessages(prev => [
          ...prev,
          { text: 'Error: Could not send message.', sender: 'Bot' },
        ])
      } finally {
        setLoading(false)
      }
    }
  }
  const openCustomizationModal = chatbotz => {
    // localStorage.setItem('current_uuid', chatbotz.uniqueId)
    // setSelectedChatbot(chatbotz)
    // console.log(chatbotz, 'chatbot')
    setCustomizationData({
      agentId: chatbotz._id,
      agentColor: chatbotz.agentColor || '#000000',
      userColor: chatbotz.userColor || '#000000',
      font: chatbotz.font || 'Arial',
      agentImg: null,
      backgroundColor: chatbotz.backgroundColor || '#FFFFFF',
      name: chatbotz.name || '',
      welcomeMessage: chatbotz.welcomeMessage || '',
    })
    setCustomizationModalOpen(true)
  }

  const handleCustomizationChange = (field, value) => {
    setCustomizationData(prevData => ({
      ...prevData,
      [field]: value,
    }))
  }

  const typeMessage = async text => {
    setMessages(prev => [
      ...prev,
      { text: '', sender: 'Bot', isStreaming: true },
    ])

    for (const char of text) {
      setMessages(prev => {
        const updated = [...prev]
        const lastMessage = updated[updated.length - 1]
        if (lastMessage.sender === 'Bot' && lastMessage.isStreaming) {
          lastMessage.text += char
        }
        return updated
      })

      await new Promise(resolve => setTimeout(resolve, 50))
    }

    setMessages(prev => {
      const updated = [...prev]
      const lastMessage = updated[updated.length - 1]
      if (lastMessage.isStreaming) delete lastMessage.isStreaming
      return updated
    })
  }
  const handleLike = (index) => {
    const updatedMessages = [...messages];
    updatedMessages[index].liked = true;
    updatedMessages[index].disliked = false;
    setMessages(updatedMessages);
  };
  
  const handleDislike = (index) => {
    const updatedMessages = [...messages];
    updatedMessages[index].disliked = true;
    updatedMessages[index].liked = false;
    setMessages(updatedMessages);
  };
  

  const formatTextWithMarkdown = text => (
    <ReactMarkdown remarkPlugins={[remarkGfm]}>{text}</ReactMarkdown>
  )


  return (
    <div className="z-30 flex w-full flex-col mt-3 ">
      {location.pathname === `/agent/${chatbotId}` ? (
        <div className="w-full h-full py-3 bg-white relative mb-3 rounded-[30px]">
          <div className="relative">
          <div className="flex items-center justify-between border-b px-4 pb-2">
          <div className="m-0 fs-20">{current_agent_name}</div>
          <div className="text-2xl  ">
                            <button
                  className="hover:text-gray-600 border-2 rounded-full p-2 text-xl cursor-pointer hover:bg-gray-100"
                  onClick={() => openCustomizationModal(chatbotId)}
                  // disabled={isLoading}
                  aria-label="Settings"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor">
                    <path d="m370-80-16-128q-13-5-24.5-12T307-235l-119 50L78-375l103-78q-1-7-1-13.5v-27q0-6.5 1-13.5L78-585l110-190 119 50q11-8 23-15t24-12l16-128h220l16 128q13 5 24.5 12t22.5 15l119-50 110 190-103 78q1 7 1 13.5v27q0 6.5-2 13.5l103 78-110 190-118-50q-11 8-23 15t-24 12L590-80H370Zm70-80h79l14-106q31-8 57.5-23.5T639-327l99 41 39-68-86-65q5-14 7-29.5t2-31.5q0-16-2-31.5t-7-29.5l86-65-39-68-99 42q-22-23-48.5-38.5T533-694l-13-106h-79l-14 106q-31 8-57.5 23.5T321-633l-99-41-39 68 86 64q-5 15-7 30t-2 32q0 16 2 31t7 30l-86 65 39 68 99-42q22 23 48.5 38.5T427-266l13 106Zm42-180q58 0 99-41t41-99q0-58-41-99t-99-41q-59 0-99.5 41T342-480q0 58 40.5 99t99.5 41Zm-2-140Z"/>
                  </svg>
                </button>
          </div>

          </div>

          <div
            className="hide-scrollbar chatbot-inner-message overflow-y-auto p-4 rounded-[30px]"
            style={{
              // backgroundImage: `url(${ChatImg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`mb-3 ${
                  msg.sender === "User" ? "text-right" : "text-left"
                }`}
              >
                {/* <span
                  className={`inline-block py-2 px-4 fs-13 rounded-[30px] ${
                    msg.sender === 'User'
                      ? 'bg-purple-500 text-white'
                      : 'message-bg text-black'
                  }`}
                  dangerouslySetInnerHTML={{ __html: msg.text }}
                >
                </span> */}

                <span
                  className={`inline-block py-2 px-4 fs-13 rounded-[30px] ${
                    msg.sender === 'User'
                      ? 'bg-purple-500 text-white'
                      : 'message-bg text-black'
                  }`}
                >
                  {formatTextWithMarkdown(msg.text)}
                </span>
                {/* {msg.isStreaming && (
                  <span className="pl-2 text-gray-500">Typing...</span>
                )} */}

{msg.sender === 'Bot' && (
                  <div className="flex justify-start mt-1">
                    <div className="relative group">
                    <button
        onClick={() => handleLike(index)}
        className={`p-1 rounded-full hover:bg-gray-200 ${
          msg.liked ? 'text-blue-500' : 'text-gray-500'
        }`}
      >
        {/* Replace FaThumbsUp with an img tag */}
        <img
          src={likeIcon}
          alt="Like"
          className="w-5 h-5" // Adjust the size as needed
        />
      </button>
                      <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-black text-white text-sm rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
                        {msg.liked ? "Good Response" : "Like"}
                      </div>
                    </div>
                    <div className="relative group ml-2">
                    <button
        onClick={() => handleDislike(index)}
        className={`p-0 rounded-full hover:bg-gray-200 ${
          msg.disliked ? 'text-red-500' : 'text-gray-500'
        }`}
      >
        {/* Replace FaThumbsDown with an img tag */}
        <img
          src={dislikeIcon}
          alt="Dislike"
          className="w-5 h-5" // Adjust the size as needed
        />
      </button>
       <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-black text-white text-sm rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap">
           {msg.disliked ? "Bad Response" : "Dislike "}
            </div>
                </div>
                  </div>
                    )}
                      </div>
                          ))}
  
            <div ref={messagesEndRef} />
            {loading && (
              <div className="text-center text-gray-500">
                Agent is Typing 💬...
              </div>
            )}
          </div>

          <div className="absolute px-4 pt-4 pb-1 border-t bg-white chat-bot-message-wrapper">
            <div className="chat-bot-message-send">
              <div className="flex relative">
                <input
                  type="text"
                  className="flex-1 p-2 border border-gray-300 rounded-l-lg focus:outline-none"
                  placeholder="Type a message..."
                  value={input}
                  ref={inputRef}
                  onChange={e => setInput(e.target.value)}
                  onKeyPress={e => {
                    if (e.key === 'Enter') handleSendMessage()
                  }}
                  disabled={loading}
                />
                <a
                  onClick={handleSendMessage}
                  className="chat-send-button"
                  disabled={loading}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" height="32px" viewBox="0 -960 960 960" width="32px" fill="currentColor"><path d="M140-190v-580l688.46 290L140-190Zm60-90 474-200-474-200v147.69L416.92-480 200-427.69V-280Zm0 0v-400 400Z"/></svg>
                </a>
              </div>
              </div>
          </div>
          </div>
        </div>
      ) : (
        <Outlet />
      )}

   {customizationModalOpen && (
  <div className="fixed inset-0 bg-gray-900 bg-opacity-75 flex justify-center items-center z-50">
    <div className="bg-white rounded-lg pt-0 w-11/12 md:w-3/4 lg:w-1/2 shadow-2xl py-2 relative overflow-hidden"
 style={{
  // maxHeight: "90vh",
  scrollbarWidth: "thin", // For Firefox
  scrollbarColor: "#A0AEC0 #EDF2F7", // Custom scrollbar colors for Firefox
}}>
      {/* Close Button */}
      <div className="flex items-center pt-2 pb-3 px-4 border-b justify-between modal-header">
      

      {/* Modal Title */}
      <h2 className="fs-20 mb-0 font-bold">
        Customize Your AI-Agent
      </h2>
      <a href="javascript:void(0)"
        onClick={handleCancelCustomizations}
        className=""
      >
       <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z"/></svg>
      </a>
      </div>

      {/* Chatbox Preview */}
      <div className="overflow-y-auto px-4 py-3 max-h-[75vh]">
        {/* <div
          className="p-6 rounded-lg shadow-inner bg-gray-100 border border-gray-300 mb-6"
          style={{
            backgroundImage: customizationData.agentImg
              ? `url(${URL.createObjectURL(customizationData.agentImg)})`
              : "none",
            backgroundColor: customizationData.agentImg
              ? "transparent"
              : customizationData.backgroundColor,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            fontFamily: customizationData.font,
          }}
        >
          <h3 className="text-lg font-semibold text-gray-700 text-center mb-4">
            Chatbox Preview
          </h3>
          <div className="space-y-4">
            
            <div className="flex">
              <div
                className="p-4 rounded-lg shadow-md max-w-md"
                style={{
                  backgroundColor: customizationData.agentColor,
                  fontFamily: customizationData.font,
                }}
              >
                <p className="text-white text-sm">Hello! How can I assist you today?</p>
              </div>
            </div>
            
            <div className="flex justify-end">
              <div
                className="p-4 rounded-lg shadow-md max-w-md"
                style={{
                  backgroundColor: customizationData.userColor,
                  fontFamily: customizationData.font,
                }}
              >
                <p className="text-white text-sm">I need help with my account.</p>
              </div>
            </div>
          </div>
        </div> */}

        {/* Customization Options */}
        <div className="space-y-8">
          {/* Color Selection */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {[
              { label: "Agent Color", value: "agentColor" },
              { label: "User Color", value: "userColor" },
              { label: "Background Color", value: "backgroundColor" },
            ].map((color, index) => (
              <div key={index} className="flex flex-col">
                <label className="text-gray-700 font-semibold mb-2">{color.label}</label>
                <div className="relative">
                <input
                  type="text"
                  value={customizationData[color.value]} 
                  onChange={(e) =>
                    handleCustomizationChange(color.value, e.target.value)
                  } 
                  disabled
                  className="w-full border input-color-settings-value border-gray-300 rounded-lg px-4 py-2"
                />
                <input
                  type="color"
                  value={customizationData[color.value]}
                  onChange={(e) =>
                    handleCustomizationChange(color.value, e.target.value)
                  }
                  className="input-color-settings"
                />
                </div>
              </div>
            ))}
            <div>
              <label className="block text-gray-700 font-semibold mb-2">Font</label>
              <select
                value={customizationData.font}
                onChange={(e) =>
                  handleCustomizationChange("font", e.target.value)
                }
                className="w-full border border-gray-300 rounded-lg px-4 py-2"
              >
                {[
                  "Arial",
                  "Verdana",
                  "Times New Roman",
                  "Courier New",
                  "Georgia",
                  "Tahoma",
                  "Trebuchet MS",
                  "Impact",
                ].map((font, idx) => (
                  <option key={idx} value={font}>
                    {font}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {/* Font and Agent Name */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-20">
            
            <div>
              <label className="block text-gray-700 font-semibold mb-2">Agent Name</label>
              <input
                type="text"
                value={customizationData.name}
                onChange={(e) =>
                  handleCustomizationChange("name", e.target.value)
                }
                className="w-full border border-gray-300 rounded-lg px-4 py-2"
                placeholder="Enter Agent Name"
              />
            </div>
            <div>
              <label className="block text-gray-700 font-semibold mb-2">Agent Image</label>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleImageChange(e.target.files[0])}
                className="w-full border border-gray-300 rounded-lg px-4 py-2"
              />
              {customizationData.agentImg && (
                <img
                  src={URL.createObjectURL(customizationData.agentImg)}
                  alt="Preview"
                  className="mt-4 w-24 h-24 hidden rounded-md object-cover"
                />
              )}
            </div>
          </div>

          {/* Agent Image and Welcome Message */}
          <div className="w-full mt-20">
            
            <div>
              <label className="block text-gray-700 font-semibold mb-2">
                Welcome Message
              </label>
              <textarea
                value={customizationData.welcomeMessage}
                onChange={(e) =>
                  handleCustomizationChange("welcomeMessage", e.target.value)
                }
                className="w-full border border-gray-300 rounded-lg px-4 py-2 h-24 resize-none"
                placeholder="Enter welcome message"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Save Button */}
      <div className="px-4 pb-2 flex gap-3 justify-end border-t pt-4">
      <button
           onClick={handleCancelCustomizations}
          className="px-4 py-2 bg-gray-200 btn-width-sm text-gray-800 rounded hover:bg-gray-300 transition-colors"
        >
          Cancel
        </button>
        <button
          onClick={handleSaveCustomizations}
          className="px-4 py-2 bg-[#6600ff] btn-width-sm text-white rounded hover:bg-[#5500cc] transition-colors"
        >
          Save
        </button>
      </div>
    </div>
  </div>
)}



    </div>
  )
}