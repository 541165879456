import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import AgentsTable from '../Components/AgentsTable';
import { FetchDataFromLocalStorage } from '../hooks/FetchDataFromLocalStorage';

export const Agents = () => {
  const [agents, setAgents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);

  const apiUrl = process.env.REACT_APP_API_URL;
  const userId = FetchDataFromLocalStorage('userId');
  const token = FetchDataFromLocalStorage('token');

  // Fetch agents data
  const fetchAgents = async () => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      const response = await axios.get(`${apiUrl}/agents`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          user_id: userId,
          page: currentPage,
        },
      });

      if (response.data.agents) {
        setAgents(response.data.agents);
        setTotalPages(response.data.totalPages || 1);
      }
    } catch (error) {
      console.error('Error fetching agents:', error);
      toast.error('Failed to fetch agents. Please try again.', {
        position: 'bottom-right',
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Initial fetch
  useEffect(() => {
    fetchAgents();
  }, [currentPage]);

  // Handle agent deletion
  const handleAgentDeleted = (agentId) => {
    setAgents((prevAgents) => prevAgents.filter((agent) => agent.uniqueId !== agentId));
    
    // If we're on a page with only one agent and it's not the first page,
    // go to the previous page
    if (agents.length === 1 && currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    } else {
      // Otherwise, just refresh the current page
      fetchAgents();
    }
  };

  // Handle agent update
  const handleAgentUpdated = (updatedAgent) => {
    setAgents((prevAgents) =>
      prevAgents.map((agent) =>
        agent.uniqueId === updatedAgent.uniqueId ? updatedAgent : agent
      )
    );
  };

  // Handle customization modal
  const handleCustomizationModal = (agent) => {
    setSelectedAgent(agent);
    setIsModalOpen(true);
  };

  // Handle training
  const handleTraining = async (agent) => {
    // Your existing training logic
  };

  // Handle pagination
  const handlePaginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Your Agents</h1>
        <button
          onClick={() => {/* Handle new agent creation */}}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
        >
          Create New Agent
        </button>
      </div>

      {isLoading && <div className="text-center py-4">Loading...</div>}

      {!isLoading && agents.length === 0 ? (
        <div className="text-center py-8">
          <p className="text-gray-600">No agents found. Create your first agent to get started!</p>
        </div>
      ) : (
        <AgentsTable
          currentChatbots={agents}
          openCustomizationModal={handleCustomizationModal}
          handleTraining={handleTraining}
          paginate={handlePaginate}
          currentPage={currentPage}
          totalPages={totalPages}
          onAgentDeleted={handleAgentDeleted}
          onAgentUpdated={handleAgentUpdated}
        />
      )}

      {/* Add your customization modal component here */}
      {isModalOpen && selectedAgent && (
        <div>
          {/* Your customization modal content */}
        </div>
      )}
    </div>
  );
};
