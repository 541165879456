import React, { useEffect, useState } from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom'

// Import Components
import { Navbar } from './Components/Chatbots/Navbar'
import { Login } from './Pages/Login'
import { MainPage } from './Components/Chatbots/MainPage'
import { Chatbots } from './Components/Chatbots/Chatbot'
import { Usage } from './Components/Chatbots/Usage'
import { Settings } from './Components/Chatbots/Settings'
import { Connect } from './Components/Chatbots/Connect'
import { Chat } from './Components/Chatbots/Chat'
import { Activity } from './Components/Chatbots/Activity'
import { Analysis } from './Components/Chatbots/Analysis'
import { Sources } from './Components/Chatbots/Sources'
import { FileUploadPage } from './Components/Chatbots/FileUploadPage'
import { TextUploadPage } from './Components/Chatbots/TextUploadPage'
import { WebsiteUploadPage } from './Components/Chatbots/WebsiteUploadPage'
import { Logout } from './Components/Chatbots/Logout'
import { Signup } from './Pages/Register'
import Table from './Components/Chatbots/Table'
import { CustomerForm } from './Components/Chatbots/CustomerForm'
import Loading from './Components/Chatbots/Loading'
import UserProfile from './Components/Chatbots/UserProfile'
import { ChatBoxIframe } from './Components/Iframes/ChatboxIframe'
import { NotFoundPage } from './Pages/NotFoundPage'
import MainContainer from './Components/Agents/MainContainer'
import { AuthProvider } from './contexts/authContext'
import { AgentFileUploadPage } from './Components/Chatbots/AgentFileUploadPage'
import { AgentTextUploadPage } from './Components/Chatbots/AgentTextUploadPage'
import { AgentWebsiteUploadPage } from './Components/Chatbots/AgentWebsiteUploadPage'
import { Agents } from './Pages/Agents'
import Dashboard from './Components/Chatbots/Dashboard'
import { SourcePage } from './Components/Chatbots/SourcePage'
import PaymentPage from './Components/Chatbots/PaymentPage'
import Blocked from './Components/Chatbots/Blocked'
import CookiePolicy from './Components/Chatbots/CookiePolicy'
import PayementDetails from './Components/Chatbots/PaymentDetails'
import './App.css'


// Access Denied Page Component
const AccessDenied = () => (
  <div style={{ textAlign: 'center', marginTop: '50px' }}>
    <h1>Access Denied</h1>
    <p>You do not have permission to view this page.</p>
  </div>
)

// Protected Route Component
const ProtectedRoute = ({ children }) => {
  const isAdmin = localStorage.getItem('isAdmin') === 'true'
  if (!isAdmin) {
    return <Navigate to="/access-denied" replace />
  }
  return children
}

// App Component
function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <MainApp />
      </AuthProvider>
    </BrowserRouter>
  )
}

// Main Application
function MainApp() {
  const location = useLocation()

  const isStaticFile = window.location.pathname.startsWith('/aiagent.min.js')

  if (isStaticFile) {
    // Let the browser handle static file requests
    return null
  }

  // Routes where Navbar should NOT be displayed
  const hideNavbarRoutes = [
    '/register',
    '/login',
    '/access-denied',
    '/notfound',
    '/agentlink',
    '/',
    '/agentmain',
    // '/agent',
  ]

  const showNavbar = !hideNavbarRoutes.some(route => {
    // Check for exact match OR prefix match with a trailing slash
    return (
      location.pathname === route || location.pathname.startsWith(route + '/')
    )
  })

  return (
    <div className="App h-screen">
      {showNavbar && <Navbar />}
      <Routes>
        {/* Public Routes */}
        <Route path="/register" element={<Signup />} />
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Signup />} />
        {/* Protected Routes */}
        <Route
          path="/admin-only"
          element={
            <ProtectedRoute>
              <Table />
            </ProtectedRoute>
          }
        />
        <Route path="/Activity" element={<Activity/>} />
        <Route path="/main" element={<MainPage />} />
        <Route path="/Cookiepolicy" element={<CookiePolicy />} />
        <Route path="/PaymentDetails" element={<PayementDetails/>} />
        <Route path="/agents" element={<Chatbots />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/paymentpage" element={<PaymentPage />} />
        <Route path="/agentmain" element={<MainContainer />} />
        <Route path="/blocked" element={<Blocked />} />
        {/* Chatbot-specific Routes */}
        <Route path="usage" element={<Usage />} />
        <Route path="settings" element={<Settings />} />
        <Route path="connect" element={<Connect />} />
        <Route path="/UserProfile/:customerId" element={<UserProfile />} />
        <Route path="/edit-profile" element={<CustomerForm />} />
        <Route path="/CustomerForm" element={<CustomerForm />} />
        <Route path="/edit-profile/:customerId" element={<CustomerForm />} />
        <Route path="files/:uuid/sources" element={<SourcePage />} />
        {/* <Route path="files/:uuid/files" element={<FileUploadPage />} /> */}
        <Route path="files/:uuid/files" element={<SourcePage />} />
        {/* <Route path="files/:uuid/text" element={<TextUploadPage />} /> */}
        {/* <Route path="files/:uuid/website" element={<WebsiteUploadPage />} /> */}
        <Route path="agent/:uuid/sources" element={<AgentFileUploadPage />} />
        <Route path="agent/:uuid/files" element={<AgentFileUploadPage />} />
        <Route path="agent/:uuid/text" element={<AgentTextUploadPage />} />
        <Route
          path="agent/:uuid/website"
          element={<AgentWebsiteUploadPage />}
        />
        <Route path="agentlink/:chatbotId" element={<ChatBoxIframe />} />
        <Route path="agentlink/*" element={<ChatBoxIframe />} />

        
        {/* Nested Chatbot Routes */}
        <Route path="/agent/:chatbotId" element={<Chat />}>
          <Route path="usage" element={<Usage />} />
          <Route path="settings" element={<Settings />} />
          <Route path="activity" element={<Activity />} />
          <Route path="connect" element={<Connect />} />
          <Route path="analysis" element={<Analysis />} />
          <Route path="/agent/:chatbotId/sources" element={<Sources />} />
        </Route>
        {/* Miscellaneous Routes */}
        <Route path="/table" element={<Table />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/loading" element={<Loading />} />
        <Route path="/access-denied" element={<AccessDenied />} />
        <Route path="/agents" element={<Agents />} />
        <Route path="/" element={<Navigate replace to="/agents" />} />
        {/* Catch-All Route */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  )
}

export default App
