import React, { useState } from 'react'
import { Link } from 'react-router-dom'
// import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios'
import { FetchDataFromLocalStorage } from '../hooks/FetchDataFromLocalStorage'
import { useLocation } from 'react-router-dom'
import aisaBackground from '../../src/img/2.png'
import userbg from '../../src/img/user.jpg'

// Confirmation Modal Component
const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm, agentName }) => {
  if (!isOpen) return null

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full mx-4">
        <h3 className="text-lg font-semibold mb-4">Confirm Delete</h3>
        <p className="mb-6">
          Are you sure you want to delete the agent "{agentName}"? This action
          cannot be undone.
        </p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  )
}

export default function AgentsTable({
  currentChatbots,
  openCustomizationModal,
  handleTraining,
  paginate,
  currentPage,
  totalPages,
  isSidebarOpen,
  onAgentDeleted, // New prop for handling agent deletion
  onAgentUpdated, // New prop for handling agent updates
  setModalOpen
}) {
  const apiUrl = process.env.REACT_APP_API_URL
  const userId = FetchDataFromLocalStorage('userId')
  const token = FetchDataFromLocalStorage('token')
  const uuid = FetchDataFromLocalStorage('current_uuid')
  const location = useLocation()

  const [deleteModal, setDeleteModal] = useState({ isOpen: false, agent: null })
  const [isLoading, setIsLoading] = useState(false)

  const handlePrevious = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1)
    }
  }

  const handleNext = () => {
    if (currentPage < totalPages) {
      paginate(currentPage + 1)
    }
  }

  const handleDeleteClick = agent => {
    setDeleteModal({ isOpen: true, agent })
  }

  const handleDeleteConfirm = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/deleteAgents`,
        {
          uuid: deleteModal.agent.uniqueId,
          deleteAgent: true,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )

      // Log the full response for debugging
      console.log(response, 'full response')

      // Check if the response indicates success
      if (response.data.message === 'Agent soft deleted successfully.') {
        // Notify the parent component to update the agent list
        onAgentDeleted(deleteModal.agent.uniqueId)
        toast.success(
          `${deleteModal.agent.name} has been deleted successfully.`,
        )
      } else {
        toast.error('Failed to delete the agent.')
      }

      setDeleteModal({ isOpen: false, agent: null })
    } catch (error) {
      console.error('Error deleting agent:', error)
      toast.error('Failed to delete the agent.')
    }
  }

  const handleAgentUpdate = async (agent, updates) => {
    if (isLoading) return
    setIsLoading(true)

    try {
      const response = await axios.put(
        `${apiUrl}/updateAgent`,
        {
          user_id: userId,
          uuid: agent.uniqueId,
          ...updates,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      )

      if (response.data.success) {
        toast.success('Agent updated successfully', {
          position: 'bottom-right',
          autoClose: 3000,
        })

        // Call the onAgentUpdated callback with the updated agent data
        if (onAgentUpdated) {
          onAgentUpdated({ ...agent, ...updates })
        }
      } else {
        throw new Error(response.data.message || 'Failed to update agent')
      }
    } catch (error) {
      console.error('Error updating agent:', error)
      toast.error(error.message || 'Failed to update agent', {
        position: 'bottom-right',
        autoClose: 3000,
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleLocalStorage = (chatbot) => {
    console.log(chatbot,"fdfd")
    localStorage.setItem('current_uuid', chatbot.uniqueId)
    localStorage.setItem('current_id', chatbot._id)
    localStorage.setItem('current_name', chatbot.name)
  }

  if (!Array.isArray(currentChatbots)) {
    return <p>No agents available.</p>
  }
  console.log(currentChatbots, 'currentChatbots')

  return (
    <>
      <DeleteConfirmationModal
        isOpen={deleteModal.isOpen}
        onClose={() => setDeleteModal({ isOpen: false, agent: null })}
        onConfirm={handleDeleteConfirm}
        agentName={deleteModal.agent?.name || 'this agent'}
      />

      <div
        className="space-y-6  relative rounded-lg hide-scrollbar"
        style={{
          // height:'500px',
          // backgroundColor: '#f0f0f0',
          // backgroundImage: `url(${require('../../src/img/2.png')})`,
          height: 'calc(100vh - 167px)',
          overflow: 'auto',
          paddingBottom: '20px',
          backgroundSize: 'cover', // Optional: Ensures the image covers the entire div
          backgroundPosition: 'center', // Optional: Centers the image
          backgroundRepeat: 'no-repeat',
          // opacity: 0.7, // Set image opacity
          zIndex: 2,
        }}
      >
        <div
          className="absolute top-0 left-0 w-full h-full rounded-lg"
          style={{
            opacity: 0.3,
            backdropFilter: 'blur(12px)',
            WebkitBackdropFilter: 'blur(8px)', // For Safari support
          }}
        ></div>

        {/* <br/> */}
        <div
          className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 mt-0 pt-0 justify-items-center"
          style={{
            gap: isSidebarOpen ? '20px' : '10px',
            transition: 'margin 0.3s ease-in-out',
            zIndex: 4,
          }}
        >
           <div  id="newagent"  
              onClick={() => setModalOpen(true)}                      
              className="agents-crearte-card w-full rounded-xl shadow-lg agent-card-hover transition-all duration-300 border border-gray-100 "
              style={{ zIndex: 4, borderRadius: '16px', cursor: 'pointer' }}
            >
              <div className="create-agent-card-item">
                <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 -960 960 960" width="30px" fill="currentColor"><path d="M450-450H220v-60h230v-230h60v230h230v60H510v230h-60v-230Z"/></svg>
                <h6>Create New Agent</h6>
              </div>
            </div>
          {currentChatbots.map(chatbot => (
            // console.log(chatbot,"chatbot")

            <div
              key={chatbot.uniqueId}
              className="bg-white w-full rounded-xl shadow-lg agent-card-hover transition-all duration-300 border border-gray-100 "
              style={{ zIndex: 4, borderRadius: '16px' }}
            >
              <Link
                to={
                  chatbot.collectionName
                    ? `/agent/${chatbot.uniqueId}`
                    : `/files/${chatbot.uniqueId}/sources`
                }
                onClick={() => handleLocalStorage(chatbot)}
                className="block text-center p-3 text-sm font-medium rounded-md transition"
              >
                <div className="flex flex-col">
                  <div className="relative items-center w-full flex align-center mt-1 justify-center">        
                    {/* <h4 className="text-black" style={{fontSize: '18px',fontWeight: '500'}}>  {chatbot.name || 'Unnamed Agent'}</h4>            */}
                    {/* <div className="agents-card-profile">
                      {chatbot.name.charAt(0)}
                    </div> */}
                    
                     <div className="agents-card-profile-img">
                        {/* <img src={userbg} alt="user-image"  class="agents-card-profile-img"/> */}
                        <svg xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 -960 960 960" width="30px" fill="currentColor"><path d="M480-492.31q-57.75 0-98.87-41.12Q340-574.56 340-632.31q0-57.75 41.13-98.87 41.12-41.13 98.87-41.13 57.75 0 98.87 41.13Q620-690.06 620-632.31q0 57.75-41.13 98.88-41.12 41.12-98.87 41.12ZM180-187.69v-88.93q0-29.38 15.96-54.42 15.96-25.04 42.66-38.5 59.3-29.07 119.65-43.61 60.35-14.54 121.73-14.54t121.73 14.54q60.35 14.54 119.65 43.61 26.7 13.46 42.66 38.5Q780-306 780-276.62v88.93H180Z"/></svg>
                      </div>
                  </div>    
                  <div className="flex align-center gap-1 justify-center mt-2 text-green-600 fw-bold" style={{alignItems: 'center' , fontSize: '12px'}}>
                    <div className="verified-bg-card flex align-center gap-1 justify-center "><svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 -960 960 960" width="16px" fill="currentColor"><path d="m352.46-85.39-71.38-120.3-135.54-29.7 13.23-139.53L66.93-480l91.84-105.08-13.23-139.53 135.54-29.7 71.38-120.3L480-820.46l127.54-54.15 71.38 120.3 135.54 29.7-13.23 139.53L893.07-480l-91.84 105.08 13.23 139.53-135.54 29.7-71.38 120.3L480-139.54 352.46-85.39ZM378-162l102-43.23L583.23-162 640-258l110-25.23L740-396l74-84-74-85.23L750-678l-110-24-58-96-102 43.23L376.77-798 320-702l-110 24 10 112.77L146-480l74 84-10 114 110 24 58 96Zm102-318Zm-42 128.15L650.15-564 608-607.38l-170 170-86-84.77L309.85-480 438-351.85Z"/></svg> Verified</div>
                    </div>                
                  {/* <Link
                to={chatbot.collectionName ? `/agent/${chatbot.uniqueId}` : `/files/${chatbot.uniqueId}/sources`}
                onClick={() => localStorage.setItem('current_uuid', chatbot.uniqueId)}
                className="block text-center py-2 px-4 text-sm font-medium rounded-md transition"
              > */}
                  <div className="flex items-center justify-between w-full mt-4">
                    <div className=" text-gray-600">Agent name</div>
                  <div className="">
                    {chatbot.name || 'Unnamed Agent'}
                  </div>
                  </div>
                  
                  {/* </Link> */}
                  <div className="flex items-center justify-between w-full mt-2 mb-3">
                  <div className="text-gray-600">Created</div>
                    <p className="text-sm text-truncate">
                    {new Date(chatbot.createdAt).toLocaleDateString()}
                    </p>
                  </div>
                  <div className="flex justify-between w-full">
                    {/* <button
                  className="hover:text-yellow-600 border-2 rounded-full p-2 text-xl cursor-pointer hover:bg-yellow-100"
                  onClick={() => openCustomizationModal(chatbot)}
                  disabled={isLoading}
                  aria-label="Settings"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor">
                    <path d="m370-80-16-128q-13-5-24.5-12T307-235l-119 50L78-375l103-78q-1-7-1-13.5v-27q0-6.5 1-13.5L78-585l110-190 119 50q11-8 23-15t24-12l16-128h220l16 128q13 5 24.5 12t22.5 15l119-50 110 190-103 78q1 7 1 13.5v27q0 6.5-2 13.5l103 78-110 190-118-50q-11 8-23 15t-24 12L590-80H370Zm70-80h79l14-106q31-8 57.5-23.5T639-327l99 41 39-68-86-65q5-14 7-29.5t2-31.5q0-16-2-31.5t-7-29.5l86-65-39-68-99 42q-22-23-48.5-38.5T533-694l-13-106h-79l-14 106q-31 8-57.5 23.5T321-633l-99-41-39 68 86 64q-5 15-7 30t-2 32q0 16 2 31t7 30l-86 65 39 68 99-42q22 23 48.5 38.5T427-266l13 106Zm42-180q58 0 99-41t41-99q0-58-41-99t-99-41q-59 0-99.5 41T342-480q0 58 40.5 99t99.5 41Zm-2-140Z"/>
                  </svg>
                </button> */}
                    {/* <button
                  className="hover:text-red-600 border-2 rounded-full p-2 text-xl cursor-pointer hover:bg-red-100"
                  onClick={() => handleDeleteClick(chatbot)}
                  disabled={isLoading}
                  aria-label="Delete"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="currentColor">
                    <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/>
                  </svg>
                </button> */}
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
        {/* {totalPages > 1 && (
          <div className="flex justify-between items-center p-4 bg-gray-50">
            <button
              onClick={handlePrevious}
              disabled={currentPage === 1 || isLoading}
              className={`inline-flex items-center px-4 py-2 rounded-md ${
                currentPage === 1 || isLoading
                  ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                  : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-300'
              } transition-colors duration-200`}
            >
              <FaArrowLeft className="mr-2" />
              <span>Previous</span>
            </button>
            <span className="text-gray-600 font-medium">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={handleNext}
              disabled={currentPage === totalPages || isLoading}
              className={`inline-flex items-center px-4 py-2 rounded-md ${
                currentPage === totalPages || isLoading
                  ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                  : 'bg-white text-gray-700 hover:bg-gray-50 border border-gray-300'
              } transition-colors duration-200`}
            >
              <span>Next</span>
              <FaArrowRight className="ml-2" />
            </button>
          </div>
        )} */}
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  )
}
