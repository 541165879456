import React, { useEffect, useState } from 'react'
import Card from './Card'
import StatsSection from './StatsSection'
import ProgressBar from './ProgressBar'
import Sidebar from './SideBar'
import BreadCrumb from './BreadCrumb'
import VisitorsBox from './VisitorsBox'
import RecentActivities from './RecentActivities'
import Announcements from './Announcements'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { FetchDataFromLocalStorage } from '../../hooks/FetchDataFromLocalStorage'
import {
  CoinsIcon,
  FileIcon,
  TrainIcon,
  UsageIcon,
  UserIcon,
} from '../../Svg icons/Svg'
import CookiePolicy from './CookiePolicy'

const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)
  const [agentDetail, setAgentDetail] = useState()

  const apiUrl = process.env.REACT_APP_API_URL

  const userId = FetchDataFromLocalStorage('userId')
  const token = FetchDataFromLocalStorage('token')

  //  const [breadcrumbName, setBreadcrumbName] = useState("Welcome");

  // const handleBreadcrumbNameUpdate = (name) => {
  //   setBreadcrumbName(name);
  // };

  useEffect(() => {
    const fetchUserDetails = async () => {
      // setIsLoading(true)
      try {
        const response = await axios.get(
          `${apiUrl}/user/${userId}`,

          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )

        console.log(response.data.data, 'dsd')
        setAgentDetail(response.data.data)
      } catch (error) {
        console.error('Error fetching fetch agent details:', error)
        alert('Failed to fetch agent details. Please try again.')
      } finally {
        // setIsLoading(false)
      }
    }

    fetchUserDetails()
  }, [userId])

  const stats = [
    { title: 'This Week', value: '1,982', isActive: true },
    { title: 'This Month', value: '5,463', isActive: false },
  ]

  const handleSidebarToggle = isOpen => {
    setIsSidebarOpen(isOpen)
  }

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.matchMedia('(max-width: 768px)').matches
      setIsSidebarOpen(!isMobile) // Close sidebar on mobile, open on larger screens
    }

    // Initialize state based on the initial screen size
    handleResize()

    // Add event listener for window resize
    window.addEventListener('resize', handleResize)

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      <Sidebar onToggle={handleSidebarToggle} />
      <CookiePolicy/>
      <div
  className="p-6 bg-gray-100 min-h-screen"
  style={{
    marginLeft: isSidebarOpen ? "250px" : "0",
    transition: "margin 0.3s ease-in-out",
  }}
>
  <BreadCrumb main="Dashboard" />

  {/* Top Cards Section */}
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-4 mb-4">
    <Card
      icon={<UserIcon />}
      value={agentDetail?.totalAgentCount || 0}
      label={<span className="text-md font-medium">Agents</span>}
    />
    <Card
      icon={<FileIcon />}
      value={agentDetail?.totalDocumentCount || 0}
      label={<span className="text-md font-medium">Documents</span>}
    />
    <Card
      icon={<CoinsIcon />}
      value={agentDetail?.totalMaterialsCount || 0}
      label={<span className="text-md font-medium">Total Materials</span>}
    />
    <Card
      icon={<TrainIcon />}
      value={agentDetail?.totalTrains || 0}
      label={<span className="text-md font-medium">Train</span>}
    />
  </div>

  {/* Middle Section */}
  <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
    {/* Left Column */}
    <div className="flex flex-col lg:col-span-2">
     
      <StatsSection stats={stats} />
      <ProgressBar progress={75} />
    </div>

    {/* Build Agent Section */}
    <Link to="/agents">
      <div className="bg-gradient-to-b from-[#6600ff] to-[#330099] border border-[#6600ff] hover:from-[#9900ff] hover:to-[#660099] shadow-lg rounded-xl p-3 flex flex-col items-center text-center transform transition-transform duration-300 hover:scale-105">
        <div className="text-2xl font-extrabold text-white mb-2">Build Agent</div>
        <h2 className="text-lg font-medium text-gray-200 mb-2">Current Agent</h2>
        <p className="text-lg text-gray-300">
          {agentDetail?.totalAgentCount || 0}
        </p>
      </div>
    </Link>
  </div>

  {/* Bottom Section */}
  <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mt-5">
    <VisitorsBox />
    <RecentActivities />
    <Announcements />
  </div>
</div>

    </>
  )
}

export default Dashboard
