import React from "react";
import { useLocation } from "react-router-dom";
import FileUploadCard from "./FileUploadCard";
import SourcesSummaryCard from "./SourcesSummaryCard";
import Header from "./Header";
import Sidebar from "./Sidebar";
import "./MainBodyContainer.css";
import TextUploadCard from "./TextUploadCard";
import WebsiteUploadCard from "./WebsiteUploadCard";
import { Chat } from "../Chatbots/Chat";
import ChatComponent from "../Chatbots/ChatComponent";
import Playground from "./Playground";
import { Usage } from "../Chatbots/Usage";

const MainContainer = () => {
  const location = useLocation();

  // Get the query param 'tabs' from the URL or default to 'playground'
  const params = new URLSearchParams(location.search);
  const activeTab = params.get('tabs') || 'playground';
  const sideTab = params.get('sideTab') || 'files';

  return (
    <div className="main-container">
      <Header />
      <div className="main-body-container">
      {activeTab === 'sources' && (
      
      
        <Sidebar />
      )}
        <div className="main-content">
          {/* Conditionally render content based on the active tab */}
          {activeTab === 'playground' && <Playground/>}
          {activeTab === 'activity' && <Usage/>}

          {activeTab === 'sources' && 
          <>
          {sideTab == 'files' && (
          <FileUploadCard />
        )}
          {sideTab == 'Text' && (
          <TextUploadCard />
        )}
          {sideTab == 'Website' && (
          <WebsiteUploadCard />
        )}
          <SourcesSummaryCard />
          </>}
          {/* Add more conditions here if you want to handle other tabs */}
        </div>
      </div>
    </div>
  );
};

export default MainContainer;
