import React from 'react';
import { useNavigate } from 'react-router-dom';

export const Logout = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        // Clear any stored user session or token (if using localStorage or sessionStorage)
        localStorage.removeItem("token");  // Adjust according to how you store the session/token

        // Redirect to login page
        navigate("/login");
    };

    return (
        <button
            onClick={handleLogout}
            className="text-white bg-red-500 rounded-md py-2 px-4 mt-4 hover:bg-red-600 transition-colors"
        >
            Logout
        </button>
    );
};


