import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { FilterIcon, FileExportIcon, ArrowLeftIcon, CommentsIcon, GroupUsersIcon } from "../../Svg icons/Svg";

export const Activity = () => {
  const [activeTab, setActiveTab] = useState('Chat Logs');
  const [exportOptionsVisible, setExportOptionsVisible] = useState(false);
  const exportRef = useRef(null);
  const navigate = useNavigate();
  const { chatbotId } = useParams();
  const location = useLocation();
  const messages = location.state?.messages || [];

  const handleBackClick = () => {
    navigate(`/agent/${chatbotId}`);
  };

  const handleExportClick = () => {
    setExportOptionsVisible(!exportOptionsVisible);
  };

  const exportAsJSON = () => {
    console.log('Exporting as JSON...');
  };

  const exportAsPDF = () => {
    console.log('Exporting as PDF...');
  };

  const exportAsCSV = () => {
    console.log('Exporting as CSV...');
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (exportRef.current && !exportRef.current.contains(event.target)) {
        setExportOptionsVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="container mx-auto py-8 px-4 lg:px-12">
      <div className="flex gap-6">
        {/* Sidebar */}
        <nav className="w-1/4 bg-white rounded-xl shadow-lg p-6">
          <ul className="space-y-4">
            <li
              className={`p-4 rounded-lg cursor-pointer transition ${
                activeTab === 'Chat Logs'
                  ? 'bg-indigo-100 text-indigo-700 font-bold'
                  : 'hover:bg-gray-100 text-gray-700'
              }`}
              onClick={() => setActiveTab('Chat Logs')}
            >
              <CommentsIcon className="inline-block mr-3" /> Chat Logs
            </li>
            <li
              className={`p-4 rounded-lg cursor-pointer transition ${
                activeTab === 'Leads'
                  ? 'bg-indigo-100 text-indigo-700 font-bold'
                  : 'hover:bg-gray-100 text-gray-700'
              }`}
              onClick={() => setActiveTab('Leads')}
            >
              <GroupUsersIcon className="inline-block mr-3" /> Leads
            </li>
          </ul>
        </nav>

        {/* Main Content */}
        <div className="flex-1 bg-white rounded-xl shadow-lg p-8">
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-2xl font-bold text-gray-800">{activeTab}</h1>
            <div className="flex items-center gap-6">
              <button
                className="flex items-center bg-gray-100 text-gray-700 px-4 py-2 rounded-lg shadow hover:bg-gray-200 transition-all"
                onClick={() => console.log('Filter clicked')}
              >
                <FilterIcon className="mr-2" />
                Filter
              </button>
              <div className="relative">
                <button
                  className="flex items-center bg-gray-100 text-gray-700 px-4 py-2 rounded-lg shadow hover:bg-gray-200 transition-all"
                  onClick={handleExportClick}
                >
                  <FileExportIcon className="mr-2" />
                  Export
                </button>
                {exportOptionsVisible && (
                  <div
                    ref={exportRef}
                    className="absolute right-0 mt-2 bg-white shadow-md rounded-lg w-40 text-gray-700 z-10"
                  >
                    <ul className="divide-y divide-gray-200">
                      <li
                        className="p-3 cursor-pointer hover:bg-gray-100 rounded-t-lg transition-all"
                        onClick={exportAsJSON}
                      >
                        JSON
                      </li>
                      <li
                        className="p-3 cursor-pointer hover:bg-gray-100 transition-all"
                        onClick={exportAsPDF}
                      >
                        PDF
                      </li>
                      <li
                        className="p-3 cursor-pointer hover:bg-gray-100 rounded-b-lg transition-all"
                        onClick={exportAsCSV}
                      >
                        CSV
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="space-y-6">
            {activeTab === 'Chat Logs' && (
              <div className="flex flex-col space-y-4">
                {messages.length > 0 ? (
                  messages.map((msg, index) => (
                    <div
                      key={index}
                      className={`flex ${
                        msg.sender === 'User' ? 'justify-end' : 'justify-start'
                      }`}
                    >
                      <div
                        className={`max-w-[70%] p-4 rounded-lg shadow-md ${
                          msg.sender === 'User'
                            ? 'bg-indigo-500 text-white'
                            : 'bg-gray-100 text-gray-800'
                        }`}
                      >
                        <strong>{msg.sender}:</strong> {msg.text}
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="text-gray-500 text-center">No messages available.</p>
                )}
              </div>
            )}

            {activeTab === 'Leads' && (
              <div>
                <p className="text-gray-500 text-center">Leads feature is under development.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};