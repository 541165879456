import { useState, useRef, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import aisaXLogo from '../../img/aisaXLogo.png';
import userIcon from '../../img/usericon.png';
import { useAuth } from '../../contexts/authContext';

export const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Sidebar state
  const isAdmin = localStorage.getItem('isAdmin'); // Check if user is admin
   const [isLogoHovered, setIsLogoHovered] = useState(false)
  const clientUrl = process.env.REACT_APP_URL;

  const { user, error, login, logout, subscriptionStatus, checkToken } = useAuth();

  // Ref for the mobile menu
  const mobileMenuRef = useRef(null);

  // Close mobile menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target)) {
        setIsMobileMenuOpen(false);
      }
    };

    // Add event listener when the mobile menu is open
    if (isMobileMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobileMenuOpen]);

  // Hide navbar on specific routes
  const hideNavbarRoutes = ['/register', '/login', '/accessdenied', '/notfound'];
  const isNavbarHidden = hideNavbarRoutes.some(route => location.pathname.startsWith(route));
  if (isNavbarHidden) return null;

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = `${clientUrl}/`;
  };

  const isActive = path => location.pathname === path;

  // Toggle sidebar
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Handle sidebar toggle
  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
  };

  return (
    <nav className="w-full md:hidden border-b bg-[#000000] text-white py-1">
      <div className="flex items-center justify-between h-14 px-6">
        {/* Left: AiSA-X Logo */}
        <div
                className={`relative bg-white p-4 rounded-full shadow-2xl transform transition-all duration-300 hover:scale-110 ${
                  isLogoHovered ? 'animate-spin' : ''
                }`}
                // onMouseEnter={() => setIsLogoHovered(true)}
                // onMouseLeave={() => setIsLogoHovered(false)}
              >
      
      <img
    src={aisaXLogo}
    alt="AiSA-X Logo"
    className="w-18 h-2 object-contain md:w-12 md:h-8 lg:w-12 lg:h-12 transform scale-150 hover:scale-150 transition-transform duration-300"
  />

  {/* Beta Badge */}
  <div className="absolute -bottom-1 -right-4 bg-[#6600ff] text-white text-xs font-bold px-1 py-0 rounded-full shadow-md md:text-sm lg:text-sm">
    Beta
  </div>
              </div>

        {/* Center: Hamburger Menu for Mobile */}
        <div className="md:hidden">
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="text-gray-700 focus:outline-none p-2"
          >
            <svg
              className="w-8 h-8"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>

      </div>

    {/* Mobile Menu */}
{isMobileMenuOpen && (
  <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50">
    {/* Sliding Menu */}
    <div
      ref={mobileMenuRef}
      className="absolute top-0 left-0 w-full bg-white shadow-lg z-50 animate-slide-down"
      style={{ maxHeight: '400px', overflowY: 'auto' }}
    >
      <ul className="flex flex-col items-center py-4">
        {/* Home Link */}
        <li className="w-full">
          <Link
            to="/dashboard"
            className={`flex justify-start items-center w-full px-6 py-3 text-gray-700 text-md hover:bg-purple-600 hover:font-bold hover:text-white transition`}
            onClick={() => setIsMobileMenuOpen(false)}
          >
            <span className="text-center w-full">Home</span> {/* Centered text */}
          </Link>
        </li>

        {/* Agents Link */}
        <li className="w-full">
          <Link
            to="/agents"
            className={`flex justify-start items-center w-full px-6 py-3 text-gray-700 text-md hover:bg-purple-600 hover:font-bold hover:text-white transition`}
            onClick={() => setIsMobileMenuOpen(false)}
          >
            <span className="text-center w-full">Agents</span> {/* Centered text */}
          </Link>
        </li>

        {/* Usage Link */}
        <li className="w-full">
          <Link
            to="/usage"
            className={`flex justify-start items-center w-full px-6 py-3 text-gray-700 text-md hover:bg-purple-600 hover:font-bold hover:text-white transition`}
            onClick={() => setIsMobileMenuOpen(false)}
          >
            <span className="text-center w-full">Usage</span> {/* Centered text */}
          </Link>
        </li>

        {/* Settings Link */}
        <li className="w-full">
          <Link
            to="/settings"
            className={`flex justify-start items-center w-full px-6 py-3 text-gray-700 text-md hover:bg-purple-600 hover:font-bold hover:text-white transition`}
            onClick={() => setIsMobileMenuOpen(false)}
          >
            <span className="text-center  pl-2 w-full">Settings</span> {/* Centered text */}
          </Link>
        </li>

        {/* Payment Link */}
        <li className="w-full">
          <Link
            to="/paymentpage"
            className={`flex justify-start items-center w-full px-6 py-3 text-gray-700 text-md hover:bg-purple-600 hover:font-bold hover:text-white transition`}
            onClick={() => setIsMobileMenuOpen(false)}
          >
            <span className="text-center pl-2 w-full">Payment</span> {/* Centered text */}
          </Link>
        </li>

        {/* Profile Link */}
        <li className="w-full">
          <Link
            to="/UserProfile"
            className={`flex justify-start items-center w-full px-6 py-3 text-gray-700 text-md hover:bg-purple-600 hover:font-bold hover:text-white transition`}
            onClick={() => setIsMobileMenuOpen(false)}
          >
            <span className="text-center w-full">Profile</span> {/* Centered text */}
          </Link>
        </li>

        {/* Logout Button */}
        <li className="w-full">
          <button
            onClick={handleLogout}
            className={`flex justify-start items-center w-full px-6 py-3 text-red-600 text-md hover:bg-red-100 hover:font-bold hover:text-red-600 transition`}
          >
            <span className="text-center w-full">Logout</span> {/* Centered text */}
          </button>
        </li>
      </ul>
    </div>
  </div>
)}
      {/* Backdrop to close mobile menu on outside click */}
      {isMobileMenuOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-25 z-40 lg:hidden"
          onClick={() => setIsMobileMenuOpen(false)}
        ></div>
      )}
    </nav>
  );
};

