import React, { useEffect, useState } from 'react';
import { FetchDataFromLocalStorage } from '../../hooks/FetchDataFromLocalStorage';
import axios from 'axios';
import { toast } from 'react-toastify';

const CookiePolicy = () => {
    const [showBanner, setShowBanner] = useState(false);
  const userId = FetchDataFromLocalStorage('userId')
  const token = FetchDataFromLocalStorage('token')
  const apiUrl = process.env.REACT_APP_API_URL
  const modelUrl = process.env.REACT_APP_AI_AGENT_URL






    // useEffect(() => {
    //     // Check if userId exists; if not, generate and store one
    //     if (!userId) {
    //         userId = `user-${Date.now()}`;
    //         localStorage.setItem('userId', userId); // Store userId in localStorage
    //     }

    //     // Check if cookies are not accepted and userId exists
    //     const cookiePolicy = localStorage.getItem('cookiePolicy');
    //     if (userId && cookiePolicy !== 'true') {
    //         setShowBanner(true); // Show the banner
    //     }
    // }, []);

    useEffect(() => {
        const fetchUserDetails = async () => {
          // setIsLoading(true)
          try {
            const response = await axios.get(
              `${apiUrl}/user/${userId}`,
    
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            )
    
            if(response.data.data.cookiePolicy){
                setShowBanner(false);

            }else{
                setShowBanner(true);

            }
            // setAgentDetail(response.data.data)
          } catch (error) {
            console.error('Error fetching fetch agent details:', error)
            alert('Failed to fetch agent details. Please try again.')
          } finally {
            // setIsLoading(false)
          }
        }
    
        fetchUserDetails()
      }, [userId])

    const handleAcceptCookies = async() => {

        try {
            const response = await axios.post(
              `${apiUrl}/save-cookie-policy`,
              {
                userId: userId,
                // deleteAgent: true,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            )
      
            // Log the full response for debugging
            console.log(response, 'full response')
      
            // Check if the response indicates success
            if (response.data.message === 'Agent soft deleted successfully.') {
      


      
      
      
              // Notify the parent component to update the agent list
              // onAgentDeleted(deleteModal.agent.uniqueId);
              toast.success(
                `Cookie policy has been saved successfully.`,
              )
            } else {
              toast.error('Failed to save Cookie policy .')
            }
      
          } catch (error) {
            console.error('Error Cookie policy:', error)
            toast.error('Failed to Cookie policy.')
          }

        // localStorage.setItem('cookiePolicy', 'true'); // Set cookie policy acceptance in localStorage
        // /save-cookie-policy
        setShowBanner(false); // Hide the banner
    };

    return (
        <>
            {showBanner && (
                <div style={styles.banner}>
                    <p style={styles.text}>
                        🍪 We use cookies to improve your experience. By using this site, you agree to our{' '}
                        <a href="/cookie-policy" style={styles.link}>cookie policy</a>.
                    </p>
                    <button onClick={handleAcceptCookies} style={styles.button}>
                        Accept
                    </button>
                </div>
            )}
        </>
    );
};

const styles = {
    banner: {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        backgroundColor: '#2c3e50',
        color: '#ecf0f1',
        padding: '15px',
        textAlign: 'center',
        boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.3)',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: 1000,
    },
    text: {
        margin: 0,
        fontSize: '14px',
        flex: 1,
        textAlign: 'left',
        paddingLeft: '20px',
    },
    link: {
        color: '#1abc9c',
        textDecoration: 'underline',
    },
    button: {
        backgroundColor: '#1abc9c',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        padding: '10px 20px',
        cursor: 'pointer',
        fontSize: '14px',
        marginRight: '20px',
    },
};

export default CookiePolicy;
