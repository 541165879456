import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import './CustomHeader.css'
import aisaXLogo from '../../img/aisaXLogo.png'
import axios from 'axios'
import { FetchDataFromLocalStorage } from '../../hooks/FetchDataFromLocalStorage'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm, agentName }) => {
  if (!isOpen) return null

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full mx-4">
        <h3 className="text-lg font-semibold mb-4">Confirm Delete</h3>
        <p className="mb-6">
          Are you sure you want to delete the agent "{agentName}"? This action
          cannot be undone.
        </p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={onConfirm}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  )
}
const Header = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [menuOpen, setMenuOpen] = useState(false)
  const [deleteModal, setDeleteModal] = useState({ isOpen: false, agent: null })

  const apiUrl = process.env.REACT_APP_API_URL
  const modelUrl = process.env.REACT_APP_AI_AGENT_URL

  const userId = FetchDataFromLocalStorage('userId')
  const uuid = FetchDataFromLocalStorage('current_uuid')
  const agent_name = FetchDataFromLocalStorage('current_name')


  const token = FetchDataFromLocalStorage('token')

  const handleDeleteClick = agent => {
    setDeleteModal({ isOpen: true, agent })
  }

  const handleDeleteConfirm = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/deleteAgents`,
        {
          uuid: uuid,
          deleteAgent: true,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )

      // Log the full response for debugging
      console.log(response, 'full response')

      // Check if the response indicates success
      if (response.data.message === 'Agent soft deleted successfully.') {

        const QuadrantResponse = await axios.post(
          `${modelUrl}/remove-embeddings?user_id=${userId}&agent_id=${uuid}`,
          // {
          //   agent_id: uuid,
          //   user_id: userId,
          // },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        )

        if(QuadrantResponse){
          console.log('Quadrant response:', QuadrantResponse)
        }

        navigate(`/agents`)


        // Notify the parent component to update the agent list
        // onAgentDeleted(deleteModal.agent.uniqueId);
        toast.success(
          `${deleteModal.agent.name} has been deleted successfully.`,
        )
      } else {
        toast.error('Failed to delete the agent.')
      }

      setDeleteModal({ isOpen: false, agent: null })
    } catch (error) {
      console.error('Error deleting agent:', error)
      toast.error('Failed to delete the agent.')
    }
  }

  // Get the query param 'tabs' from the URL or default to 'playground'
  const params = new URLSearchParams(location.search)
  const activeTab = params.get('tabs') || 'playground'

  const handleTabClick = tab => {
    navigate(`?tabs=${tab}`)
    setMenuOpen(false) // Close menu on tab selection
  }

  return (
    <header className="custom-header    "
    // style={{
    //   marginLeft: isSidebarOpen ? '250px' : '0',
    //   transition: 'margin 0.3s ease-in-out',
    // }}
    >
      <DeleteConfirmationModal
        isOpen={deleteModal.isOpen}
        onClose={() => setDeleteModal({ isOpen: false, agent: null })}
        onConfirm={handleDeleteConfirm}
        agentName={agent_name || 'this agent'}
      />
      {/* <div className="custom-header-left "> */}
        {/* <a href="https://agents.aisa-x.ai/agents">
          <img src={aisaXLogo} alt="AiSA-X Logo" className="logo" />
        </a> */}
        {/* <div className="custom-header-title">
          <span>Gautam Wise</span> <span className="custom-divider">/</span>{" "}
          <select className="select-dropdown">
            <option value="">All Agents</option>
            <option value="1">Agent 1</option>
          </select>
        </div> */}
      {/* </div> */}

      <nav className={`custom-header-nav desktop-nav`}>
        <span
          className={`nav-item ${activeTab === 'playground' ? 'active' : ''}`}
          onClick={() => handleTabClick('playground')}
        >  
          <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M852.31-133.85 710.77-275.39H320q-29.19 0-49.98-20.79-20.79-20.78-20.79-49.98v-24.61h435.39q29.82 0 51.06-21.24 21.24-21.24 21.24-51.07V-720h24.62q29.19 0 49.98 20.79 20.79 20.79 20.79 49.98v515.38Zm-684.62-320.3 56.62-56.62h380.31q5.38 0 8.84-3.46t3.46-8.85v-264.61q0-5.39-3.46-8.85t-8.84-3.46H180q-5.38 0-8.85 3.46-3.46 3.46-3.46 8.85v333.54Zm-60 144.92v-478.46q0-29.83 21.24-51.07Q150.17-860 180-860h424.62q29.82 0 51.06 21.24 21.24 21.24 21.24 51.07v264.61q0 29.83-21.24 51.07-21.24 21.24-51.06 21.24H249.23L107.69-309.23Zm60-213.85V-800v276.92Z"/></svg>
          Playground
          {activeTab === 'playground' && <div className="active-indicator" />}
        </span>
        {/* <span
          className={`nav-item ${activeTab === "activity" ? "active" : ""}`}
          onClick={() => handleTabClick("activity")}
        >
          Activity
          {activeTab === "activity" && (
            <div className="active-indicator" />
          )}
        </span> */}
        {/* <span
          className={`nav-item ${activeTab === "analytics" ? "active" : ""}`}
          onClick={() => handleTabClick("analytics")}
        >
          Analytics
          {activeTab === "analytics" && (
            <div className="active-indicator" />
          )}
        </span> */}
        <span
          className={`nav-item ${activeTab === 'sources' ? 'active' : ''}`}
          onClick={() => handleTabClick('sources')}
        >
          <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M344-350.23 214.23-480 344-609.77 385.77-567l-87 87 87 87L344-350.23Zm272 0L574.23-393l87-87-87-87L616-609.77 745.77-480 616-350.23ZM212.31-140Q182-140 161-161q-21-21-21-51.31V-360h60v147.69q0 4.62 3.85 8.46 3.84 3.85 8.46 3.85H360v60H212.31ZM600-140v-60h147.69q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46V-360h60v147.69Q820-182 799-161q-21 21-51.31 21H600ZM140-600v-147.69Q140-778 161-799q21-21 51.31-21H360v60H212.31q-4.62 0-8.46 3.85-3.85 3.84-3.85 8.46V-600h-60Zm620 0v-147.69q0-4.62-3.85-8.46-3.84-3.85-8.46-3.85H600v-60h147.69Q778-820 799-799q21 21 21 51.31V-600h-60Z"/></svg>
          Sources
          {activeTab === 'sources' && <div className="active-indicator" />}
        </span>

        <span
          className={`nav-item ${activeTab === 'connect' ? 'active' : ''}`}
          onClick={() => handleTabClick('connect')}
        >
          <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M640.22-116q-44.91 0-76.26-31.41-31.34-31.41-31.34-76.28 0-9 5.15-30.16L297.31-402.31q-14.46 14-33.41 22-18.94 8-40.59 8-44.71 0-76.01-31.54Q116-435.39 116-480q0-44.61 31.3-76.15 31.3-31.54 76.01-31.54 21.74 0 40.64 8 18.9 8 33.36 22l240.46-148.08q-2.38-7.38-3.77-14.77-1.38-7.39-1.38-15.77 0-44.87 31.43-76.28Q595.49-844 640.4-844t76.25 31.44Q748-781.13 748-736.22q0 44.91-31.41 76.26-31.41 31.34-76.28 31.34-21.85 0-40.5-8.19Q581.15-645 566.69-659L326.23-510.54q2.38 7.39 3.77 14.77 1.38 7.39 1.38 15.77 0 8.38-1.38 15.77-1.39 7.38-3.77 14.77L566.69-301q14.46-14 33.16-22.19 18.7-8.19 40.46-8.19 44.87 0 76.28 31.43Q748-268.51 748-223.6t-31.44 76.25Q685.13-116 640.22-116Zm.09-52q23.67 0 39.68-16.01Q696-200.02 696-223.69q0-23.67-16.01-39.68-16.01-16.02-39.68-16.02-23.67 0-39.68 16.02-16.02 16.01-16.02 39.68 0 23.67 16.02 39.68Q616.64-168 640.31-168Zm-417-256.31q23.83 0 39.95-16.01 16.13-16.01 16.13-39.68 0-23.67-16.13-39.68-16.12-16.01-39.95-16.01-23.51 0-39.41 16.01Q168-503.67 168-480q0 23.67 15.9 39.68 15.9 16.01 39.41 16.01Zm417-256.3q23.67 0 39.68-16.02Q696-712.64 696-736.31q0-23.67-16.01-39.68Q663.98-792 640.31-792q-23.67 0-39.68 16.01-16.02 16.01-16.02 39.68 0 23.67 16.02 39.68 16.01 16.02 39.68 16.02Zm0 456.92ZM223.69-480Zm416.62-256.31Z"/></svg>
          Connect
          {activeTab === 'connect' && <div className="active-indicator" />}
        </span>

        <span
          className={`nav-item ${activeTab === 'usage' ? 'active' : ''}`}
          onClick={() => handleTabClick('usage')}
        >
          <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M480-118q-74.77 0-141.11-28.46-66.35-28.46-116.16-78.08-49.81-49.61-78.27-115.96Q116-406.85 116-482q0-141 93.39-244.11Q302.77-829.23 444-844v60.61q-115.46 15.16-191.42 101.16-75.97 86-75.97 200.23 0 126.54 88.43 214.96 88.42 88.43 214.96 88.43 75.62 0 138.85-33.7 63.23-33.69 104-88.77L776.08-270q-51.85 70.54-128.35 111.27Q571.23-118 480-118Zm331.31-214-53.23-30.31q11.77-25.61 18.54-56.61t6.77-63.08q0-115.39-76.74-201.19Q629.92-769 516-783.39V-844q138.54 12.85 233.27 116.54Q844-623.77 844-482q0 40.69-8.62 78.19-8.61 37.5-24.07 71.81Z"/></svg>
          Usage
          {activeTab === 'usage' && <div className="active-indicator" />}
        </span>
        {/* <span
          className={`nav-item ${activeTab === "usage" ? "active" : ""}`}
          onClick={() => handleTabClick("usage")}
        >
          Usage
          {activeTab === "usage" && (
            <div className="active-indicator" />
          )}
        </span> */}
        {/* <span className="nav-item">Usage <span className="new-tag">New</span></span> */}
        {/* <span className="nav-item">Connect</span> */}
        {/* <span className="nav-item">Settings</span> */}

        <span
          className="nav-item"        
          onClick={handleDeleteClick}
        >
          <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="currentColor"><path d="M292.31-140q-29.92 0-51.12-21.19Q220-182.39 220-212.31V-720h-40v-60h180v-35.38h240V-780h180v60h-40v507.69Q740-182 719-161q-21 21-51.31 21H292.31ZM680-720H280v507.69q0 5.39 3.46 8.85t8.85 3.46h375.38q4.62 0 8.46-3.85 3.85-3.84 3.85-8.46V-720ZM376.16-280h59.99v-360h-59.99v360Zm147.69 0h59.99v-360h-59.99v360ZM280-720v520-520Z"></path></svg>
          Delete
        </span>

        {/* <span className="nav-item">Changelog</span>
        <span className="nav-item">Help</span> */}
      </nav>

      <div className="custom-header-right">
        <button
          className="group h-10 w-10 rounded-lg border-2 border-black hamburger-btn"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <div className="grid justify-items-center gap-1.5">
            <span
              className={`h-1 w-8 rounded-full bg-black transition-transform ${
                menuOpen ? 'rotate-45 translate-y-2.5' : ''
              }`}
            ></span>
            <span
              className={`h-1 w-8 rounded-full bg-black transition-transform ${
                menuOpen ? 'scale-x-0' : ''
              }`}
            ></span>
            <span
              className={`h-1 w-8 rounded-full bg-black transition-transform ${
                menuOpen ? '-rotate-45 -translate-y-2.5' : ''
              }`}
            ></span>
          </div>
        </button>
        {/* <img
          src="https://via.placeholder.com/32"
          alt="Profile"
          className="profile-pic"
        /> */}
      </div>

      {menuOpen && (
        <div
          className="overlay"
          onClick={() => setMenuOpen(false)} // Close menu on overlay click
        >
          <div
            className="slide-in-panel"
            onClick={e => e.stopPropagation()} // Prevent closing when clicking inside the panel
          >
            <nav className="custom-header-nav mobile-nav">
              <span
                className={`nav-item ${
                  activeTab === 'playground' ? 'active' : ''
                }`}
                onClick={() => handleTabClick('playground')}
              >
                Playground
              </span>
              {/* <span
                className={`nav-item ${activeTab === "activity" ? "active" : ""}`}
                onClick={() => handleTabClick("activity")}
              >
                Activity
              </span>
              <span
                className={`nav-item ${activeTab === "analytics" ? "active" : ""}`}
                onClick={() => handleTabClick("analytics")}
              >
                Analytics
              </span> */}
              <span
                className={`nav-item ${
                  activeTab === 'sources' ? 'active' : ''
                }`}
                onClick={() => handleTabClick('sources')}
              >
                Sources
              </span>
              <span
                className={`nav-item ${
                  activeTab === 'connect' ? 'active' : ''
                }`}
                onClick={() => handleTabClick('connect')}
              >
                Connect
              </span>
              {/* <span className="nav-item">Actions <span className="new-tag">New</span></span> */}
              {/* <span className="nav-item">Connect</span> */}
              {/* <span className="nav-item">Settings</span> */}
              {/* <span className="nav-item">Changelog</span> */}
              {/* <span className="nav-item">Help</span> */}
            </nav>
          </div>
        </div>
      )}
    </header>
  )
}

export default Header
