// import React, { useState, useEffect } from "react";

// // Import images
// import img from "../../img/createagent.png";
// import img2 from "../../img/Aistep-2.png";
// import img3 from "../../img/Customization.png";
// import img4 from "../../img/uploadfile.png";

// const StepModal = ({ isVisible, onClose }) => {
//   const [currentStep, setCurrentStep] = useState(1);

//   useEffect(() => {
//     if (!localStorage.getItem("firstVisit") && isVisible) {
//       localStorage.setItem("firstVisit", "true");
//     }
//   }, [isVisible]);

//   const handleNext = () => {
//     if (currentStep < 4) setCurrentStep(currentStep + 1);
//   };

//   const handleBack = () => {
//     if (currentStep > 1) setCurrentStep(currentStep - 1);
//   };

//   const handleFinish = () => {
//     console.log("Ok. Completed");
//     onClose();
//   };

//   const renderStepContent = () => {
//     switch (currentStep) {
//       case 1:
//         return (
//           <div className="flex flex-col items-center text-center">
//             <img
//               src={img}
//               alt="Step 1"
//               className="mb-6 w-3/4 h-auto shadow-lg rounded-lg"
//             />
//             <p className="text-lg text-gray-700 font-semibold mb-4">
//               Welcome to AI-Agent. This is Step 1.
//             </p>
//           </div>
//         );

//       case 2:
//         return (
//           <div className="flex flex-col items-center text-center">
//             <img
//               src={img2}
//               alt="Step 2"
//               className="mb-6 w-3/4 h-auto shadow-lg rounded-lg"
//             />
//             <p className="text-lg text-gray-700 font-semibold mb-4">
//               This is Step 2.Your Ai-Agent here .
//             </p>
//           </div>
//         );

//       case 3:
//         return (
//           <div className="flex flex-col items-center text-center">
//             <img
//               src={img3}
//               alt="Step 3"
//               className="mb-6 w-3/4 h-auto shadow-lg rounded-lg"
//             />
//             <p className="text-lg text-gray-700 font-semibold mb-4">
//               Step 3: Customization the Ai-agent .
//             </p>
//           </div>
//         );

//       case 4:
//         return (
//           <div className="flex flex-col items-center text-center">
//             <img
//               src={img4}
//               alt="Step 4"
//               className="mb-6 w-3/4 h-auto shadow-lg rounded-lg"
//             />
//             <p className="text-lg text-gray-700 font-semibold mb-4">
//               Click the Your Agent & Open the file upload page.
//             </p>
//           </div>
//         );

//       default:
//         return null;
//     }
//   };

//   if (!isVisible) return null;

//   return (
//     <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
//       <div className="bg-white p-8 rounded-lg w-11/12 sm:w-3/4 md:w-1/2 lg:w-1/3 shadow-lg max-h-[80vh] overflow-y-auto transform transition-transform duration-300 hover:scale-105">
//         <h2 className="text-2xl font-semibold mb-6 text-center text-indigo-600">
//           Step {currentStep} of 4
//         </h2>
//         {renderStepContent()}
//         <div className="flex justify-between mt-6">
//           <button
//             onClick={handleBack}
//             disabled={currentStep === 1}
//             className="bg-gray-300 text-gray-800 py-2 px-4 rounded-lg disabled:opacity-50"
//           >
//             Back
//           </button>

//           {currentStep === 4 ? (
//             <button
//               onClick={handleFinish}
//               className="bg-green-500 text-white py-2 px-6 rounded-lg shadow-md hover:bg-green-600"
//             >
//               Finish
//             </button>
//           ) : (
//             <button
//               onClick={handleNext}
//               className="bg-blue-500 text-white py-2 px-6 rounded-lg shadow-md hover:bg-blue-600"
//             >
//               Next
//             </button>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default StepModal;


import { data } from "@remix-run/router";
import { useEffect, useState } from "react"
import JoyrideDemo from "react-joyride";

export default function JoyrideDemoo(){
const[isJoyrideActive,setisJoyrideactive]=useState(false);
  
  const steps =[
    // {
    //   target: "#newagent",
    //   content:"click here to create an agent"
      
    // },

    {
      target: "#newchatbot",
      content:"Click here to add files into the agent "
      
    },
    {
      target: "#setting",
      content:"click here to customise the agent"
      
    },
    {
      target: "#delete",
      content:"click here to delete the agent"
      
    },
  ]

  useEffect(()=>{
    const tourComplete=localStorage.getItem("joyride complete");

    if(!tourComplete){
      setisJoyrideactive(true);
    }
  },[])
const handleJoyrideCallback =(data)=>{
  const {status}=data;
  if( status === "finshed" || status==="skipped"){
    localStorage.setItem("joyridecomplte", true)
    setisJoyrideactive(false)
  }
}

return (
  <div>
    {isJoyrideActive && (
      <JoyrideDemo
        steps={steps}
        continuous={true}
        callback={handleJoyrideCallback} 
      />
    )}
  </div>
);
}