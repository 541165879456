import React, { useEffect, useState } from 'react'
import AccessDeniedImage from '../img/loadingicon.gif' // Ensure the file exists in src/img

export const AccessDenied = () => {
  const messages = ['Sorry', 'Try again']
  const [currentMessage, setCurrentMessage] = useState(messages[0])

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessage(prevMessage => {
        const currentIndex = messages.indexOf(prevMessage)
        return messages[(currentIndex + 1) % messages.length]
      })
    }, 2000)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className="flex flex-col items-center min-h-screen bg-gray-900 text-white">
      {/* Access Denied message */}
      <h1 className="text-4xl font-bold text-red-500 mt-6">Access Denied</h1>

      {/* Loading icon */}
      <div className="relative w-40 h-40 mt-10">
        <img
          src={AccessDeniedImage}
          alt="Access Denied"
          className="w-full h-full"
        />
      </div>

      {/* Rotating messages */}
      <p className="mt-4 text-lg font-medium">{currentMessage}</p>

      <button>
        <a href="/login" className="text-blue-500 hover:text-blue-700">
          login
        </a>
      </button>
    </div>
  )
}
