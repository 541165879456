import React from 'react';

const VisitorsBox = () => {
  return (
    <div className="bg-white shadow-md rounded-lg p-4">
      <h2 className="text-lg font-semibold mb-2">Visitors</h2>
      <p className="text-md text-gray-600">Current Visitors: <span className="text-sm ext-purple-600 font-bold">231</span></p>
      <p className="text-md text-gray-600">Total Visitors Today: <span className="text-sm text-purple-600 font-bold">1,534</span></p>
    </div>
  );
};

export default VisitorsBox;
