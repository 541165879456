import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { FetchDataFromLocalStorage } from "../../hooks/FetchDataFromLocalStorage";

export default function BreadCrumb({ main, sub1, sub2 }) {
  const location = useLocation();
  const apiUrl = process.env.REACT_APP_API_URL;

  // State to store user information
  const [userName, setUserName] = useState("User");

  useEffect(() => {
    const token = FetchDataFromLocalStorage("token");
    const userId = FetchDataFromLocalStorage("userId");

    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/customers/${userId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.data.success) {
          const { firstName, lastName } = response.data.customer;
          setUserName(`${firstName} ${lastName}`);
        }
      } catch (error) {
        console.error("Error fetching user profile:", error.response?.data || error.message);
      }
    };

    if (token && userId) {
      fetchUserProfile();
    }
  }, [apiUrl]);

  // Generate breadcrumb links
  // const getCrumbs = () => {
  //   const crumbs = [{ name: "Dashboard", path: "/dashboard" }];

  //   if ("UserProfile") {
  //     crumbs.push({ name: "UserProfile", path: "/userprofile" });
  //   }

  //   if (userName) {
  //     crumbs.push({ name: userName, path: "/userprofile" });
  //   }

  //   return crumbs;
  // };

  const getCrumbs = () => {
    const crumbs = [{ name: main, path: "/" + main.toLowerCase() }];

    if (sub1) {
      crumbs.push({ name: sub1, path: "/" + sub1.toLowerCase() });
    }

    if (sub2) {
      crumbs.push({ name: sub2, path: "/" + sub2.toLowerCase() });
    }

    return crumbs;
  };

  const crumbs = getCrumbs();

  return (
    <div className=" items-center xs:hidden sm:hidden md:flex lg:flex xl:flex justify-between p-3 mb-4 bg-white rounded-lg shadow-md">
      {/* Breadcrumb navigation */}
      <div className="flex items-center space-x-2">
        {crumbs.map((crumb, index) => (
          <React.Fragment key={crumb.name}>
            <Link
              to={crumb.path}
              className={`${
                location.pathname === crumb.path
                  ? "text-blue-600 font-semibold"
                  : "text-gray-600"
              }`}
            >
              <span>{crumb.name.charAt(0).toUpperCase() + crumb.name.slice(1)}</span>

            </Link>
            {index < crumbs.length - 1 && (
              <span className="text-gray-400"> &gt; </span>
            )}
          </React.Fragment>
        ))}
      </div>

      {/* User Greeting */}
      <div className="flex items-center space-x-2">
        <div className="bg-blue-100 p-2 rounded-lg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-6 h-6 text-blue-600"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8 7V3m8 4V3m-9 4h10a2 2 0 012 2v10a2 2 0 01-2 2H7a2 2 0 01-2-2V9a2 2 0 012-2zm1 4h3m-3 4h6"
            />
          </svg>
        </div>
        <div>
          <p className="text-gray-400 text-sm">Welcome</p>
          <p className="text-gray-600 font-semibold">{userName}</p>
        </div>
      </div>
    </div>
  );
}
