import React, { useEffect, useState } from "react";
import { BarsIcon, CardIcon, GroupAddIcon,ScheduleIcon, SettingIcon, XMarkIcon } from "../../Svg icons/Svg";
import Sidebar from "./SideBar";
import BreadCrumb from "./BreadCrumb";

export const Settings = () => {
  const [activeTab, setActiveTab] = useState("General"); // Manage active tab
  const [teamName, setTeamName] = useState("");
  const [teamUrl, setTeamUrl] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showActionMenu, setShowActionMenu] = useState(null); // Track action menu visibility
  
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Sidebar toggle state
  const [members, setMembers] = useState([
    {
      email: "venkateshkumar19311@gmail.com",
      joinedDate: "Nov 29 ,2001",
      role: "Owner",
    },
  ]); // Example members list

  const handleSave = () => {
    if (teamName && teamUrl) {
      setIsSaved(true);
    }
  };

  const handleDelete = () => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete the team "${teamName}"? This action is irreversible.`
    );
    if (confirmDelete) {
      setIsDeleting(true);
      setTimeout(() => {
        setTeamName("");
        setTeamUrl("");
        setIsDeleting(false);
        alert("Team deleted successfully");
      }, 1000);
    }
  };

  // Toggle the action menu for a specific member
  const toggleActionMenu = (index) => {
    setShowActionMenu((prev) => (prev === index ? null : index));
  };

  const handleSidebarToggle = (isOpen) => {
    setIsSidebarOpen(isOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.matchMedia("(max-width: 768px)").matches;
      setIsSidebarOpen(!isMobile); // Close sidebar on mobile, open on larger screens
    };

    // Initialize state based on the initial screen size
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Function to handle "Leave team" action
  const handleLeaveTeam = (index) => {
    const confirmLeave = window.confirm(
      `Are you sure you want to leave the team? This action cannot be undone.`
    );
    if (confirmLeave) {
      // Simulate leaving the team by removing the member from the list
      setMembers((prevMembers) => prevMembers.filter((_, i) => i !== index));
      setShowActionMenu(null); // Close the action menu
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex w-full shadow-lg fixed h-full bg-gray-100">
      
     
      {/* Hamburger Button for Mobile */}
     {/* <button
            className="fixed top-16 left-4 z-50 bg-gray-500 text-white  p-2 rounded-full lg:hidden"
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          >
            {isSidebarOpen ? <XMarkIcon className="w-6 h-6" /> : <BarsIcon className="w-6 h-6" />}
          </button> */}

      {/* Left Sidebar */}

      <Sidebar onToggle={handleSidebarToggle} />




      {/* Content Area */}
      <div className="flex-1 min-h-screen bg-gray-100 p-6" style={{ marginLeft: isSidebarOpen ? '250px' : '0', transition: 'margin 0.3s ease-in-out' }} >
   {/* Content Area */}
   <BreadCrumb main="Dashboard" sub1="Settings" />


  {activeTab === "General" && (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4">General</h2>
      <div className="mb-4">
        <label className="block text-lg font-semibold">Team Name</label>
        <input
          type="text"
          value={teamName}
          onChange={(e) => setTeamName(e.target.value)}
          placeholder="Enter your team's name"
          className="w-full p-2 border border-gray-300 rounded-lg mt-2"
        />
      </div>
      <div className="mb-4">
        <label className="block text-lg font-semibold">Team URL</label>
        <input
          type="text"
          value={teamUrl}
          onChange={(e) => setTeamUrl(e.target.value)}
          placeholder="Enter your team's URL"
          className="w-full p-2 border border-gray-300 rounded-lg mt-2"
        />
        <p className="text-sm text-gray-500 mt-2">
          Updating Team URL will cause a redirect to the new URL.
        </p>
      </div>

      <button
        onClick={handleSave}
        className="bg-purple-600 text-white py-2 px-4 rounded-lg hover:bg-purple-700 transition duration-300"
      >
        Save
      </button>

      {isSaved && (
        <p className="text-green-500 mt-4">Settings saved successfully!</p>
      )}

      <div className="mt-16 border-t-2 border-gray-200 pt-10">
        <h2 className="text-2xl font-bold text-red-600 mb-4">Danger Zone</h2>
        <div className="p-4 bg-red-50 border border-red-200 rounded-lg">
          <p className="text-sm text-red-800 mb-4">
            Once you delete your team account, there is no going back. Please
            be certain. All your uploaded data and trained AI Agent will be
            permanently deleted. This action is not reversible.
          </p>
          <button
            onClick={handleDelete}
            className="bg-red-600 text-white py-2 px-4 rounded-lg hover:bg-red-700 transition duration-300"
          >
            Delete {teamName || "your team"}
          </button>

          {isDeleting && (
            <p className="text-red-600 mt-4">Deleting team...</p>
          )}
        </div>
      </div>
    </div>
  )}



{activeTab === "Members" && (
  <div className="bg-white p-6 rounded-lg shadow-md">
    <h2 className="text-2xl font-bold mb-4">Members</h2>

    {/* Table for Members List */}
    <table className="table-auto w-full">
      <thead>
        <tr>
          <th className="px-4 py-2 text-left">User</th>
          <th className="px-4 py-2 text-left">Member since</th>
          <th className="px-4 py-2 text-left">Role</th>
          <th className="px-4 py-2 text-left">Actions</th>
        </tr>
      </thead>
      <tbody>
        {members.map((member, index) => (
          <tr key={index}>
            <td className="border px-4 py-2">{member.email}</td>
            <td className="border px-4 py-2">{member.joinedDate}</td>
            <td className="border px-4 py-2">{member.role}</td>
            <td className="border px-4 py-2 relative">
              {/* Action Button (Three Dots) */}
              <button
                className="text-gray-500 hover:text-gray-700"
                onClick={() => toggleActionMenu(index)}
              >
                ...
              </button>

              {/* Dropdown Menu */}
              {showActionMenu === index && (
                <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 shadow-lg rounded-lg">
                  <ul>
                    <li
                      className="block px-4 py-2 text-gray-700 hover:bg-gray-100 cursor-pointer"
                      onClick={() => handleLeaveTeam(index)}
                    >
                      Leave team
                    </li>
                  </ul>
                </div>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>

    {/* Invite Members Button */}
    <div className="mt-6">
      <button className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300">
        Invite Members
      </button>
    </div>
  </div>
)}

        {activeTab === "Plans" && <div>  {activeTab === "Plans" && (
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-4">Plans</h2>
            <p className="text-sm">View and change your plan.</p>
          </div>
        )}</div>}
        {activeTab === "Billing" && <div>  {activeTab === "Billing" && (
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-4">Billing</h2>
            <p className="text-sm">
              Manage your billing information and view invoices.
            </p>
          </div>
        )}
        </div>}
     



      </div>
    </div>
  );
};


